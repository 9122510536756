.feh-modal {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10005;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 24px;

    /* Modal content */
    .feh-modal-container {
        background-color: #fff;
        border-radius: 8px;
        padding: 8px 24px 12px;
        border: rgb(236, 235, 235);
        margin: 0 auto;
        overflow: auto;
        max-height: fit-content;
        width: fit-content;

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #555;
        }
    }

    /* Close button */
    .close {
        color: #39393a;
        font-size: 25px;
        font-weight: bold;
        opacity: 1 !important;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;

        .close-icon-button {
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
        }
    }

    .close:hover,
    .close:focus {
        text-decoration: none;
        opacity: 1;
    }

    @media only screen and (min-width: 768px) {
        .close {
            margin-top: -17px;
            margin-right: -9px;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 1024px) {
        .feh-modal-container {
            padding: 40px;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 1200px) {
    }

    &.b2c-cancel-assessment {
        .feh-modal-container {
            .close {
                color: #000000;
            }
        }

        @media screen and (min-width: 768px) {
            .cancel-assessment-custom-model {
                padding: 55px 32px 0 64px;
            }
        }
    }

    &.your-contact-details-popup {
        .feh-modal-container {
            padding: 0;
        }

        .close {
            padding: 24px;
        }
    }
}
