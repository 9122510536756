.search-list-component {
    position: relative;
    box-shadow: inset 0 0 0 2px #cccbca;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &.input--focused {
        box-shadow: inset 0 0 0 2px #1ea2b1;
    }

    &.list--exist {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &.reverse-list--exist {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    input {
        background: transparent
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
            no-repeat 97% center;
        background-position: 97% center;
        background-repeat: no-repeat;
        border: 0;
        box-sizing: border-box;
        display: block;
        font-size: 16px;
        padding: 12px 48px 12px 12px;
        width: 100%;
        height: 48px;
        outline: none;
    }

    .loader {
        position: absolute;
        right: 4px;
        top: 4px;
    }

    .search-icon-container {
        width: 40px;
        height: 40px;
        position: absolute;
        cursor: pointer;
        top: 4px;
        padding: 0;
        margin: 0;
        right: 4px;
        background: transparent;
        border: 0;
        border-radius: 8px;
    }

    .result_ul {
        margin: 0;
        list-style: none;
        border: 2px solid #1ea2b1;
        padding: 2px 0;
        position: absolute;
        width: 100%;
        background-color: white;
        z-index: 1000;
        overflow: hidden;
        &--limited-height {
            max-height: 200px;
            overflow-y: auto;
        }
        &--normal {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-top: 0;
        }
        &--reverse {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }
        .result-list {
            font-size: 18px;
            display: flex;
            align-items: center;
            position: relative;

            a {
                flex: 1;
                text-align: left;
            }

            .icon-align-right {
                border: 0;
                position: relative;
                right: 0px;
                border-radius: 16px;
                background-color: #f9f6f4;
                margin-left: 2px;
                width: 32px;
                height: 32px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background-color: #f7e8da;
                }
            }

            &:first-child {
                border-top: 2px solid var(--eon-neutral300);
            }

            .result-redirection-wrapper {
                text-decoration: none;
                color: var(--eon-neutral800);
            }

            &:hover {
                background: #f9f6f4;
                text-decoration: underline;
            }
        }
        .app-section {
            background-color: #f9f6f4;
            .app-slider {
                display: -webkit-inline-box;
                overflow: auto hidden;
                width: calc(100% - 24px);
                padding-bottom: 4px;
                &::-webkit-scrollbar {
                  height: 8px; // Height of the horizontal scrollbar
                }
              
                &::-webkit-scrollbar-track {
                  background: #f1f1f1; // Background of the scrollbar track
                }
              
                &::-webkit-scrollbar-thumb {
                  background: #888; // Color of the scrollbar thumb
                  border-radius: 4px; // Rounded corners for the scrollbar thumb
                }
              
                &::-webkit-scrollbar-thumb:hover {
                  background: #555; // Color of the scrollbar thumb on hover
                }
            }
        }
        .result-search-subheader {
            display: flex;
            margin: 4px 12px;
        }
        .app-slider {
            margin: 0 12px;
        }
        .result-list {
            &:first-child {
                border-top: 0;
            }
            background-color: #fff;
            margin-right: 12px;
            border-radius: 8px;
            padding-right: 4px;
        }
    }
}