.room-card-wrapper {
    margin-top: 36px;

    .card-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card-layout {
        height: 130px;
        width: 200px;
        min-width: 200px;
        background-color: #f6f6f6;
        border-radius: 25px;
        padding: 10px;
        margin: 8px 8px auto;
        font-size: 14px;
        transition: transform 0.2s;
        position: relative;
        .card-row {
            display: flex;
        }
        &:hover {
            cursor: pointer;
            transform: scale(1.03);
        }
        .type-icon-wrapper {
            position: absolute;
            bottom: 24px;
            right: 24px;
            svg {
                height: 30px;
                width: 30px;
            }
        }
    }
    .card-heading {
        padding-left: 12px;
    }
    .display-flex {
        display: flex;
        gap: 20px;
    }
    .card-text__bold {
        font-weight: bold;
    }
    .availability-pointers__label {
        display: flex;
        gap: 24px;
        margin-top: 24px;
        .indicator {
            display: flex;
            align-items: baseline;
            gap: 4px;
        }
    }
    .icon-theme {
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: 9px;
        margin-top: 4px;
        &--free {
            background-color: #00b876 !important;
        }
        &--partial {
            background-color: #F07C00 !important;
        }
        &--busy {
            background-color: #e63121 !important;
        }
    }
}
