.tab-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    box-shadow: inset 0 -5px 1px -3px #cccbca;

    .tab-scroller {
        display: flex;
        position: relative;
        z-index: 10;
        overflow: auto hidden;
        scrollbar-width: none;
    }

    .tab {
        max-height: 50px;
        height: 36px;
        display: block;
        text-overflow: ellipsis;
        border: 0;
        border-bottom: 1px solid #cccbca;
        background: none;
        color: #555555;
        padding: 1px 16px;
        cursor: pointer;

        &:hover {
            background-color: var(--super-light-bg);
            border-bottom: 2px solid #767676;
        }

        &--active {
            background-color: var(--super-light-bg);
            color: #262626;
            font-weight: 700;
            border-bottom: 2px solid #262626;
            &:hover {
                border-bottom: 2px solid #262626;
            }
        }

        &.add-new {
            justify-content: flex-end;
            background-color: #fff;
            border-bottom: 2px solid var(--primary-color);

            .add-new-desktop {
                display: none;
                padding-left: 8px;
                margin: auto;
            }

            .icon-wrapper {
                margin: auto;
            }
            &:hover {
                background-color: var(--super-light-bg);
            }
        }
    }

    @media (min-width: 768px) {
        .tab {
            height: 48px;

            &.add-new {
                display: flex;

                .add-new-desktop {
                    display: block;
                    font-weight: 700;
                    color: var(--primary-color);
                }
            }
        }

        .button-wrapper {
            flex-direction: row-reverse;
        }
    }
}

.new-tab-wrapper {
    width: 400px;
    .common-button-wrapper {
        flex-direction: row;
        justify-content: start;
        margin-bottom: 0;
    }
    .heading {
        color: var(--heading-color);
        margin: -1em 0 0.5em 0;
    }

    @media (max-width: 600px) {
        width: 100%;
        .common-button-wrapper {
            flex-direction: column;
            justify-content: center;
        }
    }
}
