.widget-container {
    position: fixed;
    transform: rotate(270deg);
    right: -40px;
    bottom: 350px;
    z-index: 1001;
    text-align: center;

    .widget-button {
        border: 1px solid var(--primary-color);
        border-bottom: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        cursor: pointer;
        height: 24px;
        padding: 4px 14px;
        width: 100px;
        background-color: var(--super-light-bg);
        color: #262626;
        &:hover {
            padding: 4px 12px;
            height: 32px;
            background-color: var(--light-bg);
            color: var(--primary-color);
        }
    }
    &:hover {
        right: -38px;
    }
    @media screen and (min-width: 1200px) {
        bottom: calc(50% + 80px);
    }
}
