@mixin flex-container($direction: row,
    $wrap: nowrap,
    $justify: flex-start,
    $align: stretch,
    $align-content: stretch,
    $grow: 0,
    $shrink: 0,
    $basis: auto,
    $gap: 0) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify;
    align-items: $align;
    align-content: $align-content;
    flex-grow: $grow;
    flex-shrink: $shrink;
    flex-basis: $basis;
    gap: $gap;
}

/* Style the tab */
.search-custom-filter-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .unified-box-heading {
        margin: 0 12px;
        padding: 12px 0;
        border-bottom: 1px solid #cccbca;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 54px;
        font-size: 18px;
        font-weight: bold;
        top: 0;
        background: white;
    }

    .unified-box-content {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .search-input-wrapper {
        margin-bottom: 24px;

        .input-box-wrapper {
            width: 100%;
            box-sizing: border-box;
            border-radius: 18px;
            font-size: 16px;
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
            background-position: 10px 10px;
            background-repeat: no-repeat;
            border: 1px solid #555;
            display: block;
            padding: 9px 4px 9px 40px;
        }
    }

    .filter-conatiner {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .filter-tabs {
            @include flex-container($align: center, $gap: 8px);
            background-color: #fff;

            .tablinks {
                color: #0b0b0b;
            }

            & .spacer {
                @include flex-container($grow: 1);
            }
        }

        .filter-tabs .tablinks {
            color: #343A40;
            background-color: #F9FAFB;
            float: left;
            border: none;
            outline: none;
            cursor: pointer;
            padding: 8px;
            transition: padding 0.3s linear 0s;
            min-width: 30px;
        }

        .filter-tabs .tablinks:hover {
            color: #0b0b0b;
            background-color: #f9f6f4;
        }

        .filter-tabs .tablinks.active {
            color: #F9FAFB;
            background-color: var(--eon-red500);
            border-radius: 8px;
            font-weight: 700;
        }

        .filter-tabs-right {
            @include flex-container($direction: column);
        }

        .filter-bottom {
            margin-block-start: 0;
            height: 4px;
            background: #e9e9e9;
            border: none;
        }
    }

    .custom-result-container {
        @include flex-container($direction: column,
            $grow: 1,
            $shrink: 1,
            $basis: 80%,
            $gap: 12px);
        list-style: none;
        border: none;
        border-radius: 2px 2px 5px 5px;
        cursor: auto;
        padding: 0;
        margin: 0;
        z-index: 1;
        background: white;
        color: #262626;
        min-width: 200px;



        .no-more-data {
            text-align: center;
            padding: 4px;
        }
    }

    .search-input {
        @include flex-container($gap: 2px);
    }

    @media screen and (min-width: 1024px) {
        gap: 24px;

        .filter-conatiner {
            .filter-tabs .tablinks {
                font-size: 16px;
                padding: 8px 16px;
            }

            .filter-tabs .tablinks.active {
                font-size: 16px;
                font-weight: 500;
            }
        }

        .custom-result {
            &--reverse {
                @include flex-container($gap: 48px, $direction: row-reverse);
            }
        }

        .custom-result-container {
            .no-data {
                font-size: 24px;
            }
        }
    }
}

.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: 4px;
    margin: 20px;
}