.username {
    display: flex;
    align-items: center;
}
.auth-wrapper {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: none;
    margin: 0 auto;
    padding: 1px 14px;
    height: 100%;
    &:hover {
        border-bottom: 4px solid #262626;
        background-color: #f9f6f4;
        height: 68px;
    }
    .loggedout-alert {
        position: absolute;
        top: 0;
        left: 0;
        background: var(--eon-red500);
        border-radius: 12px;
        width: 8px;
        height: 8px;
    }
}