.refine-filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 280px;
}

.widget-reduced-width {
    @media screen and (min-width: 1024px) {
        width: calc(100% - 292px);
    }
}

.search-filter-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #B3ACA9;
    padding: 12px 0;

    .filter-header-left {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 18px;
        font-weight: 900;
        cursor: pointer;
    }

    .arrow-container {
        display: flex;
        gap: 10px;

        .filter-count {
            align-content: center;
            font-size: 18px;
            font-weight: 700;
            color: var(--primary-color);
        }
    }

    .filter-heading {
        font-size: 18px;
        font-weight: 700;
    }
}

.search-filter-wrapper {
    color: #262626;





    .filter-section-heading {
        font-size: 16px;
        font-weight: 700;
        margin: 10px 0;
    }

    .filter-search-section {
        display: none;
    }

    .reload-icon {
        cursor: pointer;
    }

    @media screen and (min-width: 1024px) {
        width: 286px;

        .search-filter-header {
            .filter-header-left {
                cursor: default;
            }

            .arrow-container {
                display: none;
            }
        }

        .filter-search-section {
            display: block;
        }
    }
}

.search-filters-modal-container {
    .btn {
        display: flex;
        gap: 20px;
        margin-top: 20px;
    }

    .search-filter-wrapper {
        .search-filter-header {
            .arrow-container {
                display: none;
            }
        }
    }

    .filter-search-section {
        display: block;
    }

    @media screen and (min-width: 320px) {
        width: 200px;
    }

    @media screen and (min-width: 480px) {
        width: 260px;
    }
}