.advance-page-result-root {

    .all_data_view_row_2 {
        padding: 0 8px 8px;

        .sharepoint-wrapper {
            height: 100%;

            .unified-box-content {
                max-height: 200px;
                // overflow-y: hidden;
                // transition: overflow-y 0.3s ease;

                // &:hover {
                //     overflow-y: auto;
                // }
                overflow-y: auto;
            }
        }
    }

    @media screen and (min-width: 768px) {

        .all_data_view_row_2 {
            padding: 0 8px 8px;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;

            .sharepoint-wrapper {
                .unified-box-content {
                    max-height: 1000px;
                }
            }
        }

        .all-list-wrapper,
        .sharepoint-wrapper {
            width: calc(50% - 6px);
        }
    }

    @media screen and (min-width: 1024px) {

        .all_data_view_row_2 {
            padding: 0 12px 12px;
        }

        .all-list-wrapper {
            width: calc(100% - 412px);
        }

        .sharepoint-wrapper {
            width: 400px;
        }
    }
}

.simple_data_rows {
    flex-direction: column;

    code {
        white-space: break-spaces;
    }

    @media screen and (min-width: 540px) {
        flex-direction: row;
    }
}