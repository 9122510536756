
.ai-response {
    margin: 0;
}

.citation-container {
    flex: 1;
    .citation-item {
        margin: 10px;
    }
}
.iframe-with-links {
    display: flex;
}
.pdf-preview {
    flex: 2;
    width: 100%;
    min-width: 400px;
    min-height: 600px;
    @media screen and (max-width: 920px) {
        display: none;
    }
}
