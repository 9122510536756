.meeting-popup {
    width: 220px;
    .form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 18px;
        .eon-form-label {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 4px;
            display: block;
        }
        .field-value {
            font-size: 12px;
        }
    }
    .room-not-available {
        color: #b00402;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        .error-icon {
            color: #b00402;
        }
        .error-text {
            line-height: 32px;
            margin-left: 8px;
        }
    }
    .room-partially-available {
        color: #ffa947;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        .partial-error-icon {
            color: #ffa947;
        }
        .error-text {
            line-height: 32px;
            margin-left: 8px;
        }
    }
    .capacity_wrapper {
        display: flex;
        line-height: 24px;
        margin-bottom: 0;
        .capacity_icon {
            margin-right: 8px;
        }
    }
    @media screen and (min-width: 540px) {
        width: 400px;
    }

    @media screen and (min-width: 768px) {
        width: 540px;
    }
}
