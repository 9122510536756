.about-us-container {
    color: #262626;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .about-us-header-section {
        display: flex;
        gap: 48px;
        padding: 12px 16px;
        background: rgba(249, 250, 251, 1);
        .about-us-header-label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 12px 16px;
            gap: 24px;
            .looking-label {
                font-size: 24px;
                font-weight: 500;
                text-align: center;
            }
            .find-out-label {
                color: #5C5C5C;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
            }
        }
        .about-us-image {
            display: none;
            .about-us-img {
                background: #F2F2F2;
                width: 616.88px;
                height: 245px;
            }
        }
    }
    
    .about-us-hint-section {
        display: flex;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 0 16px;
        gap: 10px;
    }

    .release-history {
        .release-date {
            font-size: 16px;
            font-weight: 500;
        }
    }

    ul {
        list-style-type: square;
        line-height: 24px;
    }

    @media screen and (min-width: 1024px) {
        gap: 24px;
        .about-us-header-section {
            padding: 24px;
            .about-us-header-label {
                padding: 12px 24px;
                .looking-label {
                    font-size: 30px;
                }
                .find-out-label {
                    font-size: 24px;
                }
            }
            .about-us-image {
                display: inline;
            }
        }

        .about-us-hint-section {
            font-size: 20px;
            padding: 0 24px;
            gap: 48px;
        }
    
        .release-history {
            .release-date {
                font-size: 20px;
            }
        }

        ul {
            line-height: 30px;
        }
    }
}
