@mixin footer-sections {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 14px;
}

@mixin footer-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 14px;
}

.footer {
    border-top: 4px solid #e6e3e1;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;

    .white-section-footer {
        background-color: white;
        padding: 15px 16px;

        .footer-left-right-spacing {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: space-between;

            .left-footer {
                @include footer-sections;

                .social-media-icons {
                    @include footer-items;
                }
            }

            .mid-footer {
                @include footer-sections;

                .footer-links {
                    @include footer-items;
                    color: #39393a;
                    font-size: 15px;
                    font-weight: 550;
                    &:hover {
                        color: #39393a;
                        text-decoration: none;
                        background-color: transparent;
                    }
                }
            }

            .right-footer {
                @include footer-sections;
                gap: 20px;

                .eon-copyright {
                    @include footer-items;
                    color: #8f9192;
                    font-size: 14px;
                    font-weight: 250;
                    align-items: center;
                }
            }
        }
    }
    @media screen and (min-width: 768px) {
        .white-section-footer {
            .footer-left-right-spacing {
                flex-direction: row;

                .left-footer {
                    width: 100%;
                }
                .mid-footer {
                    width: 100%;
                }
                .right-footer {
                    width: 100%;
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .white-section-footer {
            .footer-left-right-spacing {
                .left-footer {
                    width: 20%;
                    justify-content: flex-start;
                }
                .mid-footer {
                    width: 55%;
                }
                .right-footer {
                    width: 25%;
                    justify-content: flex-end;
                }
            }
        }
    }
    @media screen and (min-width: 1440px) {
        max-width: 1440px;
    }
}
