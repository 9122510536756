.location-dropdown {
    width: 100%;
    .form-label {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
        display: block;
    }
    .selected-location {
        border: 2px solid #cccbca;
        border-radius: 6px;
        height: 32px;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 30px;
        padding-left: 8px;

        &:hover {
            border: 2px solid #1ea2b1;
        }
    }
    &-error {
        border: 2px solid #b00402 !important;
    }
    &-placeholder {
        color: #cccbca !important;
    }
    &-show {
        position: absolute;
        z-index: 98 !important;
    }
    &-hide {
        display: none;
    }
}
