.child-container {
    margin-bottom: 16px;

    .page-sub-title {
        font-size: 18px;
        font-weight: bold;
    }

    .book-again-container {
        display: flex;
        gap: 15px;
        background-color: #FCFBFA;
        border: 1px solid transparent;
        transition: all 0.3s ease;
        width: 100%;
        overflow-x: auto;
        padding: 8px;

        .box {
            display: flex;
            gap: 15px;
            padding: 16px;
            min-width: max-content;
            cursor: pointer;
            background: #f9f6f4;

            &:hover {
                box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
            }

            .item {
                display: flex;
                flex-direction: column;
                gap: 5px;
                max-width: 15rem;
    
                .link {
                    color: var(--primary-color);
                    font-weight: 600;
                    text-align: center;
                }
            }
    
            .type-icon-wrapper {
                display: flex;
                align-items: center;
                svg {
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
}