@mixin flex-container($direction: row,
    $wrap: nowrap,
    $justify: flex-start,
    $align: stretch,
    $align-content: stretch,
    $grow: 0,
    $shrink: 0,
    $basis: auto,
    $gap: 0) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify;
    align-items: $align;
    align-content: $align-content;
    flex-grow: $grow;
    flex-shrink: $shrink;
    flex-basis: $basis;
    gap: $gap;
}

.advance-unified-assist {
    padding: 8px 8px 0;

    &--flex {
        @include flex-container($direction: column);
    }

    &--flex-rev {
        @include flex-container($gap: 8px, $direction: column-reverse);
    }

    .advance-unified-ai {
        width: 100%;
    }

    .ai-logo {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .role-list {
        height: 50px;
        margin-bottom: 24px;

        .unified-bot-text-format {
            font-size: 16px;
            white-space: pre-wrap;
            word-break: break-word;

            a {
                color: #1ea2b1;
                text-decoration: none;
            }

            b {
                color: #262626;
            }
        }
    }

    .show-more-button {
        display: flex;
        justify-content: center;
        margin-bottom: -12px;
        padding: 10px;
        z-index: 1;
        background: linear-gradient(to bottom, #fff6, #fff);
    }

    .chatbot-response {
        overflow: hidden;



        @include flex-container($direction: column,
            $justify: space-between,
            $grow: 1,
            $shrink: 1,
            $basis: 80%);
        list-style: none;
        border: none;
        border-radius: 2px 2px 5px 5px;
        cursor: auto;
        padding: 8px;
        z-index: 1;
        color: #262626;
        min-width: 200px;



        .unified-input-wrapper {
            display: flex;
            flex-direction: row;
            gap: 12px;
            border: 1px solid #808080;
            padding: 8px;
            max-width: 700px;
            margin: 0 auto;
            width: 100%;
        }
    }

    .no-response-loader {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        flex-basis: 80%;
    }

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        mask-image: none;
        padding: 12px 12px 0;

        &--flex {
            @include flex-container($gap: 12px);
        }

        .role-list {
            height: 80px;
        }
    }
}