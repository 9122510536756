.cancel-booking-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    color: #262626;

    .heading {
        font-size: 30px;
        font-weight: 500;
        color: var(--heading-color);
    }

    .title {
        font-size: 24px;
        font-weight: 400;
    }

    .location {
        font-weight: 500;
        .label {
            font-size: 16px;
        }
        .loc {
            font-size: 22px;
        }
    }

    .other-details {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .timing {
            font-weight: 500;
            .date {
                font-size: 18px;
            }

            .time {
                font-size: 22px;
            }

            .duration {
                font-size: 14px;
                color: #404040;
            }
        }
    }

    .btn {
        display: flex;
        gap: 25px;

        .confirm {
            font: 16px;
            font-weight: 400;
        }

        .cancel {
            font: 16px;
            font-weight: 400;
        }
    }

    @media screen and (min-width: 768px) {
        .other-details {
            flex-direction: row;
            gap: 50px;
        }
    }
    @media screen and (min-width: 1024px) {
        .other-details {
            flex-direction: row;
            gap: 100px;
        }
    }
    @media screen and (min-width: 1440px) {
        max-width: 1440px;
    }
}