.edit-booking-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    color: #262626;
    height: 45rem;

    .heading {
        font-size: 30px;
        font-weight: 500;
        color: var(--heading-color);
    }

    .title {
        font-size: 24px;
        font-weight: 400;
    }

    .availability-info {
        display: flex;
        flex-direction: column;

        .heading {
            display: flex;
            gap: 10px;
            align-items: center;
            font-size: 36px;
        }

        .error {
            color: #EF7115;
        }
    }

    .info {
        color: #00B876;
    }

    .location {
        font-weight: 500;
        .label {
            font-size: 16px;
        }
        .loc {
            font-size: 22px;
        }
    }

    .other-details {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .timing {
            font-weight: 500;
            .date {
                font-size: 18px;
            }

            .time {
                font-size: 22px;
            }

            .duration {
                font-size: 14px;
                color: #404040;
            }
        }
    }

    .reschedule-to {
        font-size: 18px;
        font-weight: 500;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .row {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }

    .btn {
        display: flex;
        gap: 25px;
        
        .confirm {
            font: 16px;
            font-weight: 400;
        }

        .cancel {
            font: 16px;
            font-weight: 400;
        }
    }

    @media screen and (min-width: 768px) {
        .other-details {
            flex-direction: row;
            gap: 125px;
        }
        .form {
            flex-direction: row;
        }
    }
    @media screen and (min-width: 1024px) {
        .other-details {
            flex-direction: row;
            gap: 250px;
        }
        .form {
            flex-direction: row;
            justify-content: flex-start;
            gap: 25px;
            .row-1 {
                flex-direction: row;
            }
            .row-2 {
                flex-direction: row;
            }

            .dropdown {
                width: 10rem;
            }
        }
    }
    @media screen and (min-width: 1440px) {
        max-width: 1440px;
    }
}