.nav-bar-dropdown {
    .nav-bar-li {
        button {
            width: 100%;
            border-bottom: 0px !important;
        }

        &:last-child {
            button {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}