@charset "UTF-8";
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.sui-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/**
 * Header / Searchbox
 */
.sui-layout-header {
  padding: 32px 24px;
  border-bottom: 1px solid #eeeeee;
}

/**
 * Body
 */
.sui-layout-body {
  background: #fcfcfc;
}
.sui-layout-body:after {
  content: "";
  height: 80px;
  width: 100%;
  display: block;
  position: relative;
  background: -moz-linear-gradient(top, #fcfcfc 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #fcfcfc 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #fcfcfc 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#fcfcfc", endColorstr="#ffffff",GradientType=0 );
}
@media (max-width: 800px) {
  .sui-layout-body:after {
    display: none;
  }
}
.sui-layout-body__inner {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 0 24px;
}
@media (max-width: 800px) {
  .sui-layout-body__inner {
    display: block;
    padding: 0 15px;
  }
}

/**
 * Sidebar / Filters
 */
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sui-layout-sidebar {
  width: 24%;
  padding: 32px 32px 0 0;
}
@media (max-width: 975px) {
  .sui-layout-sidebar {
    padding-right: 0;
  }
}
@media (max-width: 800px) {
  .sui-layout-sidebar {
    z-index: 99;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0 15px 30px 15px;
    background-color: #fcfcfc;
    overflow: auto;
  }
}
.sui-layout-sidebar--toggled {
  display: block;
  animation: fadein 0.2s ease-out;
}

@media (max-width: 800px) {
  .sui-layout-sidebar-toggle {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    font-size: 14px;
    font-weight: 700;
    color: #3a56e4;
    background-color: #ffffff;
    border: 1px solid #3a56e4;
    border-radius: 4px;
  }
  .sui-layout-sidebar .sui-layout-sidebar-toggle {
    margin-bottom: 20px;
  }
}

/**
 * Main / Results
 */
.sui-layout-main {
  width: 76%;
  padding: 32px 0 32px 32px;
}
@media (max-width: 800px) {
  .sui-layout-main {
    width: 100%;
    padding-left: 0;
  }
}

.sui-layout-main-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.sui-layout-main-header__inner {
  font-size: 12px;
  color: #4a4b4b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.sui-layout-main-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sui-search-error {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: red;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 180px);
}
.sui-search-error.no-error {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #333333;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 180px);
}

.sui-facet {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
  padding: 0;
  border: 0;
}
.sui-facet + .sui-facet {
  margin-top: 32px;
}
.sui-sorting + .sui-facet {
  margin-top: 32px;
}
.sui-facet__title {
  text-transform: uppercase;
  font-size: 12px;
  color: #8b9bad;
  letter-spacing: 1px;
  padding: 0;
}
.sui-facet__list {
  line-height: 1.5;
  font-size: 13px;
  margin: 8px 0;
  padding: 0;
}
.sui-facet__count {
  color: #888888;
  font-size: 0.85em;
  margin-left: 20px;
  display: inline-block;
  padding-top: 2px;
}

.sui-multi-checkbox-facet {
  color: #4f4f4f;
  font-size: 13px;
  margin: 8px 0;
}
.sui-multi-checkbox-facet__option-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.sui-multi-checkbox-facet__option-input-wrapper {
  flex: 1;
}
.sui-multi-checkbox-facet__checkbox {
  margin-right: 8px;
  cursor: pointer;
}
.sui-multi-checkbox-facet__option-count {
  color: #888888;
  font-size: 0.85em;
  margin-left: 24px;
}

.sui-facet-view-more {
  display: block;
  cursor: pointer;
  color: #3a56e4;
  font-size: 13px;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  border: unset;
  padding: unset;
  background: unset;
}
.sui-facet-view-more:hover, .sui-facet-view-more:focus {
  background-color: #f8f8f8;
  outline: 4px solid #f8f8f8;
}

.sui-facet-search {
  margin: 6px 0px 0px 0px;
}
.sui-facet-search__text-input {
  width: 100%;
  height: 100%;
  padding: 6px;
  margin: 0;
  font-family: inherit;
  border: 1px solid #cccccc;
  border-radius: 4px;
  outline: none;
}
.sui-facet-search__text-input:focus {
  border: 1px solid #3a56e4;
}

.sui-boolean-facet {
  color: #4f4f4f;
  font-size: 13px;
  margin: 8px 0;
}
.sui-boolean-facet__option-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.sui-boolean-facet__option-input-wrapper {
  flex: 1;
}
.sui-boolean-facet__checkbox {
  margin-right: 8px;
  cursor: pointer;
}
.sui-boolean-facet__option-count {
  color: #888888;
  font-size: 0.85em;
  margin-left: 24px;
}

.sui-single-option-facet {
  font-size: 13px;
  margin: 8px 0;
  padding: 0;
  list-style: none;
}
.sui-single-option-facet__item {
  display: flex;
  justify-content: space-between;
}
.sui-single-option-facet__link {
  color: #4f4f4f;
  position: relative;
  text-decoration: none;
  list-style: none;
  padding: 0;
  font-weight: bold;
}
.sui-single-option-facet__link:after {
  content: "";
  opacity: 0;
  position: absolute;
  top: -1px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 2px);
  background: rgba(37, 139, 248, 0.08);
  pointer-events: none;
}
.sui-single-option-facet__link:focus {
  color: #3a56e4;
  font-weight: bold;
  outline: none;
}
.sui-single-option-facet__link:hover {
  color: #3a56e4;
  font-weight: bold;
}
.sui-single-option-facet__link:hover:after {
  opacity: 1;
}
.sui-single-option-facet__selected {
  font-weight: 900;
  list-style: none;
}
.sui-single-option-facet__selected a {
  font-weight: 100;
  padding: 0 2px;
}
.sui-single-option-facet__remove {
  color: #666;
  margin-left: 10px;
}

.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.rc-pagination ul,
.rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: " ";
}

.rc-pagination-total-text {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
  line-height: 26px;
  vertical-align: middle;
}

.rc-pagination-item {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}

.rc-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}

.rc-pagination-item a:hover {
  text-decoration: none;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  border-color: #1890ff;
  transition: all 0.3s;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: #1890ff;
}

.rc-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}

.rc-pagination-item-active a {
  color: #1890ff;
}

.rc-pagination-item-active:focus,
.rc-pagination-item-active:hover {
  border-color: #40a9ff;
}

.rc-pagination-item-active:focus a,
.rc-pagination-item-active:hover a {
  color: #40a9ff;
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  outline: 0;
}

.rc-pagination-jump-prev button,
.rc-pagination-jump-next button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}

.rc-pagination-jump-prev button:after,
.rc-pagination-jump-next button:after {
  display: block;
  content: "•••";
}

.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.rc-pagination-prev,
.rc-pagination-next {
  outline: 0;
}

.rc-pagination-prev button,
.rc-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}

.rc-pagination-prev:hover button,
.rc-pagination-next:hover button {
  border-color: #40a9ff;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}

.rc-pagination-prev:focus .rc-pagination-item-link,
.rc-pagination-next:focus .rc-pagination-item-link,
.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}

.rc-pagination-prev button:after {
  content: "‹";
  display: block;
}

.rc-pagination-next button:after {
  content: "›";
  display: block;
}

.rc-pagination-disabled,
.rc-pagination-disabled:hover,
.rc-pagination-disabled:focus {
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item-link,
.rc-pagination-disabled:hover .rc-pagination-item-link,
.rc-pagination-disabled:focus .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination-slash {
  margin: 0 10px 0 5px;
}

.rc-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {
  .rc-pagination-options *::-ms-backdrop,
.rc-pagination-options {
    vertical-align: top;
  }
}
.rc-pagination-options-size-changer.rc-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}

.rc-pagination-options-quick-jumper {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}

.rc-pagination-options-quick-jumper input {
  width: 50px;
  margin: 0 8px;
}

.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.rc-pagination.rc-pagination-disabled {
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
  color: #fff;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
  opacity: 0;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .rc-pagination-item-after-jump-prev,
.rc-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .rc-pagination-options {
    display: none;
  }
}
.sui-paging {
  font-size: 12px;
}
.sui-paging .rc-pagination-prev,
.sui-paging .rc-pagination-next,
.sui-paging .rc-pagination-item {
  border: none;
  background: transparent;
}
.sui-paging .rc-pagination-prev .rc-pagination-item-link,
.sui-paging .rc-pagination-next .rc-pagination-item-link,
.sui-paging .rc-pagination-item .rc-pagination-item-link {
  font-size: 18px;
}
.sui-paging .rc-pagination-prev > a,
.sui-paging .rc-pagination-prev > button,
.sui-paging .rc-pagination-next > a,
.sui-paging .rc-pagination-next > button,
.sui-paging .rc-pagination-item > a,
.sui-paging .rc-pagination-item > button {
  border: none;
  background: transparent;
  color: #4f4f4f;
}
.sui-paging .rc-pagination-prev > a:hover,
.sui-paging .rc-pagination-prev > button:hover,
.sui-paging .rc-pagination-next > a:hover,
.sui-paging .rc-pagination-next > button:hover,
.sui-paging .rc-pagination-item > a:hover,
.sui-paging .rc-pagination-item > button:hover {
  color: #4f4f4f;
  background: #f8f8f8;
}
.sui-paging .rc-pagination-disabled .rc-pagination-item-link {
  color: #ccc;
  opacity: 0.8;
}
.sui-paging .rc-pagination-disabled .rc-pagination-item-link:hover {
  color: #ccc;
}
.sui-paging .rc-pagination-item a {
  color: #3a56e4;
  text-decoration: none;
}
.sui-paging .rc-pagination-item-active a {
  color: #4f4f4f;
  font-weight: 700;
}
.sui-paging .rc-pagination-item-active:hover {
  background: transparent;
  cursor: not-allowed;
}
.sui-paging .rc-pagination-item-active:hover a {
  color: #4f4f4f;
  cursor: not-allowed;
}

.sui-paging-info {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #4a4b4b;
  font-size: 12px;
  display: inline-block;
}

.sui-result {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  list-style: none;
  padding: 24px 0;
  text-decoration: none;
  display: block;
  border: 1px solid #f0f0f0;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
  overflow-wrap: break-word;
  overflow: hidden;
}
.sui-result + .sui-result {
  margin-top: 32px;
}
.sui-result em {
  position: relative;
  color: #3a56e4;
  font-weight: 700;
  font-style: inherit;
}
.sui-result em:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background: rgba(37, 139, 248, 0.08);
  pointer-events: none;
}
.sui-result__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.sui-result__title {
  font-size: 1.8em;
  font-weight: 400;
  color: #333333;
  text-decoration: none;
}
.sui-result__title-link {
  color: #3a56e4;
  text-decoration: none;
}
.sui-result__key {
  font-family: monospace;
  font-weight: 400;
  font-size: 14px;
  flex: 0 1 50%;
  color: #777777;
}
.sui-result__key:before {
  content: '"';
}
.sui-result__key:after {
  content: '": ';
}
.sui-result__value {
  font-weight: 400;
  font-size: 14px;
}
.sui-result__version {
  font-size: 12px;
  display: inline;
  vertical-align: bottom;
}
.sui-result__license {
  font-size: 12px;
  color: #999999;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
  line-height: 1;
  padding: 4px 4px 3px 4px;
}
.sui-result__body {
  line-height: 1.5;
  margin-top: 0;
  display: flex;
}
.sui-result__body p {
  margin: 0;
}
.sui-result__image {
  padding-top: 12px;
  padding-left: 24px;
  flex-basis: 220px;
}
.sui-result__image img {
  max-width: 100%;
  display: block;
  height: auto;
}
.sui-result__details {
  flex: 1;
  list-style: none;
  padding: 12px 24px;
  margin: 0;
}

.sui-results-container {
  padding: 0;
  list-style: none;
}

.sui-results-per-page {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: #4a4b4b;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 100%;
}
.sui-results-per-page__label {
  margin-right: 8px;
}
.sui-results-per-page .sui-select__control {
  align-items: center;
}
.sui-results-per-page .sui-select__control input {
  position: absolute;
}

.sui-search-box {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: stretch;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}
.sui-search-box__submit {
  flex-shrink: 0;
  font-family: inherit;
  font-size: 14px;
  padding: 16px;
  margin-left: 10px;
  text-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
  color: white;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset, rgba(59, 69, 79, 0.05) 0px 1px 0px;
  background: linear-gradient(#2da0fa, #3158ee) #2f7cf4;
  cursor: pointer;
  border-radius: 4px;
}
.sui-search-box__submit:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px 1px inset, rgba(59, 69, 79, 0.3) 0px 2px 4px;
  background: linear-gradient(#3cabff, #4063f0) #3d84f7;
}
.live-filtering .sui-search-box__submit {
  display: none;
}
.sui-search-box__wrapper {
  width: 100%;
  height: 100%;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 3px;
  position: relative;
}
.sui-search-box__text-input {
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 16px;
  outline: none;
  position: relative;
  font-family: inherit;
  font-size: 14px;
  width: 100%;
}
.sui-search-box__text-input:focus {
  box-shadow: rgba(59, 69, 79, 0.3) 0px 2px 4px;
  border-top: 1px solid #3a56e4;
  border-left: 1px solid #3a56e4;
  border-right: 1px solid #3a56e4;
  border-bottom: 1px solid #3a56e4;
}
.autocomplete .sui-search-box__text-input {
  box-shadow: rgba(59, 69, 79, 0.3) 0px 2px 4px;
}
.sui-search-box__autocomplete-container {
  display: none;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 110%;
  margin: 0;
  padding: 24px 0 12px 0;
  line-height: 1.5;
  background: white;
  position: absolute;
  box-shadow: rgba(59, 69, 79, 0.3) 0px 2px 4px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.autocomplete .sui-search-box__autocomplete-container {
  display: flex;
  z-index: 1;
}
.sui-search-box__autocomplete-container ul {
  list-style: none;
  margin: 0;
  padding: 0 0 24px 0;
  background: transparent;
  border-radius: 3px;
}
.sui-search-box__autocomplete-container ul:last-child {
  padding: 0;
}
.sui-search-box__autocomplete-container li {
  margin: 0 12px;
  font-size: 0.9em;
  padding: 4px 12px;
  border-radius: 4px;
  color: #555;
  cursor: default;
}
.sui-search-box__autocomplete-container li em {
  font-style: normal;
  color: #3a56e4;
  background: #edf0fd;
}
.sui-search-box__autocomplete-container li:hover {
  background: #3a56e4;
  color: #ffffff;
}
.sui-search-box__autocomplete-container li:hover em {
  background: transparent;
  color: #ffffff;
}
.sui-search-box__autocomplete-container li[aria-selected=true] {
  background: #3a56e4;
  color: #ffffff;
}
.sui-search-box__autocomplete-container li[aria-selected=true] em {
  background: transparent;
  color: #ffffff;
}
.sui-search-box__section-title {
  color: #888;
  font-size: 0.7em;
  letter-spacing: 1px;
  font-weight: normal;
  padding: 0 0 4px 24px;
  text-transform: uppercase;
}

.sui-sorting {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  display: inline-block;
  width: 100%;
}
.sui-sorting__label {
  font-size: 12px;
  color: #8b9bad;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.sui-select {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 8px;
  width: 100%;
}
.sui-select--inline {
  margin-top: 0;
}
.sui-select--is-disabled {
  opacity: 0.5;
}
.sui-select__control {
  background-color: #f8f8f8;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.sui-select__control--is-focused {
  border: 1px solid #3a56e4;
}
.sui-select__value-container {
  padding-top: 0;
  padding-bottom: 0;
}
.sui-select__value-container--has-value {
  font-weight: 700;
  color: #333333;
}
.sui-select__placeholder {
  white-space: nowrap;
  position: static;
  transform: none;
  color: #333333;
}
.sui-select__dropdown-indicator {
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
}
.sui-select__option-count {
  color: #888888;
  font-size: 0.8em;
}
.sui-select__option-label {
  color: #4f4f4f;
}
.sui-select__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  padding: 8px 12px;
  cursor: pointer;
}
.sui-select__option--is-selected {
  color: #333333;
  background: #ffffff;
  font-weight: 700;
}
.sui-select__option--is-selected .sui-search-select__option-label {
  color: #333333;
  position: relative;
}
.sui-select__option:hover {
  background: #f8f8f8;
}
