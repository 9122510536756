.filter-list-wrapper {
    .filter-opt {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        align-items: center;
        gap: 24px;
        font-size: 16px;
        min-height: 48px;

        .current-opt-left {
            display: flex;
            gap: 12px;
            align-items: center;
        }

        &--selected,
        &:hover,
        &:focus {
            background-color: #f8f5f2;
            text-decoration: underline;
            color: var(--primary-color);
            cursor: pointer;
        }

        &--selected {
            background-color: #f8f5f2;

            &:hover {
                background-color: #f8f5f2;
            }
        }

        input[type="radio"]:checked {
            accent-color: var(--primary-color);
        }

        input[type="radio"]:checked+label {
            color: var(--primary-color);
        }
    }
}