$backgroundColor: #f0f0f0;

.child-container {
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .child-no-data-message {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $backgroundColor;
    }

    .child-ticket-add {
        margin-top: 50px;
    }
}
