.time-picker {
    position: relative;
    width: 100%;
    .timepicker {
        &--error {
            border: 1px solid red;
        }
    }
    .dropdown-menu {
        position: absolute;
        z-index: 1;
        top: 65px;
        border-radius: 6px;
        border: 2px solid #1ea2b1;
        max-height: 250px;
        padding-top: 4px;
        overflow-y: scroll;
        width: 276px;
        background: #fff;
        .dropdown-item {
            padding: 8px;
            cursor: pointer;
            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}
