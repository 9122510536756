$gap-small: 8px;
$gap-medium: 12px;
$border-radius: 8px;
$border-color: #f0f0f0;
$background-color: #fafafa;
$font-size-small: 12px;
$font-weight-bold: bold;

.report-content {
    display: flex;
    gap: $gap-medium;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.device-info {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: $gap-medium;
    padding: $gap-medium;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    background-color: $background-color;

    .device-label {
        display: flex;
        justify-content: space-between;
    }

    .device-name,
    .uptime {
        display: flex;
        flex-direction: column;
        .restart-days {
            display: flex;
            gap: 5px;
        }
        > div {
            font-weight: $font-weight-bold;
        }

        .virtual-tag {
            font-size: $font-size-small;
        }
        .recommendation {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-top: 5px;
        }
    }
}

.energy-info {
    min-width: 235px;
    display: flex;
    flex-direction: column;
    gap: $gap-medium;

    .energy-consumed,
    .energy-emitted {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        gap: $gap-medium;
        background-color: $background-color;

        .device-label {
            display: flex;
            justify-content: space-between;
        }

        .value {
            font-weight: $font-weight-bold;
        }

        .unit {
            font-size: $font-size-small;
        }
    }
}
