.loader-wrapper {
    background: #04040447;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1000;
    .loader-overlay {
        margin: 0 auto;
    }
}
