.live-agent-chat-container {

    display: flex;
    flex-direction: column;
    gap: 15px;
    background: #fff;
    margin-top: 10px;
    padding: 8px;

    .heading {
        display: flex;
        gap: 12px;
        background: #F1F2F6;
        padding: 8px;
        border-radius: 8px;
    }

    .agent-waiting-msg {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        // background: #F8F5F2;
        .bot-text {
            font-size: 12px !important;
        }
    }

    .messages {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .agent-message {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    
        .user-message {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    .switch-to-assistance-btn {
        text-align: center;
    }

    .agent-icon {
        color: var(--primary-color);
    }

    .user-reply-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .user-heading {
            margin-top: 6px;
            font-weight: normal !important;
        }
    }

    .user-header {
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 8px;
        border-radius: 8px;
        background: #F1F2F6;
    }

    .agent-header {
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 8px;
        border-radius: 8px;
        background: #F6E5DC;
    }

    .waiting-header {
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding: 8px;
        border-radius: 8px;
    }

    .chat-input-box {
        display: flex;
        gap: 10px;

        .form {
            width: 100%
        }
    }

    .message-time {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        font-size: 10px;
        min-width: 3rem;
    }

    .loader {
        letter-spacing: 1px;
    }
}