.child-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .page-sub-title {
        border-bottom: 2px solid var(--primary-color);
        line-height: 32px;
        font-size: 18px;
        font-weight: 700;
    }

    .search-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        margin-bottom: 16px;
        position: relative;

        .local-filters {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .date-filters {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    .manage-bookings-container {
        .table-font-header {
            font-size: 16px;
        }
        .table-font {
            font-size: 16px;
        }
    }

    @media screen and (min-width: 768px) {
        .search-container {
            flex-direction: column;
            .local-filters {
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                .free-text {
                    width: 100%;
                }
                .booking-type {
                    width: 40%;
                }
            }

            .date-filters {
                flex-direction: row;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        .search-container {
            flex-direction: row;
            justify-content: space-between;

            .date-filters {
                justify-content: space-between;
            }
        }
    }
}