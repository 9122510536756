.ai-assist-assistance-task {
  flex: 1;
  background-color: #fff;
  position: relative;
  .heading {
      display: none;
      justify-content: space-between;
      gap: 8px;
      color: #262626;
      font-size: 16px;
      padding: 5px 12px;
      border: 1px solid #6B7280;
      border-radius: 6px;
      .icon {
          display: none;
      }
      .arrow-icon {
          display: block;
      }
  }
  .assistance-task-wrapper {
      display: none;
      flex-direction: column;
      gap: 12px;
      position: absolute;
      background: white;
      right: 0;
      width: 260px;
      .item {
          padding: 12px;
      }
  }

  .assistance-task-item {
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: center;
  }

  @media screen and (min-width: 1024px) {
      padding: 8px;
      width: 100%;
      width: 300px;
      .heading {
          display: flex;
          font-size: 20px;
          justify-content: flex-start;
          border: none;
          border-bottom: 1px solid #6B7280;
          border-radius: 0;
          padding: 12px;
          .icon {
              display: block;
          }
          .arrow-icon {
              display: none;
          }
      }
      .assistance-task-wrapper {
          display: flex;
          flex-direction: column;
          position: inherit;
          width: fit-content;
      }
  }
}