/* AvailabilityTimeline.css */
.timeline-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 18px;
    .timeline-container {
        position: relative;
        height: 32px;
        border: 1px solid #ccc;
        background-color: #e0e0e0;
        .booking-slot {
            position: absolute;
            height: 100%;
            top: 0;
            bottom: 0;
            background-color: #ea1c0a;
            color: white;
            text-align: center;
            line-height: 50px;
            overflow: hidden;
            white-space: nowrap;
            z-index: 1;
        }
        .available-slot {
            position: absolute;
            height: 100%;
            top: 0;
            bottom: 0;
        }
    }
    .time-scale {
        position: relative;
        height: 15px;
        margin-top: 5px;
        .time-label {
            position: absolute;
            transform: translateX(-50%);
            white-space: nowrap;
            font-size: 8px;
        }
    }
}
