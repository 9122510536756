.room-locations {
    position: absolute;
    margin-top: 8px;
    width: 276px;

    .location-list-wrapper {
        border-radius: 6px;
        border: 2px solid #1ea2b1;
        max-height: 250px;
        padding-top: 4px;
        overflow-y: scroll;
        background: #fff;
    }
    button {
        background: none;
        border: none;
        width: -webkit-fill-available;
        cursor: pointer;
        text-align: left;
        line-height: 36px;
        display: flex;
        &.collapsible {
            border-radius: 40px;
            .location-name {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .home-icon {
                margin-left: auto;
                svg {
                    fill: #fff;
                    path {
                        fill: #fff;
                    }
                }
            }
            &--isHome {
                background: rgb(234 28 10 / 50%);
                font-weight: bold;
                color: #fff;
            }
            &--selected,
            &:hover,
            &:focus {
                border: 2px solid rgb(234 28 10 / 50%);
                outline: 0;
            }
        }
    }
    svg {
        width: 24px;
        height: 24px;
        padding-right: 8px;
        padding-top: 4px;
    }
}
