:root {
  --eon-transparent: transparent;
  --eon-bright-font: #262626;
  --eon-dark-font: #fff;
  --eon-neutral100-font: #262626;
  --eon-neutral300-font: #262626;
  --eon-neutral800-font: #fff;
  --eon-red100-font: #262626;
  --eon-red300-font: #262626;
  --eon-red500-font: #fff;
  --eon-red800-font: #fff;
  --eon-yellow100-font: #262626;
  --eon-yellow300-font: #262626;
  --eon-yellow500-font: #262626;
  --eon-turquoise100-font: #262626;
  --eon-turquoise300-font: #262626;
  --eon-turquoise500-font: #262626;
  --eon-black-font: #fff;
  --eon-violet-dark: #6d199a;
  --eon-red200: #f6c1b0;
  --eon-red200-opacity-25: #f6c1b040;
  --eon-red400: #f05b48;
  --eon-red400-opacity-25: #f05b4840;
  --eon-red600: #e01000;
  --eon-red700: #c90a01;
  --eon-red700-opacity-20: #c90a0133;
  --eon-red900: #76020d;
  --eon-yellow200: #f2efa3;
  --eon-yellow400: #e8e536;
  --eon-yellow600: #9eb22d;
  --eon-yellow700: #61822a;
  --eon-yellow800: #3d5327;
  --eon-yellow900: #1c301e;
  --eon-turquoise200: #b0dade;
  --eon-turquoise400: #4fb5c0;
  --eon-turquoise600: #178a9d;
  --eon-turquoise700: #0f738a;
  --eon-turquoise800: #144d62;
  --eon-turquoise900: #182739;
  --eon-neutral200: #fcfbfa;
  --eon-neutral400: #e6e3e1;
  --eon-neutral500: #cccbca;
  --eon-neutral600: #999;
  --eon-neutral600-opacity-25: #99999940;
  --eon-neutral700: #666;
  --eon-neutral700-opacity-25: #66666640;
  --eon-neutral900: #000;
  --eon-system-success-light: #d7e9ca;
  --eon-system-success: #18a087;
  --eon-system-success-dark: #0d6d68;
  --eon-system-warning-light: #f9f2d8;
  --eon-system-warning: #ffc940;
  --eon-system-warning-dark: #ff711a;
  --eon-system-error-light: #f6c1b0;
  --eon-system-error: #f05b48;
  --eon-system-error-dark: #c41708;
  --eon-system-highlight-light: #ccebff;
  --eon-system-highlight: #0075c2;
  --eon-system-highlight-dark: #00568f;
  --eon-white: #fff;
  --eon-grey100: #f8f5f2;
  --eon-grey200: #f0ebe5;
  --eon-grey300: #d7d0cc;
  --eon-grey400: #b3aca9;
  --eon-grey500: #958d8b;
  --eon-grey600: #767676;
  --eon-grey700: #5c5c5c;
  --eon-grey800: #404040;
  --eon-grey900: #262626;
  --eon-blue100: #ccebff;
  --eon-blue200: #a3daff;
  --eon-blue300: #7acaff;
  --eon-blue400: #52baff;
  --eon-blue500: #27a9ff;
  --eon-blue600: #0093f5;
  --eon-blue700: #0075c2;
  --eon-blue800: #00568f;
  --eon-blue900: #00375c;
  --eon-purple100: #f0d8eb;
  --eon-purple200: #dba6d1;
  --eon-purple300: #ca6fba;
  --eon-purple400: #b0429d;
  --eon-purple500: #961482;
  --eon-purple600: #821171;
  --eon-purple700: #710f62;
  --eon-purple800: #5f0c52;
  --eon-purple900: #4d0a42;
  --eon-anchor-navigation-background-color: #fff;
  --eon-anchor-navigation-background-hover-color: #fff;
  --eon-anchor-navigation-icon-color: #262626;
  --eon-anchor-navigation-icon-hover-color: #c90a01;
  --eon-anchor-navigation-icon-highlight-color: #ea1b0a;
  --eon-anchor-navigation-item-background-color: #fff;
  --eon-anchor-navigation-item-hover-color: #f9f6f4;
  --eon-anchor-navigation-link-color: #262626;
  --eon-anchor-navigation-link-hover-color: #c90a01;
  --eon-anchor-navigation-link-highlight-color: #ea1b0a;
  --eon-context-menu-border-color: #1ea2b1;
  --eon-context-menu-divider-border-color: #cccbca;
  --eon-context-menu-item-background-hover-color: #f9f6f4;
  --eon-context-menu-item-background-active-color: #e1eded;
  --eon-data-table-head-cell-color: #262626;
  --eon-data-table-head-cell-icon-color: #262626;
  --eon-data-table-head-cell-background: #f9f6f4;
  --eon-data-table-head-cell-background-hover: #e6e3e1;
  --eon-data-table-head-cell-background-act: #e6e3e1;
  --eon-data-table-head-cell-background-act-hover: #cccbca;
  --eon-data-table-head-cell-border: #e6e3e1;
  --eon-data-table-head-cell-border-hover: #cccbca;
  --eon-data-table-head-cell-border-act: #cccbca;
  --eon-data-table-head-cell-border-act-hover: #cccbca;
  --eon-data-table-cell-color: #262626;
  --eon-data-table-cell-icon-color: #262626;
  --eon-data-table-cell-background: #fff;
  --eon-data-table-cell-background-hover: #fcfbfa;
  --eon-data-table-cell-background-selected: #e1eded;
  --eon-data-table-cell-background-selected-hover: #b0dade;
  --eon-data-table-cell-background-odd: #fff;
  --eon-data-table-cell-background-odd-hover: #f9f6f4;
  --eon-data-table-cell-background-even: #fcfbfa;
  --eon-data-table-cell-background-even-hover: #f9f6f4;
  --eon-data-table-cell-border: #e6e3e1;
  --eon-data-table-cell-border-hover: #e6e3e1;
  --eon-data-table-cell-border-selected: #b0dade;
  --eon-data-table-cell-border-selected-hover: #7fc7cf;
  --eon-data-table-cell-border-odd: #e6e3e1;
  --eon-data-table-cell-border-odd-hover: #e6e3e1;
  --eon-data-table-cell-border-even: #e6e3e1;
  --eon-data-table-cell-border-even-hover: #e6e3e1;
  --eon-data-table-toolbar-background: #fff;
  --eon-data-table-toolbar-color: #262626;
  --eon-data-table-toolbar-selected-background: #7fc7cf;
  --eon-data-table-toolbar-selected-color: #262626;
  --eon-footer-bar-copyright-color: #666;
  --eon-footer-bar-background-color: #fff;
  --eon-footer-bar-link-color: #262626;
  --eon-footer-bar-link-hover-color: #262626;
  --eon-navigation-box-shadow: none;
  --eon-navigation-meta-bg-color: #f9f6f4;
  --eon-navigation-meta-border-color: #e6e3e1;
  --eon-navigation-meta-link-font-color: #262626;
  --eon-navigation-meta-link-font-color-hover: #262626;
  --eon-navigation-meta-link-font-color-focus: #262626;
  --eon-navigation-meta-link-font-color-current: #262626;
  --eon-navigation-meta-link-font-color-current-hover: #262626;
  --eon-navigation-meta-link-font-color-current-focus: #262626;
  --eon-navigation-meta-link-bg-color: transparent;
  --eon-navigation-meta-link-bg-color-hover: #e6e3e1;
  --eon-navigation-meta-link-bg-color-focus: transparent;
  --eon-navigation-meta-link-bg-color-current: transparent;
  --eon-navigation-meta-link-bg-color-current-hover: #e6e3e1;
  --eon-navigation-meta-link-bg-color-current-focus: transparent;
  --eon-navigation-meta-link-border-color: transparent;
  --eon-navigation-meta-link-border-color-hover: #262626;
  --eon-navigation-meta-link-border-color-focus: #999;
  --eon-navigation-meta-link-border-color-current: #262626;
  --eon-navigation-meta-link-border-color-current-hover: #262626;
  --eon-navigation-meta-link-border-color-current-focus: #262626;
  --eon-navigation-toolbar-bg-color: #fff;
  --eon-navigation-toolbar-icon-link-font-color: #262626;
  --eon-navigation-toolbar-icon-link-font-color-hover: #262626;
  --eon-navigation-toolbar-icon-link-font-color-focus: #262626;
  --eon-navigation-toolbar-icon-link-font-color-current: #ea1b0a;
  --eon-navigation-toolbar-icon-link-font-color-current-hover: #ea1b0a;
  --eon-navigation-toolbar-icon-link-font-color-current-focus: #76020d;
  --eon-navigation-toolbar-icon-link-font-color-active: #262626;
  --eon-navigation-toolbar-icon-link-font-color-active-hover: #262626;
  --eon-navigation-toolbar-icon-link-font-color-active-focus: #262626;
  --eon-navigation-toolbar-icon-link-bg-color: transparent;
  --eon-navigation-toolbar-icon-link-bg-color-hover: #f9f6f4;
  --eon-navigation-toolbar-icon-link-bg-color-focus: transparent;
  --eon-navigation-toolbar-icon-link-bg-color-current: transparent;
  --eon-navigation-toolbar-icon-link-bg-color-current-hover: #f9f6f4;
  --eon-navigation-toolbar-icon-link-bg-color-current-focus: #f9f6f4;
  --eon-navigation-toolbar-icon-link-bg-color-active: #f9f6f4;
  --eon-navigation-toolbar-icon-link-bg-color-active-hover: #f9f6f4;
  --eon-navigation-toolbar-icon-link-bg-color-active-focus: #f9f6f4;
  --eon-navigation-toolbar-sub-bg-color: #f9f6f4;
  --eon-navigation-main-bg-color: #fff;
  --eon-navigation-main-border-color: #e6e3e1;
  --eon-navigation-main-link-font-color: #262626;
  --eon-navigation-main-link-font-color-hover: #262626;
  --eon-navigation-main-link-font-color-focus: #262626;
  --eon-navigation-main-link-font-color-current: #ea1b0a;
  --eon-navigation-main-link-font-color-current-hover: #262626;
  --eon-navigation-main-link-font-color-current-focus: #ea1b0a;
  --eon-navigation-main-link-font-color-active: #262626;
  --eon-navigation-main-link-font-color-active-hover: #262626;
  --eon-navigation-main-link-font-color-active-focus: #262626;
  --eon-navigation-main-link-bg-color: transparent;
  --eon-navigation-main-link-bg-color-hover: #f9f6f4;
  --eon-navigation-main-link-bg-color-focus: transparent;
  --eon-navigation-main-link-bg-color-current: transparent;
  --eon-navigation-main-link-bg-color-current-hover: #f9f6f4;
  --eon-navigation-main-link-bg-color-current-focus: transparent;
  --eon-navigation-main-link-bg-color-active: #f9f6f4;
  --eon-navigation-main-link-bg-color-active-hover: #f9f6f4;
  --eon-navigation-main-link-bg-color-active-focus: #f9f6f4;
  --eon-navigation-main-link-border-color: transparent;
  --eon-navigation-main-link-border-color-hover: #262626;
  --eon-navigation-main-link-border-color-focus: #262626;
  --eon-navigation-main-link-border-color-current: #ea1b0a;
  --eon-navigation-main-link-border-color-current-hover: #262626;
  --eon-navigation-main-link-border-color-current-focus: #0075c2;
  --eon-navigation-main-link-border-color-active: transparent;
  --eon-navigation-main-link-border-color-active-hover: #262626;
  --eon-navigation-main-link-border-color-active-focus: #f9f6f4;
  --eon-navigation-main-icon-link-font-color: #262626;
  --eon-navigation-main-icon-link-font-color-hover: #262626;
  --eon-navigation-main-icon-link-font-color-focus: #262626;
  --eon-navigation-main-icon-link-font-color-current: #ea1b0a;
  --eon-navigation-main-icon-link-font-color-current-hover: #262626;
  --eon-navigation-main-icon-link-font-color-current-focus: #262626;
  --eon-navigation-main-icon-link-font-color-active: #262626;
  --eon-navigation-main-icon-link-font-color-active-hover: #262626;
  --eon-navigation-main-icon-link-font-color-active-focus: #262626;
  --eon-navigation-main-icon-link-bg-color: transparent;
  --eon-navigation-main-icon-link-bg-color-hover: #f9f6f4;
  --eon-navigation-main-icon-link-bg-color-focus: transparent;
  --eon-navigation-main-icon-link-bg-color-current: transparent;
  --eon-navigation-main-icon-link-bg-color-current-hover: #f9f6f4;
  --eon-navigation-main-icon-link-bg-color-current-focus: #f9f6f4;
  --eon-navigation-main-icon-link-bg-color-active: #f9f6f4;
  --eon-navigation-main-icon-link-bg-color-active-hover: #f9f6f4;
  --eon-navigation-main-icon-link-bg-color-active-focus: #f9f6f4;
  --eon-navigation-main-icon-link-border-color: transparent;
  --eon-navigation-main-icon-link-border-color-hover: #262626;
  --eon-navigation-main-icon-link-border-color-focus: #262626;
  --eon-navigation-main-icon-link-border-color-current: transparent;
  --eon-navigation-main-icon-link-border-color-current-hover: transparent;
  --eon-navigation-main-icon-link-border-color-current-focus: transparent;
  --eon-navigation-main-icon-link-border-color-active: #f9f6f4;
  --eon-navigation-main-icon-link-border-color-active-hover: #262626;
  --eon-navigation-main-icon-link-border-color-active-focus: #f9f6f4;
  --eon-navigation-main-sub-bg-color: #f9f6f4;
  --eon-navigation-main-sub-box-shadow: #00000014 0px 4px 8px;
  --eon-navigation-flyout-divider-bg-color: #e6e3e1;
  --eon-navigation-flyout-link-font-color: #262626;
  --eon-navigation-flyout-link-font-color-hover: #ea1b0a;
  --eon-navigation-flyout-link-font-color-focus: #76020d;
  --eon-navigation-flyout-link-font-color-current: #ea1b0a;
  --eon-navigation-flyout-link-border-color-current: #ea1b0a;
  --eon-navigation-flyout-link-large-font-color: #262626;
  --eon-navigation-flyout-link-large-font-color-hover: #ea1b0a;
  --eon-navigation-flyout-link-large-font-color-focus: #76020d;
  --eon-navigation-flyout-link-large-font-color-current: #ea1b0a;
  --eon-navigation-flyout-link-large-border-color-current: #ea1b0a;
  --eon-navigation-mobile-bg-color: #f9f6f4;
  --eon-navigation-mobile-link-font-color: #262626;
  --eon-navigation-mobile-link-highlight-border-color: #ea1b0a;
  --eon-navigation-mobile-link-border-color: #e6e3e1;
  --eon-navigation-focus-border-color: #0075c2;
  --eon-navigation-main-border-color-focus-visible: transparent;
  --eon-navigation-logo-focus-color: #0075c2;
  --eon-section-video-control-color-light: #fff;
  --eon-section-video-control-color-dark: #262626;
  --eon-search-result-item-mark-color: #edea6c;
  --eon-search-result-item-visited-color: #6d199a;
  --eon-search-result-item-background-color: #fff;
  --eon-table-renderer-row-hover-color: #e1eded;
  --eon-videoplayer-focus-outline-color: #7fc7cf;
  --eon-videoplayer-error-color: #fff;
  --eon-videoplayer-error-background-color: #cccbca;
  --eon-videoplayer-controls-background: #fff;
  --eon-videoplayer-progress-background: #fff;
  --eon-videoplayer-progress-color: #edea6c;
  --eon-videoplayer-progress-bar-color: #edea6c;
  --eon-videoplayer-progress-bar-background: #7fc7cf;
  --eon-videoplayer-progress-bar-active-background: #f7f4d9;
  --eon-videoplayer-progress-bar-time-color: #262626;
  --eon-videoplayer-progress-bar-value-background: #7fc7cf;
  --eon-videoplayer-progress-bar-button-color: #fff;
  --eon-videoplayer-progress-bar-button-focus: #fff;
  --eon-videoplayer-progress-bar-play-button-background: #1ea2b1;
  --eon-videoplayer-progress-bar-play-button-hover-background: #e1eded;
  --eon-videoplayer-progress-bar-mute-button-background: #f9f6f4;
  --eon-videoplayer-progress-bar-mute-button-hover-background: #f59a86;
  --eon-videoplayer-progress-bar-full-screen-button-background: #f9f6f4;
  --eon-videoplayer-progress-bar-full-screen-button-hover-background: #c90a01;
  --eon-videoplayer-icon-color: #262626;
  --eon-website-navigation-box-shadow: none;
  --eon-website-navigation-meta-bg-color: #f9f6f4;
  --eon-website-navigation-meta-border-color: #e6e3e1;
  --eon-website-navigation-meta-link-font-color: #262626;
  --eon-website-navigation-meta-link-font-color-hover: #262626;
  --eon-website-navigation-meta-link-font-color-current: #262626;
  --eon-website-navigation-meta-link-font-color-current-hover: #262626;
  --eon-website-navigation-meta-link-bg-color: transparent;
  --eon-website-navigation-meta-link-bg-color-hover: #e6e3e1;
  --eon-website-navigation-meta-link-bg-color-current: transparent;
  --eon-website-navigation-meta-link-bg-color-current-hover: #e6e3e1;
  --eon-website-navigation-meta-link-border-color: transparent;
  --eon-website-navigation-meta-link-border-color-hover: #262626;
  --eon-website-navigation-meta-link-border-color-current: #262626;
  --eon-website-navigation-meta-link-border-color-current-hover: #262626;
  --eon-website-navigation-toolbar-bg-color: #fff;
  --eon-website-navigation-toolbar-link-font-color: #262626;
  --eon-website-navigation-toolbar-link-font-color-hover: #262626;
  --eon-website-navigation-toolbar-link-font-color-current: #ea1b0a;
  --eon-website-navigation-toolbar-link-font-color-current-hover: #ea1b0a;
  --eon-website-navigation-toolbar-link-font-color-active: #ea1b0a;
  --eon-website-navigation-toolbar-link-font-color-active-hover: #ea1b0a;
  --eon-website-navigation-toolbar-link-bg-color: transparent;
  --eon-website-navigation-toolbar-link-bg-color-hover: #f9f6f4;
  --eon-website-navigation-toolbar-link-bg-color-current: transparent;
  --eon-website-navigation-toolbar-link-bg-color-current-hover: #f9f6f4;
  --eon-website-navigation-toolbar-link-bg-color-active: #f9f6f4;
  --eon-website-navigation-toolbar-link-bg-color-active-hover: #f9f6f4;
  --eon-website-navigation-toolbar-sub-bg-color: #f9f6f4;
  --eon-website-navigation-main-bg-color: #fff;
  --eon-website-navigation-main-border-color: #e6e3e1;
  --eon-website-navigation-main-link-font-color: #262626;
  --eon-website-navigation-main-link-font-color-hover: #262626;
  --eon-website-navigation-main-link-font-color-current: #ea1b0a;
  --eon-website-navigation-main-link-font-color-current-hover: #262626;
  --eon-website-navigation-main-link-font-color-active: #262626;
  --eon-website-navigation-main-link-font-color-active-hover: #262626;
  --eon-website-navigation-main-link-bg-color: transparent;
  --eon-website-navigation-main-link-bg-color-hover: #f9f6f4;
  --eon-website-navigation-main-link-bg-color-current: transparent;
  --eon-website-navigation-main-link-bg-color-current-hover: #f9f6f4;
  --eon-website-navigation-main-link-bg-color-active: #f9f6f4;
  --eon-website-navigation-main-link-bg-color-active-hover: #f9f6f4;
  --eon-website-navigation-main-link-border-color: transparent;
  --eon-website-navigation-main-link-border-color-hover: #262626;
  --eon-website-navigation-main-link-border-color-current: #ea1b0a;
  --eon-website-navigation-main-link-border-color-current-hover: #262626;
  --eon-website-navigation-main-link-border-color-active: #ea1b0a;
  --eon-website-navigation-main-link-border-color-active-hover: #262626;
  --eon-website-navigation-main-sub-bg-color: #f9f6f4;
  --eon-website-navigation-main-sub-box-shadow: #00000014 0px 4px 8px;
  --eon-website-navigation-flyout-divider-bg-color: #e6e3e1;
  --eon-website-navigation-flyout-link-font-color: #262626;
  --eon-website-navigation-flyout-link-font-color-hover: #ea1b0a;
  --eon-website-navigation-flyout-link-font-color-current: #ea1b0a;
  --eon-website-navigation-flyout-link-border-color-current: #ea1b0a;
  --eon-website-navigation-flyout-link-large-font-color: #262626;
  --eon-website-navigation-flyout-link-large-font-color-hover: #ea1b0a;
  --eon-website-navigation-flyout-link-large-font-color-current: #ea1b0a;
  --eon-website-navigation-flyout-link-large-border-color-current: #ea1b0a;
  --eon-website-navigation-mobile-bg-color: #f9f6f4;
  --eon-website-navigation-mobile-link-font-color: #262626;
  --eon-website-navigation-mobile-link-highlight-border-color: #ea1b0a;
  --eon-website-navigation-mobile-link-border-color: #e6e3e1;
  --eon-bright: #fff;
  --eon-bright-shade-1: #e6e6e6;
  --eon-bright-shade-2: #ccc;
  --eon-bright-tint-1: white;
  --eon-bright-tint-2: white;
  --eon-dark: #262626;
  --eon-dark-shade-1: #0d0d0d;
  --eon-dark-shade-2: black;
  --eon-dark-tint-1: #404040;
  --eon-dark-tint-2: #595959;
  --eon-neutral100: #fff;
  --eon-neutral100-shade-1: #e6e6e6;
  --eon-neutral100-shade-2: #ccc;
  --eon-neutral100-tint-1: white;
  --eon-neutral100-tint-2: white;
  --eon-neutral300: #f9f6f4;
  --eon-neutral300-shade-1: #e7dbd3;
  --eon-neutral300-shade-2: #d5c0b2;
  --eon-neutral300-tint-1: white;
  --eon-neutral300-tint-2: white;
  --eon-neutral800: #262626;
  --eon-neutral800-shade-1: #0d0d0d;
  --eon-neutral800-shade-2: black;
  --eon-neutral800-tint-1: #404040;
  --eon-neutral800-tint-2: #595959;
  --eon-red100: #f7e8da;
  --eon-red100-shade-1: #eeceb0;
  --eon-red100-shade-2: #e5b486;
  --eon-red100-tint-1: white;
  --eon-red100-tint-2: white;
  --eon-red300: #f59a86;
  --eon-red300-shade-1: #f17357;
  --eon-red300-shade-2: #ed4b28;
  --eon-red300-tint-1: #f9c1b5;
  --eon-red300-tint-2: #fde9e4;
  --eon-red500: #ea1b0a;
  --eon-red500-shade-1: #b91508;
  --eon-red500-shade-2: #881006;
  --eon-red500-tint-1: #f64031;
  --eon-red500-tint-2: #f86d62;
  --eon-red800: #b00402;
  --eon-red800-shade-1: #7e0301;
  --eon-red800-shade-2: #4b0201;
  --eon-red800-tint-1: #e20503;
  --eon-red800-tint-2: #fc1e1c;
  --eon-yellow100: #f7f4d9;
  --eon-yellow100-shade-1: #eee8af;
  --eon-yellow100-shade-2: #e5dc85;
  --eon-yellow100-tint-1: white;
  --eon-yellow100-tint-2: white;
  --eon-yellow300: #edea6c;
  --eon-yellow300-shade-1: #e7e43f;
  --eon-yellow300-shade-2: #d8d41b;
  --eon-yellow300-tint-1: #f3f099;
  --eon-yellow300-tint-2: #f8f7c7;
  --eon-yellow500: #e3e000;
  --eon-yellow500-shade-1: #b0ae00;
  --eon-yellow500-shade-2: #7d7b00;
  --eon-yellow500-tint-1: #fffc17;
  --eon-yellow500-tint-2: #fffd4a;
  --eon-turquoise100: #e1eded;
  --eon-turquoise100-shade-1: #c1dada;
  --eon-turquoise100-shade-2: #a1c7c7;
  --eon-turquoise100-tint-1: white;
  --eon-turquoise100-tint-2: white;
  --eon-turquoise300: #7fc7cf;
  --eon-turquoise300-shade-1: #5ab7c1;
  --eon-turquoise300-shade-2: #3f9ea9;
  --eon-turquoise300-tint-1: #a4d7dd;
  --eon-turquoise300-tint-2: #c9e7eb;
  --eon-turquoise500: #1ea2b1;
  --eon-turquoise500-shade-1: #177a85;
  --eon-turquoise500-shade-2: #0f525a;
  --eon-turquoise500-tint-1: #28c8da;
  --eon-turquoise500-tint-2: #53d3e2;
  --eon-black: #000;
  --eon-black-shade-1: black;
  --eon-black-shade-2: black;
  --eon-black-tint-1: #1a1a1a;
  --eon-black-tint-2: #333;
  --eon-bright-5: white;
  --eon-dark-5: #f4f4f4;
  --eon-neutral100-5: white;
  --eon-neutral300-5: #fffffe;
  --eon-neutral800-5: #f4f4f4;
  --eon-red100-5: #fffefd;
  --eon-red300-5: #fffaf9;
  --eon-red500-5: #fef4f3;
  --eon-red800-5: #fbf2f2;
  --eon-yellow100-5: #fffefd;
  --eon-yellow300-5: #fefef8;
  --eon-yellow500-5: #fefdf2;
  --eon-turquoise100-5: #fefefe;
  --eon-turquoise300-5: #f9fcfd;
  --eon-turquoise500-5: #f4fafb;
  --eon-black-5: #f2f2f2;
  --eon-bright-10: white;
  --eon-dark-10: #e9e9e9;
  --eon-neutral100-10: white;
  --eon-neutral300-10: #fefefe;
  --eon-neutral800-10: #e9e9e9;
  --eon-red100-10: #fefdfb;
  --eon-red300-10: #fef5f3;
  --eon-red500-10: #fde8e7;
  --eon-red800-10: #f7e6e6;
  --eon-yellow100-10: #fefefb;
  --eon-yellow300-10: #fdfdf0;
  --eon-yellow500-10: #fcfce6;
  --eon-turquoise100-10: #fcfdfd;
  --eon-turquoise300-10: #f2f9fa;
  --eon-turquoise500-10: #e9f6f7;
  --eon-black-10: #e6e6e6;
  --eon-bright-15: white;
  --eon-dark-15: #dedede;
  --eon-neutral100-15: white;
  --eon-neutral300-15: #fefefd;
  --eon-neutral800-15: #dedede;
  --eon-red100-15: #fefcf9;
  --eon-red300-15: #fef0ed;
  --eon-red500-15: #fcddda;
  --eon-red800-15: #f3d9d9;
  --eon-yellow100-15: #fefdf9;
  --eon-yellow300-15: #fcfce9;
  --eon-yellow500-15: #fbfad9;
  --eon-turquoise100-15: #fbfcfc;
  --eon-turquoise300-15: #ecf7f8;
  --eon-turquoise500-15: #ddf1f3;
  --eon-black-15: #d9d9d9;
  --eon-bright-20: white;
  --eon-dark-20: #d4d4d4;
  --eon-neutral100-20: white;
  --eon-neutral300-20: #fefdfd;
  --eon-neutral800-20: #d4d4d4;
  --eon-red100-20: #fdfaf8;
  --eon-red300-20: #fdebe7;
  --eon-red500-20: #fbd1ce;
  --eon-red800-20: #efcdcc;
  --eon-yellow100-20: #fdfdf7;
  --eon-yellow300-20: #fbfbe2;
  --eon-yellow500-20: #f9f9cc;
  --eon-turquoise100-20: #f9fbfb;
  --eon-turquoise300-20: #e5f4f5;
  --eon-turquoise500-20: #d2ecef;
  --eon-black-20: #ccc;
  --eon-bright-25: white;
  --eon-dark-25: #c9c9c9;
  --eon-neutral100-25: white;
  --eon-neutral300-25: #fefdfc;
  --eon-neutral800-25: #c9c9c9;
  --eon-red100-25: #fdf9f6;
  --eon-red300-25: #fde6e1;
  --eon-red500-25: #fac6c2;
  --eon-red800-25: #ebc0c0;
  --eon-yellow100-25: #fdfcf6;
  --eon-yellow300-25: #fbfada;
  --eon-yellow500-25: #f8f7bf;
  --eon-turquoise100-25: #f8fbfb;
  --eon-turquoise300-25: #dff1f3;
  --eon-turquoise500-25: #c7e8ec;
  --eon-black-25: #bfbfbf;
  --eon-bright-30: white;
  --eon-dark-30: #bebebe;
  --eon-neutral100-30: white;
  --eon-neutral300-30: #fdfcfc;
  --eon-neutral800-30: #bebebe;
  --eon-red100-30: #fdf8f4;
  --eon-red300-30: #fce1db;
  --eon-red500-30: #f9bbb6;
  --eon-red800-30: #e7b4b3;
  --eon-yellow100-30: #fdfcf4;
  --eon-yellow300-30: #faf9d3;
  --eon-yellow500-30: #f7f6b3;
  --eon-turquoise100-30: #f6fafa;
  --eon-turquoise300-30: #d9eef1;
  --eon-turquoise500-30: #bce3e8;
  --eon-black-30: #b3b3b3;
  --eon-bright-35: white;
  --eon-dark-35: #b3b3b3;
  --eon-neutral100-35: white;
  --eon-neutral300-35: #fdfcfb;
  --eon-neutral800-35: #b3b3b3;
  --eon-red100-35: #fcf7f2;
  --eon-red300-35: #fcdcd5;
  --eon-red500-35: #f8afa9;
  --eon-red800-35: #e3a7a6;
  --eon-yellow100-35: #fcfbf2;
  --eon-yellow300-35: #f9f8cc;
  --eon-yellow500-35: #f5f4a6;
  --eon-turquoise100-35: #f5f9f9;
  --eon-turquoise300-35: #d2ebee;
  --eon-turquoise500-35: #b0dee4;
  --eon-black-35: #a6a6a6;
  --eon-bright-40: white;
  --eon-dark-40: #a8a8a8;
  --eon-neutral100-40: white;
  --eon-neutral300-40: #fdfbfb;
  --eon-neutral800-40: #a8a8a8;
  --eon-red100-40: #fcf6f0;
  --eon-red300-40: #fbd7cf;
  --eon-red500-40: #f7a49d;
  --eon-red800-40: #df9b9a;
  --eon-yellow100-40: #fcfbf0;
  --eon-yellow300-40: #f8f7c4;
  --eon-yellow500-40: #f4f399;
  --eon-turquoise100-40: #f3f8f8;
  --eon-turquoise300-40: #cce9ec;
  --eon-turquoise500-40: #a5dae0;
  --eon-black-40: #999;
  --eon-bright-45: white;
  --eon-dark-45: #9d9d9d;
  --eon-neutral100-45: white;
  --eon-neutral300-45: #fcfbfa;
  --eon-neutral800-45: #9d9d9d;
  --eon-red100-45: #fbf5ee;
  --eon-red300-45: #fbd2c9;
  --eon-red500-45: #f69891;
  --eon-red800-45: #db8e8d;
  --eon-yellow100-45: #fbfaee;
  --eon-yellow300-45: #f7f6bd;
  --eon-yellow500-45: #f2f18c;
  --eon-turquoise100-45: #f2f7f7;
  --eon-turquoise300-45: #c5e6e9;
  --eon-turquoise500-45: #9ad5dc;
  --eon-black-45: #8c8c8c;
  --eon-bright-50: white;
  --eon-dark-50: #939393;
  --eon-neutral100-50: white;
  --eon-neutral300-50: #fcfbfa;
  --eon-neutral800-50: #939393;
  --eon-red100-50: #fbf4ed;
  --eon-red300-50: #facdc3;
  --eon-red500-50: #f58d85;
  --eon-red800-50: #d88281;
  --eon-yellow100-50: #fbfaec;
  --eon-yellow300-50: #f6f5b6;
  --eon-yellow500-50: #f1f080;
  --eon-turquoise100-50: #f0f6f6;
  --eon-turquoise300-50: #bfe3e7;
  --eon-turquoise500-50: #8fd1d8;
  --eon-black-50: gray;
  --eon-bright-55: white;
  --eon-dark-55: #888;
  --eon-neutral100-55: white;
  --eon-neutral300-55: #fcfaf9;
  --eon-neutral800-55: #888;
  --eon-red100-55: #fbf2eb;
  --eon-red300-55: #fac7bc;
  --eon-red500-55: #f38278;
  --eon-red800-55: #d47574;
  --eon-yellow100-55: #fbf9ea;
  --eon-yellow300-55: #f5f3ae;
  --eon-yellow500-55: #f0ee73;
  --eon-turquoise100-55: #eff5f5;
  --eon-turquoise300-55: #b9e0e5;
  --eon-turquoise500-55: #83ccd4;
  --eon-black-55: #737373;
  --eon-bright-60: white;
  --eon-dark-60: #7d7d7d;
  --eon-neutral100-60: white;
  --eon-neutral300-60: #fbfaf8;
  --eon-neutral800-60: #7d7d7d;
  --eon-red100-60: #faf1e9;
  --eon-red300-60: #f9c2b6;
  --eon-red500-60: #f2766c;
  --eon-red800-60: #d06867;
  --eon-yellow100-60: #faf8e8;
  --eon-yellow300-60: #f4f2a7;
  --eon-yellow500-60: #eeec66;
  --eon-turquoise100-60: #edf4f4;
  --eon-turquoise300-60: #b2dde2;
  --eon-turquoise500-60: #78c7d0;
  --eon-black-60: #666;
  --eon-bright-65: white;
  --eon-dark-65: #727272;
  --eon-neutral100-65: white;
  --eon-neutral300-65: #fbf9f8;
  --eon-neutral800-65: #727272;
  --eon-red100-65: #faf0e7;
  --eon-red300-65: #f9bdb0;
  --eon-red500-65: #f16b60;
  --eon-red800-65: #cc5c5b;
  --eon-yellow100-65: #faf8e6;
  --eon-yellow300-65: #f3f19f;
  --eon-yellow500-65: #edeb59;
  --eon-turquoise100-65: #ecf3f3;
  --eon-turquoise300-65: #acdbe0;
  --eon-turquoise500-65: #6dc3cc;
  --eon-black-65: #595959;
  --eon-bright-70: white;
  --eon-dark-70: #676767;
  --eon-neutral100-70: white;
  --eon-neutral300-70: #fbf9f7;
  --eon-neutral800-70: #676767;
  --eon-red100-70: #f9efe5;
  --eon-red300-70: #f8b8aa;
  --eon-red500-70: #f05f54;
  --eon-red800-70: #c84f4e;
  --eon-yellow100-70: #f9f7e4;
  --eon-yellow300-70: #f2f098;
  --eon-yellow500-70: #ebe94d;
  --eon-turquoise100-70: #eaf2f2;
  --eon-turquoise300-70: #a5d8dd;
  --eon-turquoise500-70: #62bec8;
  --eon-black-70: #4d4d4d;
  --eon-bright-75: white;
  --eon-dark-75: #5c5c5c;
  --eon-neutral100-75: white;
  --eon-neutral300-75: #fbf8f7;
  --eon-neutral800-75: #5c5c5c;
  --eon-red100-75: #f9eee3;
  --eon-red300-75: #f8b3a4;
  --eon-red500-75: #ef5447;
  --eon-red800-75: #c44341;
  --eon-yellow100-75: #f9f7e3;
  --eon-yellow300-75: #f2ef91;
  --eon-yellow500-75: #eae840;
  --eon-turquoise100-75: #e9f2f2;
  --eon-turquoise300-75: #9fd5db;
  --eon-turquoise500-75: #56b9c5;
  --eon-black-75: #404040;
  --eon-bright-80: white;
  --eon-dark-80: #515151;
  --eon-neutral100-80: white;
  --eon-neutral300-80: #faf8f6;
  --eon-neutral800-80: #515151;
  --eon-red100-80: #f9ede1;
  --eon-red300-80: #f7ae9e;
  --eon-red500-80: #ee493b;
  --eon-red800-80: #c03635;
  --eon-yellow100-80: #f9f6e1;
  --eon-yellow300-80: #f1ee89;
  --eon-yellow500-80: #e9e633;
  --eon-turquoise100-80: #e7f1f1;
  --eon-turquoise300-80: #99d2d9;
  --eon-turquoise500-80: #4bb5c1;
  --eon-black-80: #333;
  --eon-bright-85: white;
  --eon-dark-85: #474747;
  --eon-neutral100-85: white;
  --eon-neutral300-85: #faf7f6;
  --eon-neutral800-85: #474747;
  --eon-red100-85: #f8ebe0;
  --eon-red300-85: #f7a998;
  --eon-red500-85: #ed3d2f;
  --eon-red800-85: #bc2a28;
  --eon-yellow100-85: #f8f6df;
  --eon-yellow300-85: #f0ed82;
  --eon-yellow500-85: #e7e526;
  --eon-turquoise100-85: #e6f0f0;
  --eon-turquoise300-85: #92cfd6;
  --eon-turquoise500-85: #40b0bd;
  --eon-black-85: #262626;
  --eon-bright-90: white;
  --eon-dark-90: #3c3c3c;
  --eon-neutral100-90: white;
  --eon-neutral300-90: #faf7f5;
  --eon-neutral800-90: #3c3c3c;
  --eon-red100-90: #f8eade;
  --eon-red300-90: #f6a492;
  --eon-red500-90: #ec3223;
  --eon-red800-90: #b81d1b;
  --eon-yellow100-90: #f8f5dd;
  --eon-yellow300-90: #efec7b;
  --eon-yellow500-90: #e6e31a;
  --eon-turquoise100-90: #e4efef;
  --eon-turquoise300-90: #8ccdd4;
  --eon-turquoise500-90: #35abb9;
  --eon-black-90: #1a1a1a;
  --eon-bright-95: white;
  --eon-dark-95: #313131;
  --eon-neutral100-95: white;
  --eon-neutral300-95: #f9f6f5;
  --eon-neutral800-95: #313131;
  --eon-red100-95: #f7e9dc;
  --eon-red300-95: #f69f8c;
  --eon-red500-95: #eb2616;
  --eon-red800-95: #b4110f;
  --eon-yellow100-95: #f7f5db;
  --eon-yellow300-95: #eeeb73;
  --eon-yellow500-95: #e4e20d;
  --eon-turquoise100-95: #e3eeee;
  --eon-turquoise300-95: #85cad1;
  --eon-turquoise500-95: #29a7b5;
  --eon-black-95: #0d0d0d;
  --eon-bright-opacity-5: #ffffff0d;
  --eon-dark-opacity-5: #2626260d;
  --eon-neutral100-opacity-5: #ffffff0d;
  --eon-neutral300-opacity-5: #f9f6f40d;
  --eon-neutral800-opacity-5: #2626260d;
  --eon-red100-opacity-5: #f7e8da0d;
  --eon-red300-opacity-5: #f59a860d;
  --eon-red500-opacity-5: #ea1b0a0d;
  --eon-red800-opacity-5: #b004020d;
  --eon-yellow100-opacity-5: #f7f4d90d;
  --eon-yellow300-opacity-5: #edea6c0d;
  --eon-yellow500-opacity-5: #e3e0000d;
  --eon-turquoise100-opacity-5: #e1eded0d;
  --eon-turquoise300-opacity-5: #7fc7cf0d;
  --eon-turquoise500-opacity-5: #1ea2b10d;
  --eon-black-opacity-5: #0000000d;
  --eon-bright-opacity-10: #ffffff1a;
  --eon-dark-opacity-10: #2626261a;
  --eon-neutral100-opacity-10: #ffffff1a;
  --eon-neutral300-opacity-10: #f9f6f41a;
  --eon-neutral800-opacity-10: #2626261a;
  --eon-red100-opacity-10: #f7e8da1a;
  --eon-red300-opacity-10: #f59a861a;
  --eon-red500-opacity-10: #ea1b0a1a;
  --eon-red800-opacity-10: #b004021a;
  --eon-yellow100-opacity-10: #f7f4d91a;
  --eon-yellow300-opacity-10: #edea6c1a;
  --eon-yellow500-opacity-10: #e3e0001a;
  --eon-turquoise100-opacity-10: #e1eded1a;
  --eon-turquoise300-opacity-10: #7fc7cf1a;
  --eon-turquoise500-opacity-10: #1ea2b11a;
  --eon-black-opacity-10: #0000001a;
  --eon-bright-opacity-15: #ffffff26;
  --eon-dark-opacity-15: #26262626;
  --eon-neutral100-opacity-15: #ffffff26;
  --eon-neutral300-opacity-15: #f9f6f426;
  --eon-neutral800-opacity-15: #26262626;
  --eon-red100-opacity-15: #f7e8da26;
  --eon-red300-opacity-15: #f59a8626;
  --eon-red500-opacity-15: #ea1b0a26;
  --eon-red800-opacity-15: #b0040226;
  --eon-yellow100-opacity-15: #f7f4d926;
  --eon-yellow300-opacity-15: #edea6c26;
  --eon-yellow500-opacity-15: #e3e00026;
  --eon-turquoise100-opacity-15: #e1eded26;
  --eon-turquoise300-opacity-15: #7fc7cf26;
  --eon-turquoise500-opacity-15: #1ea2b126;
  --eon-black-opacity-15: #00000026;
  --eon-bright-opacity-20: #fff3;
  --eon-dark-opacity-20: #26262633;
  --eon-neutral100-opacity-20: #fff3;
  --eon-neutral300-opacity-20: #f9f6f433;
  --eon-neutral800-opacity-20: #26262633;
  --eon-red100-opacity-20: #f7e8da33;
  --eon-red300-opacity-20: #f59a8633;
  --eon-red500-opacity-20: #ea1b0a33;
  --eon-red800-opacity-20: #b0040233;
  --eon-yellow100-opacity-20: #f7f4d933;
  --eon-yellow300-opacity-20: #edea6c33;
  --eon-yellow500-opacity-20: #e3e00033;
  --eon-turquoise100-opacity-20: #e1eded33;
  --eon-turquoise300-opacity-20: #7fc7cf33;
  --eon-turquoise500-opacity-20: #1ea2b133;
  --eon-black-opacity-20: #0003;
  --eon-bright-opacity-25: #ffffff40;
  --eon-dark-opacity-25: #26262640;
  --eon-neutral100-opacity-25: #ffffff40;
  --eon-neutral300-opacity-25: #f9f6f440;
  --eon-neutral800-opacity-25: #26262640;
  --eon-red100-opacity-25: #f7e8da40;
  --eon-red300-opacity-25: #f59a8640;
  --eon-red500-opacity-25: #ea1b0a40;
  --eon-red800-opacity-25: #b0040240;
  --eon-yellow100-opacity-25: #f7f4d940;
  --eon-yellow300-opacity-25: #edea6c40;
  --eon-yellow500-opacity-25: #e3e00040;
  --eon-turquoise100-opacity-25: #e1eded40;
  --eon-turquoise300-opacity-25: #7fc7cf40;
  --eon-turquoise500-opacity-25: #1ea2b140;
  --eon-black-opacity-25: #00000040;
  --eon-bright-opacity-30: #ffffff4d;
  --eon-dark-opacity-30: #2626264d;
  --eon-neutral100-opacity-30: #ffffff4d;
  --eon-neutral300-opacity-30: #f9f6f44d;
  --eon-neutral800-opacity-30: #2626264d;
  --eon-red100-opacity-30: #f7e8da4d;
  --eon-red300-opacity-30: #f59a864d;
  --eon-red500-opacity-30: #ea1b0a4d;
  --eon-red800-opacity-30: #b004024d;
  --eon-yellow100-opacity-30: #f7f4d94d;
  --eon-yellow300-opacity-30: #edea6c4d;
  --eon-yellow500-opacity-30: #e3e0004d;
  --eon-turquoise100-opacity-30: #e1eded4d;
  --eon-turquoise300-opacity-30: #7fc7cf4d;
  --eon-turquoise500-opacity-30: #1ea2b14d;
  --eon-black-opacity-30: #0000004d;
  --eon-bright-opacity-35: #ffffff59;
  --eon-dark-opacity-35: #26262659;
  --eon-neutral100-opacity-35: #ffffff59;
  --eon-neutral300-opacity-35: #f9f6f459;
  --eon-neutral800-opacity-35: #26262659;
  --eon-red100-opacity-35: #f7e8da59;
  --eon-red300-opacity-35: #f59a8659;
  --eon-red500-opacity-35: #ea1b0a59;
  --eon-red800-opacity-35: #b0040259;
  --eon-yellow100-opacity-35: #f7f4d959;
  --eon-yellow300-opacity-35: #edea6c59;
  --eon-yellow500-opacity-35: #e3e00059;
  --eon-turquoise100-opacity-35: #e1eded59;
  --eon-turquoise300-opacity-35: #7fc7cf59;
  --eon-turquoise500-opacity-35: #1ea2b159;
  --eon-black-opacity-35: #00000059;
  --eon-bright-opacity-40: #fff6;
  --eon-dark-opacity-40: #26262666;
  --eon-neutral100-opacity-40: #fff6;
  --eon-neutral300-opacity-40: #f9f6f466;
  --eon-neutral800-opacity-40: #26262666;
  --eon-red100-opacity-40: #f7e8da66;
  --eon-red300-opacity-40: #f59a8666;
  --eon-red500-opacity-40: #ea1b0a66;
  --eon-red800-opacity-40: #b0040266;
  --eon-yellow100-opacity-40: #f7f4d966;
  --eon-yellow300-opacity-40: #edea6c66;
  --eon-yellow500-opacity-40: #e3e00066;
  --eon-turquoise100-opacity-40: #e1eded66;
  --eon-turquoise300-opacity-40: #7fc7cf66;
  --eon-turquoise500-opacity-40: #1ea2b166;
  --eon-black-opacity-40: #0006;
  --eon-bright-opacity-45: #ffffff73;
  --eon-dark-opacity-45: #26262673;
  --eon-neutral100-opacity-45: #ffffff73;
  --eon-neutral300-opacity-45: #f9f6f473;
  --eon-neutral800-opacity-45: #26262673;
  --eon-red100-opacity-45: #f7e8da73;
  --eon-red300-opacity-45: #f59a8673;
  --eon-red500-opacity-45: #ea1b0a73;
  --eon-red800-opacity-45: #b0040273;
  --eon-yellow100-opacity-45: #f7f4d973;
  --eon-yellow300-opacity-45: #edea6c73;
  --eon-yellow500-opacity-45: #e3e00073;
  --eon-turquoise100-opacity-45: #e1eded73;
  --eon-turquoise300-opacity-45: #7fc7cf73;
  --eon-turquoise500-opacity-45: #1ea2b173;
  --eon-black-opacity-45: #00000073;
  --eon-bright-opacity-50: #ffffff80;
  --eon-dark-opacity-50: #26262680;
  --eon-neutral100-opacity-50: #ffffff80;
  --eon-neutral300-opacity-50: #f9f6f480;
  --eon-neutral800-opacity-50: #26262680;
  --eon-red100-opacity-50: #f7e8da80;
  --eon-red300-opacity-50: #f59a8680;
  --eon-red500-opacity-50: #ea1b0a80;
  --eon-red800-opacity-50: #b0040280;
  --eon-yellow100-opacity-50: #f7f4d980;
  --eon-yellow300-opacity-50: #edea6c80;
  --eon-yellow500-opacity-50: #e3e00080;
  --eon-turquoise100-opacity-50: #e1eded80;
  --eon-turquoise300-opacity-50: #7fc7cf80;
  --eon-turquoise500-opacity-50: #1ea2b180;
  --eon-black-opacity-50: #00000080;
  --eon-bright-opacity-55: #ffffff8c;
  --eon-dark-opacity-55: #2626268c;
  --eon-neutral100-opacity-55: #ffffff8c;
  --eon-neutral300-opacity-55: #f9f6f48c;
  --eon-neutral800-opacity-55: #2626268c;
  --eon-red100-opacity-55: #f7e8da8c;
  --eon-red300-opacity-55: #f59a868c;
  --eon-red500-opacity-55: #ea1b0a8c;
  --eon-red800-opacity-55: #b004028c;
  --eon-yellow100-opacity-55: #f7f4d98c;
  --eon-yellow300-opacity-55: #edea6c8c;
  --eon-yellow500-opacity-55: #e3e0008c;
  --eon-turquoise100-opacity-55: #e1eded8c;
  --eon-turquoise300-opacity-55: #7fc7cf8c;
  --eon-turquoise500-opacity-55: #1ea2b18c;
  --eon-black-opacity-55: #0000008c;
  --eon-bright-opacity-60: #fff9;
  --eon-dark-opacity-60: #26262699;
  --eon-neutral100-opacity-60: #fff9;
  --eon-neutral300-opacity-60: #f9f6f499;
  --eon-neutral800-opacity-60: #26262699;
  --eon-red100-opacity-60: #f7e8da99;
  --eon-red300-opacity-60: #f59a8699;
  --eon-red500-opacity-60: #ea1b0a99;
  --eon-red800-opacity-60: #b0040299;
  --eon-yellow100-opacity-60: #f7f4d999;
  --eon-yellow300-opacity-60: #edea6c99;
  --eon-yellow500-opacity-60: #e3e00099;
  --eon-turquoise100-opacity-60: #e1eded99;
  --eon-turquoise300-opacity-60: #7fc7cf99;
  --eon-turquoise500-opacity-60: #1ea2b199;
  --eon-black-opacity-60: #0009;
  --eon-bright-opacity-65: #ffffffa6;
  --eon-dark-opacity-65: #262626a6;
  --eon-neutral100-opacity-65: #ffffffa6;
  --eon-neutral300-opacity-65: #f9f6f4a6;
  --eon-neutral800-opacity-65: #262626a6;
  --eon-red100-opacity-65: #f7e8daa6;
  --eon-red300-opacity-65: #f59a86a6;
  --eon-red500-opacity-65: #ea1b0aa6;
  --eon-red800-opacity-65: #b00402a6;
  --eon-yellow100-opacity-65: #f7f4d9a6;
  --eon-yellow300-opacity-65: #edea6ca6;
  --eon-yellow500-opacity-65: #e3e000a6;
  --eon-turquoise100-opacity-65: #e1ededa6;
  --eon-turquoise300-opacity-65: #7fc7cfa6;
  --eon-turquoise500-opacity-65: #1ea2b1a6;
  --eon-black-opacity-65: #000000a6;
  --eon-bright-opacity-70: #ffffffb3;
  --eon-dark-opacity-70: #262626b3;
  --eon-neutral100-opacity-70: #ffffffb3;
  --eon-neutral300-opacity-70: #f9f6f4b3;
  --eon-neutral800-opacity-70: #262626b3;
  --eon-red100-opacity-70: #f7e8dab3;
  --eon-red300-opacity-70: #f59a86b3;
  --eon-red500-opacity-70: #ea1b0ab3;
  --eon-red800-opacity-70: #b00402b3;
  --eon-yellow100-opacity-70: #f7f4d9b3;
  --eon-yellow300-opacity-70: #edea6cb3;
  --eon-yellow500-opacity-70: #e3e000b3;
  --eon-turquoise100-opacity-70: #e1ededb3;
  --eon-turquoise300-opacity-70: #7fc7cfb3;
  --eon-turquoise500-opacity-70: #1ea2b1b3;
  --eon-black-opacity-70: #000000b3;
  --eon-bright-opacity-75: #ffffffbf;
  --eon-dark-opacity-75: #262626bf;
  --eon-neutral100-opacity-75: #ffffffbf;
  --eon-neutral300-opacity-75: #f9f6f4bf;
  --eon-neutral800-opacity-75: #262626bf;
  --eon-red100-opacity-75: #f7e8dabf;
  --eon-red300-opacity-75: #f59a86bf;
  --eon-red500-opacity-75: #ea1b0abf;
  --eon-red800-opacity-75: #b00402bf;
  --eon-yellow100-opacity-75: #f7f4d9bf;
  --eon-yellow300-opacity-75: #edea6cbf;
  --eon-yellow500-opacity-75: #e3e000bf;
  --eon-turquoise100-opacity-75: #e1ededbf;
  --eon-turquoise300-opacity-75: #7fc7cfbf;
  --eon-turquoise500-opacity-75: #1ea2b1bf;
  --eon-black-opacity-75: #000000bf;
  --eon-bright-opacity-80: #fffc;
  --eon-dark-opacity-80: #262626cc;
  --eon-neutral100-opacity-80: #fffc;
  --eon-neutral300-opacity-80: #f9f6f4cc;
  --eon-neutral800-opacity-80: #262626cc;
  --eon-red100-opacity-80: #f7e8dacc;
  --eon-red300-opacity-80: #f59a86cc;
  --eon-red500-opacity-80: #ea1b0acc;
  --eon-red800-opacity-80: #b00402cc;
  --eon-yellow100-opacity-80: #f7f4d9cc;
  --eon-yellow300-opacity-80: #edea6ccc;
  --eon-yellow500-opacity-80: #e3e000cc;
  --eon-turquoise100-opacity-80: #e1ededcc;
  --eon-turquoise300-opacity-80: #7fc7cfcc;
  --eon-turquoise500-opacity-80: #1ea2b1cc;
  --eon-black-opacity-80: #000c;
  --eon-bright-opacity-85: #ffffffd9;
  --eon-dark-opacity-85: #262626d9;
  --eon-neutral100-opacity-85: #ffffffd9;
  --eon-neutral300-opacity-85: #f9f6f4d9;
  --eon-neutral800-opacity-85: #262626d9;
  --eon-red100-opacity-85: #f7e8dad9;
  --eon-red300-opacity-85: #f59a86d9;
  --eon-red500-opacity-85: #ea1b0ad9;
  --eon-red800-opacity-85: #b00402d9;
  --eon-yellow100-opacity-85: #f7f4d9d9;
  --eon-yellow300-opacity-85: #edea6cd9;
  --eon-yellow500-opacity-85: #e3e000d9;
  --eon-turquoise100-opacity-85: #e1ededd9;
  --eon-turquoise300-opacity-85: #7fc7cfd9;
  --eon-turquoise500-opacity-85: #1ea2b1d9;
  --eon-black-opacity-85: #000000d9;
  --eon-bright-opacity-90: #ffffffe6;
  --eon-dark-opacity-90: #262626e6;
  --eon-neutral100-opacity-90: #ffffffe6;
  --eon-neutral300-opacity-90: #f9f6f4e6;
  --eon-neutral800-opacity-90: #262626e6;
  --eon-red100-opacity-90: #f7e8dae6;
  --eon-red300-opacity-90: #f59a86e6;
  --eon-red500-opacity-90: #ea1b0ae6;
  --eon-red800-opacity-90: #b00402e6;
  --eon-yellow100-opacity-90: #f7f4d9e6;
  --eon-yellow300-opacity-90: #edea6ce6;
  --eon-yellow500-opacity-90: #e3e000e6;
  --eon-turquoise100-opacity-90: #e1edede6;
  --eon-turquoise300-opacity-90: #7fc7cfe6;
  --eon-turquoise500-opacity-90: #1ea2b1e6;
  --eon-black-opacity-90: #000000e6;
  --eon-bright-opacity-95: #fffffff2;
  --eon-dark-opacity-95: #262626f2;
  --eon-neutral100-opacity-95: #fffffff2;
  --eon-neutral300-opacity-95: #f9f6f4f2;
  --eon-neutral800-opacity-95: #262626f2;
  --eon-red100-opacity-95: #f7e8daf2;
  --eon-red300-opacity-95: #f59a86f2;
  --eon-red500-opacity-95: #ea1b0af2;
  --eon-red800-opacity-95: #b00402f2;
  --eon-yellow100-opacity-95: #f7f4d9f2;
  --eon-yellow300-opacity-95: #edea6cf2;
  --eon-yellow500-opacity-95: #e3e000f2;
  --eon-turquoise100-opacity-95: #e1ededf2;
  --eon-turquoise300-opacity-95: #7fc7cff2;
  --eon-turquoise500-opacity-95: #1ea2b1f2;
  --eon-black-opacity-95: #000000f2;
}

eon-ui-accordion, eon-ui-badges, eon-ui-bottom-drawer, eon-ui-businesscard-author, eon-ui-businesscard-contact, eon-ui-card-teaser, eon-ui-carousel, eon-ui-contact-list, eon-ui-content-aggregator, eon-ui-form, eon-ui-image, eon-ui-list, eon-ui-map-info-layer, eon-ui-offset-teaser, eon-ui-page-header, eon-ui-search-result-item, eon-ui-sitelink, eon-ui-stage, eon-ui-tabs, eon-ui-text-teaser, eon-ui-text, eon-ui-multi-content-stage, eon-ui-multi-content-stage-item, eon-ui-table, eon-ui-table-body, eon-ui-table-row, eon-ui-table-head-row, eon-ui-table-cell {
  visibility: hidden;
  opacity: 0;
  transition: opacity .25s cubic-bezier(.33, 0, .35, 1);
}

eon-ui-accordion.hydrated, eon-ui-badges.hydrated, eon-ui-bottom-drawer.hydrated, eon-ui-businesscard-author.hydrated, eon-ui-businesscard-contact.hydrated, eon-ui-card-teaser.hydrated, eon-ui-carousel.hydrated, eon-ui-contact-list.hydrated, eon-ui-content-aggregator.hydrated, eon-ui-form.hydrated, eon-ui-image.hydrated, eon-ui-list.hydrated, eon-ui-map-info-layer.hydrated, eon-ui-offset-teaser.hydrated, eon-ui-page-header.hydrated, eon-ui-search-result-item.hydrated, eon-ui-sitelink.hydrated, eon-ui-stage.hydrated, eon-ui-tabs.hydrated, eon-ui-text-teaser.hydrated, eon-ui-text.hydrated, eon-ui-multi-content-stage.hydrated, eon-ui-multi-content-stage-item.hydrated, eon-ui-table.hydrated, eon-ui-table-body.hydrated, eon-ui-table-row.hydrated, eon-ui-table-head-row.hydrated, eon-ui-table-cell.hydrated {
  visibility: visible;
  opacity: 1;
}

@font-face {
  font-family: Eon-font;
  src: url("EON_BrixSansWeb-Regular.3efeefa5.woff");
}

body {
  background-color: #fff;
}

body, button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: Eon-font, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

a {
  font-family: Eon-font;
}

.pre-loader-gif {
  justify-content: center;
  margin-top: 40%;
  display: flex;
  position: static;
}

.modal-data {
  color: #ea1b0a;
  text-align: center;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}

.mtb24 {
  margin: 24px 0;
}

.wrapper {
  background-color: #fff;
}

.wrapper .content {
  margin-top: 24px;
}

#root {
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  display: flex;
}

#root button:focus-visible, #root button:focus, #root a:focus-visible, #root a:focus {
  outline-color: #0075c2;
}

.me8eon-responsive-container {
  padding-left: 24px;
  padding-right: 24px;
}

@media (width >= 768px) {
  .me8eon-responsive-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (width >= 1024px) {
  .me8eon-responsive-container {
    max-width: 1296px;
    margin: 0 auto;
    padding-left: 48px;
    padding-right: 48px;
  }
}

@media (width >= 1535px) {
  .me8eon-responsive-container {
    max-width: 1536px;
  }
}

.common-button-wrapper {
  flex-direction: column;
  gap: 18px;
  margin: 24px 0;
  display: flex;
}

@media (width >= 768px) {
  .common-button-wrapper {
    flex-direction: row;
  }
}

.me-eon-header {
  z-index: 1000;
  position: sticky;
  top: 0;
}

@media screen and (width <= 540px) {
  .me-eon-header .eon-logo-wrapper {
    display: none;
  }
}

.username {
  align-items: center;
  display: flex;
}

.auth-wrapper {
  cursor: pointer;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  padding: 1px 14px;
  display: flex;
  position: relative;
}

.auth-wrapper:hover {
  background-color: #f9f6f4;
  border-bottom: 4px solid #262626;
  height: 68px;
}

.auth-wrapper .loggedout-alert {
  background: var(--eon-red500);
  border-radius: 12px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.dropdown-overlay {
  width: 48px;
  font-size: 18px;
}

.dropdown-overlay .action-wrapper {
  cursor: pointer;
  background: none;
  border: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 62px;
  margin: 0 auto;
  padding-left: 14px;
  display: flex;
}

.dropdown-overlay .action-wrapper.action-closed:hover {
  background-color: #f9f6f4;
  border-bottom: 4px solid #262626;
  height: 68px;
}

.dropdown-overlay .overlay-wrapper {
  position: relative;
}

.dropdown-overlay .overlay-wrapper .dropdown-body {
  background: #fff;
  border: 2px solid #1ea2b1;
  border-radius: 12px;
  width: 210px;
  display: none;
  position: absolute;
  top: -58px;
  right: 4px;
}

.dropdown-overlay .overlay-wrapper .dropdown-body .action-wrapper {
  height: 48px;
  padding: 0 8px 0 18px;
}

.dropdown-overlay .overlay-wrapper .dropdown-body.open {
  display: block;
}

.dropdown-overlay .overlay-wrapper .dropdown-body .dropdown-item {
  cursor: pointer;
  background: none;
  border: 0;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  display: flex;
}

.dropdown-overlay .overlay-wrapper .dropdown-body .dropdown-item:hover {
  background: #f3f2f2;
  text-decoration: underline;
}

.dropdown-overlay .overlay-wrapper .dropdown-body .dropdown-item--highlight {
  background: #e1eded;
}

.dropdown-overlay .overlay-wrapper .dropdown-body .dropdown-item:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.dropdown-overlay .overlay-wrapper .dropdown-body .dropdown-item .option-val {
  padding: 12px;
}

.dropdown-overlay .overlay-wrapper .dropdown-body .dropdown-item .turquoise-icon {
  color: #1ea2b1;
  padding: 6px;
}

.footer {
  border-top: 4px solid #e6e3e1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footer .white-section-footer {
  background-color: #fff;
  padding: 15px 16px;
}

.footer .white-section-footer .footer-left-right-spacing {
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  display: flex;
}

.footer .white-section-footer .footer-left-right-spacing .left-footer {
  flex-flow: wrap;
  justify-content: center;
  gap: 14px;
  display: flex;
}

.footer .white-section-footer .footer-left-right-spacing .left-footer .social-media-icons {
  flex-direction: row;
  justify-content: center;
  gap: 14px;
  display: flex;
}

.footer .white-section-footer .footer-left-right-spacing .mid-footer {
  flex-flow: wrap;
  justify-content: center;
  gap: 14px;
  display: flex;
}

.footer .white-section-footer .footer-left-right-spacing .mid-footer .footer-links {
  color: #39393a;
  flex-direction: row;
  justify-content: center;
  gap: 14px;
  font-size: 15px;
  font-weight: 550;
  display: flex;
}

.footer .white-section-footer .footer-left-right-spacing .mid-footer .footer-links:hover {
  color: #39393a;
  background-color: #0000;
  text-decoration: none;
}

.footer .white-section-footer .footer-left-right-spacing .right-footer {
  flex-flow: wrap;
  justify-content: center;
  gap: 20px;
  display: flex;
}

.footer .white-section-footer .footer-left-right-spacing .right-footer .eon-copyright {
  color: #8f9192;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 14px;
  font-size: 14px;
  font-weight: 250;
  display: flex;
}

@media screen and (width >= 768px) {
  .footer .white-section-footer .footer-left-right-spacing {
    flex-direction: row;
  }

  .footer .white-section-footer .footer-left-right-spacing .left-footer, .footer .white-section-footer .footer-left-right-spacing .mid-footer, .footer .white-section-footer .footer-left-right-spacing .right-footer {
    width: 100%;
  }
}

@media screen and (width >= 1024px) {
  .footer .white-section-footer .footer-left-right-spacing .left-footer {
    justify-content: flex-start;
    width: 20%;
  }

  .footer .white-section-footer .footer-left-right-spacing .mid-footer {
    width: 55%;
  }

  .footer .white-section-footer .footer-left-right-spacing .right-footer {
    justify-content: flex-end;
    width: 25%;
  }
}

@media screen and (width >= 1440px) {
  .footer {
    max-width: 1440px;
  }
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

.sui-layout {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.sui-layout-header {
  border-bottom: 1px solid #eee;
  padding: 32px 24px;
}

.sui-layout-body {
  background: #fcfcfc;
}

.sui-layout-body:after {
  content: "";
  width: 100%;
  height: 80px;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#fcfcfc", endColorstr= "#ffffff", GradientType= 0);
  background: linear-gradient(#fcfcfc 0%, #fff 100%);
  display: block;
  position: relative;
}

@media (width <= 800px) {
  .sui-layout-body:after {
    display: none;
  }
}

.sui-layout-body__inner {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 24px;
  display: flex;
}

@media (width <= 800px) {
  .sui-layout-body__inner {
    padding: 0 15px;
    display: block;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sui-layout-sidebar {
  width: 24%;
  padding: 32px 32px 0 0;
}

@media (width <= 975px) {
  .sui-layout-sidebar {
    padding-right: 0;
  }
}

@media (width <= 800px) {
  .sui-layout-sidebar {
    z-index: 99;
    background-color: #fcfcfc;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 15px 30px;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
  }
}

.sui-layout-sidebar--toggled {
  animation: .2s ease-out fadein;
  display: block;
}

@media (width <= 800px) {
  .sui-layout-sidebar-toggle {
    color: #3a56e4;
    background-color: #fff;
    border: 1px solid #3a56e4;
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    display: block;
  }

  .sui-layout-sidebar .sui-layout-sidebar-toggle {
    margin-bottom: 20px;
  }
}

.sui-layout-main {
  width: 76%;
  padding: 32px 0 32px 32px;
}

@media (width <= 800px) {
  .sui-layout-main {
    width: 100%;
    padding-left: 0;
  }
}

.sui-layout-main-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.sui-layout-main-header__inner {
  color: #4a4b4b;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 12px;
  display: flex;
}

.sui-layout-main-footer {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.sui-search-error {
  color: red;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 180px);
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.sui-search-error.no-error {
  color: #333;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 180px);
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.sui-facet {
  border: 0;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

.sui-facet + .sui-facet, .sui-sorting + .sui-facet {
  margin-top: 32px;
}

.sui-facet__title {
  text-transform: uppercase;
  color: #8b9bad;
  letter-spacing: 1px;
  padding: 0;
  font-size: 12px;
}

.sui-facet__list {
  margin: 8px 0;
  padding: 0;
  font-size: 13px;
  line-height: 1.5;
}

.sui-facet__count {
  color: #888;
  margin-left: 20px;
  padding-top: 2px;
  font-size: .85em;
  display: inline-block;
}

.sui-multi-checkbox-facet {
  color: #4f4f4f;
  margin: 8px 0;
  font-size: 13px;
}

.sui-multi-checkbox-facet__option-label {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sui-multi-checkbox-facet__option-input-wrapper {
  flex: 1;
}

.sui-multi-checkbox-facet__checkbox {
  cursor: pointer;
  margin-right: 8px;
}

.sui-multi-checkbox-facet__option-count {
  color: #888;
  margin-left: 24px;
  font-size: .85em;
}

.sui-facet-view-more {
  cursor: pointer;
  color: #3a56e4;
  font-family: inherit;
  font-size: 13px;
  line-height: inherit;
  text-align: left;
  border: unset;
  padding: unset;
  background: unset;
  display: block;
}

.sui-facet-view-more:hover, .sui-facet-view-more:focus {
  background-color: #f8f8f8;
  outline: 4px solid #f8f8f8;
}

.sui-facet-search {
  margin: 6px 0 0;
}

.sui-facet-search__text-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px;
  font-family: inherit;
}

.sui-facet-search__text-input:focus {
  border: 1px solid #3a56e4;
}

.sui-boolean-facet {
  color: #4f4f4f;
  margin: 8px 0;
  font-size: 13px;
}

.sui-boolean-facet__option-label {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sui-boolean-facet__option-input-wrapper {
  flex: 1;
}

.sui-boolean-facet__checkbox {
  cursor: pointer;
  margin-right: 8px;
}

.sui-boolean-facet__option-count {
  color: #888;
  margin-left: 24px;
  font-size: .85em;
}

.sui-single-option-facet {
  margin: 8px 0;
  padding: 0;
  font-size: 13px;
  list-style: none;
}

.sui-single-option-facet__item {
  justify-content: space-between;
  display: flex;
}

.sui-single-option-facet__link {
  color: #4f4f4f;
  padding: 0;
  font-weight: bold;
  text-decoration: none;
  list-style: none;
  position: relative;
}

.sui-single-option-facet__link:after {
  content: "";
  opacity: 0;
  pointer-events: none;
  background: #258bf814;
  width: calc(100% + 10px);
  height: calc(100% + 2px);
  position: absolute;
  top: -1px;
  left: -5px;
}

.sui-single-option-facet__link:focus {
  color: #3a56e4;
  outline: none;
  font-weight: bold;
}

.sui-single-option-facet__link:hover {
  color: #3a56e4;
  font-weight: bold;
}

.sui-single-option-facet__link:hover:after {
  opacity: 1;
}

.sui-single-option-facet__selected {
  font-weight: 900;
  list-style: none;
}

.sui-single-option-facet__selected a {
  padding: 0 2px;
  font-weight: 100;
}

.sui-single-option-facet__remove {
  color: #666;
  margin-left: 10px;
}

.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.rc-pagination ul, .rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-pagination:after {
  clear: both;
  visibility: hidden;
  content: " ";
  height: 0;
  display: block;
  overflow: hidden;
}

.rc-pagination-total-text {
  vertical-align: middle;
  height: 28px;
  margin-right: 8px;
  line-height: 26px;
  display: inline-block;
}

.rc-pagination-item {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  min-width: 28px;
  height: 28px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 26px;
  list-style: none;
  display: inline-block;
}

.rc-pagination-item a {
  color: #000000d9;
  padding: 0 6px;
  transition: none;
  display: block;
}

.rc-pagination-item a:hover {
  text-decoration: none;
}

.rc-pagination-item:focus, .rc-pagination-item:hover {
  border-color: #1890ff;
  transition: all .3s;
}

.rc-pagination-item:focus a, .rc-pagination-item:hover a {
  color: #1890ff;
}

.rc-pagination-item-active {
  background: #fff;
  border-color: #1890ff;
  font-weight: 500;
}

.rc-pagination-item-active a {
  color: #1890ff;
}

.rc-pagination-item-active:focus, .rc-pagination-item-active:hover {
  border-color: #40a9ff;
}

.rc-pagination-item-active:focus a, .rc-pagination-item-active:hover a {
  color: #40a9ff;
}

.rc-pagination-jump-prev, .rc-pagination-jump-next {
  outline: 0;
}

.rc-pagination-jump-prev button, .rc-pagination-jump-next button {
  cursor: pointer;
  color: #666;
  background: none;
  border: none;
}

.rc-pagination-jump-prev button:after, .rc-pagination-jump-next button:after {
  content: "•••";
  display: block;
}

.rc-pagination-prev, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-prev, .rc-pagination-next, .rc-pagination-jump-prev, .rc-pagination-jump-next {
  color: #000000d9;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
  min-width: 28px;
  height: 28px;
  font-family: Arial;
  line-height: 28px;
  list-style: none;
  transition: all .3s;
  display: inline-block;
}

.rc-pagination-prev, .rc-pagination-next {
  outline: 0;
}

.rc-pagination-prev button, .rc-pagination-next button {
  color: #000000d9;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.rc-pagination-prev:hover button, .rc-pagination-next:hover button {
  border-color: #40a9ff;
}

.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link {
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all .3s;
  display: block;
}

.rc-pagination-prev:focus .rc-pagination-item-link, .rc-pagination-next:focus .rc-pagination-item-link, .rc-pagination-prev:hover .rc-pagination-item-link, .rc-pagination-next:hover .rc-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}

.rc-pagination-prev button:after {
  content: "‹";
  display: block;
}

.rc-pagination-next button:after {
  content: "›";
  display: block;
}

.rc-pagination-disabled, .rc-pagination-disabled:hover, .rc-pagination-disabled:focus {
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item-link, .rc-pagination-disabled:hover .rc-pagination-item-link, .rc-pagination-disabled:focus .rc-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  border-color: #d9d9d9;
}

.rc-pagination-slash {
  margin: 0 10px 0 5px;
}

.rc-pagination-options {
  vertical-align: middle;
  margin-left: 16px;
  display: inline-block;
}

@media (-ms-high-contrast: none) {
  .rc-pagination-options {
    vertical-align: top;
  }

  .rc-pagination-options ::-ms-backdrop {
    vertical-align: top;
  }
}

.rc-pagination-options-size-changer.rc-select {
  width: auto;
  margin-right: 8px;
  display: inline-block;
}

.rc-pagination-options-quick-jumper {
  vertical-align: top;
  height: 28px;
  line-height: 28px;
  display: inline-block;
}

.rc-pagination-options-quick-jumper input {
  width: 50px;
  margin: 0 8px;
}

.rc-pagination-simple .rc-pagination-prev, .rc-pagination-simple .rc-pagination-next {
  vertical-align: top;
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  background-color: #0000;
  border: 0;
  height: 24px;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link:after, .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link:after {
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  height: 24px;
  margin-right: 8px;
  display: inline-block;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  transition: border-color .3s;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.rc-pagination.rc-pagination-disabled {
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item {
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item a {
  color: #00000040;
  cursor: not-allowed;
  background: none;
  border: none;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
  background: #dbdbdb;
  border-color: #0000;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
  color: #fff;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
  color: #00000040;
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
  opacity: 0;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
  opacity: 1;
}

@media only screen and (width <= 992px) {
  .rc-pagination-item-after-jump-prev, .rc-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (width <= 576px) {
  .rc-pagination-options {
    display: none;
  }
}

.sui-paging {
  font-size: 12px;
}

.sui-paging .rc-pagination-prev, .sui-paging .rc-pagination-next, .sui-paging .rc-pagination-item {
  background: none;
  border: none;
}

.sui-paging .rc-pagination-prev .rc-pagination-item-link, .sui-paging .rc-pagination-next .rc-pagination-item-link, .sui-paging .rc-pagination-item .rc-pagination-item-link {
  font-size: 18px;
}

.sui-paging .rc-pagination-prev > a, .sui-paging .rc-pagination-prev > button, .sui-paging .rc-pagination-next > a, .sui-paging .rc-pagination-next > button, .sui-paging .rc-pagination-item > a, .sui-paging .rc-pagination-item > button {
  color: #4f4f4f;
  background: none;
  border: none;
}

.sui-paging .rc-pagination-prev > a:hover, .sui-paging .rc-pagination-prev > button:hover, .sui-paging .rc-pagination-next > a:hover, .sui-paging .rc-pagination-next > button:hover, .sui-paging .rc-pagination-item > a:hover, .sui-paging .rc-pagination-item > button:hover {
  color: #4f4f4f;
  background: #f8f8f8;
}

.sui-paging .rc-pagination-disabled .rc-pagination-item-link {
  color: #ccc;
  opacity: .8;
}

.sui-paging .rc-pagination-disabled .rc-pagination-item-link:hover {
  color: #ccc;
}

.sui-paging .rc-pagination-item a {
  color: #3a56e4;
  text-decoration: none;
}

.sui-paging .rc-pagination-item-active a {
  color: #4f4f4f;
  font-weight: 700;
}

.sui-paging .rc-pagination-item-active:hover {
  cursor: not-allowed;
  background: none;
}

.sui-paging .rc-pagination-item-active:hover a {
  color: #4f4f4f;
  cursor: not-allowed;
}

.sui-paging-info {
  color: #4a4b4b;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.sui-result {
  overflow-wrap: break-word;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 24px 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  list-style: none;
  display: block;
  overflow: hidden;
  box-shadow: 0 0 1px #0000001a;
}

.sui-result + .sui-result {
  margin-top: 32px;
}

.sui-result em {
  color: #3a56e4;
  font-weight: 700;
  font-style: inherit;
  position: relative;
}

.sui-result em:after {
  content: "";
  pointer-events: none;
  background: #258bf814;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  position: absolute;
  top: -3px;
  left: -3px;
}

.sui-result__header {
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.sui-result__title {
  color: #333;
  font-size: 1.8em;
  font-weight: 400;
  text-decoration: none;
}

.sui-result__title-link {
  color: #3a56e4;
  text-decoration: none;
}

.sui-result__key {
  color: #777;
  flex: 0 50%;
  font-family: monospace;
  font-size: 14px;
  font-weight: 400;
}

.sui-result__key:before {
  content: "\"";
}

.sui-result__key:after {
  content: "\": ";
}

.sui-result__value {
  font-size: 14px;
  font-weight: 400;
}

.sui-result__version {
  vertical-align: bottom;
  font-size: 12px;
  display: inline;
}

.sui-result__license {
  color: #999;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 4px 4px 3px;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
}

.sui-result__body {
  margin-top: 0;
  line-height: 1.5;
  display: flex;
}

.sui-result__body p {
  margin: 0;
}

.sui-result__image {
  flex-basis: 220px;
  padding-top: 12px;
  padding-left: 24px;
}

.sui-result__image img {
  max-width: 100%;
  height: auto;
  display: block;
}

.sui-result__details {
  flex: 1;
  margin: 0;
  padding: 12px 24px;
  list-style: none;
}

.sui-results-container {
  padding: 0;
  list-style: none;
}

.sui-results-per-page {
  color: #4a4b4b;
  align-items: center;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.sui-results-per-page__label {
  margin-right: 8px;
}

.sui-results-per-page .sui-select__control {
  align-items: center;
}

.sui-results-per-page .sui-select__control input {
  position: absolute;
}

.sui-search-box {
  justify-content: center;
  align-items: stretch;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
}

.sui-search-box__submit {
  text-shadow: 0 1px 2px #0000000d;
  color: #fff;
  cursor: pointer;
  background: #2f7cf4 linear-gradient(#2da0fa, #3158ee);
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 10px;
  padding: 16px;
  font-family: inherit;
  font-size: 14px;
  box-shadow: inset 0 0 0 1px #0000000d, 0 1px #3b454f0d;
}

.sui-search-box__submit:hover {
  background: #3d84f7 linear-gradient(#3cabff, #4063f0);
  box-shadow: inset 0 0 0 1px #0000004d, 0 2px 4px #3b454f4d;
}

.live-filtering .sui-search-box__submit {
  display: none;
}

.sui-search-box__wrapper {
  border-radius: 3px;
  outline: none;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.sui-search-box__text-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  width: 100%;
  padding: 16px;
  font-family: inherit;
  font-size: 14px;
  position: relative;
}

.sui-search-box__text-input:focus {
  border: 1px solid #3a56e4;
  box-shadow: 0 2px 4px #3b454f4d;
}

.autocomplete .sui-search-box__text-input {
  box-shadow: 0 2px 4px #3b454f4d;
}

.sui-search-box__autocomplete-container {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-direction: column;
  margin: 0;
  padding: 24px 0 12px;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px #3b454f4d;
}

.autocomplete .sui-search-box__autocomplete-container {
  z-index: 1;
  display: flex;
}

.sui-search-box__autocomplete-container ul {
  background: none;
  border-radius: 3px;
  margin: 0;
  padding: 0 0 24px;
  list-style: none;
}

.sui-search-box__autocomplete-container ul:last-child {
  padding: 0;
}

.sui-search-box__autocomplete-container li {
  color: #555;
  cursor: default;
  border-radius: 4px;
  margin: 0 12px;
  padding: 4px 12px;
  font-size: .9em;
}

.sui-search-box__autocomplete-container li em {
  color: #3a56e4;
  background: #edf0fd;
  font-style: normal;
}

.sui-search-box__autocomplete-container li:hover {
  color: #fff;
  background: #3a56e4;
}

.sui-search-box__autocomplete-container li:hover em {
  color: #fff;
  background: none;
}

.sui-search-box__autocomplete-container li[aria-selected="true"] {
  color: #fff;
  background: #3a56e4;
}

.sui-search-box__autocomplete-container li[aria-selected="true"] em {
  color: #fff;
  background: none;
}

.sui-search-box__section-title {
  color: #888;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0 0 4px 24px;
  font-size: .7em;
  font-weight: normal;
}

.sui-sorting {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.sui-sorting__label {
  color: #8b9bad;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
}

.sui-select {
  width: 100%;
  margin-top: 8px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
}

.sui-select--inline {
  margin-top: 0;
}

.sui-select--is-disabled {
  opacity: .5;
}

.sui-select__control {
  background-color: #f8f8f8;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.sui-select__control--is-focused {
  border: 1px solid #3a56e4;
}

.sui-select__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

.sui-select__value-container--has-value {
  color: #333;
  font-weight: 700;
}

.sui-select__placeholder {
  white-space: nowrap;
  color: #333;
  position: static;
  transform: none;
}

.sui-select__dropdown-indicator {
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.sui-select__option-count {
  color: #888;
  font-size: .8em;
}

.sui-select__option-label {
  color: #4f4f4f;
}

.sui-select__option {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  font-weight: 400;
  display: flex;
}

.sui-select__option--is-selected {
  color: #333;
  background: #fff;
  font-weight: 700;
}

.sui-select__option--is-selected .sui-search-select__option-label {
  color: #333;
  position: relative;
}

.sui-select__option:hover {
  background: #f8f8f8;
}

.search-custom-filter-wrapper {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.search-custom-filter-wrapper .unified-box-heading {
  background: #fff;
  border-bottom: 1px solid #cccbca;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  margin: 0 12px;
  padding: 12px 0;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  top: 0;
}

.search-custom-filter-wrapper .unified-box-content {
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  display: flex;
}

.search-custom-filter-wrapper .search-input-wrapper {
  margin-bottom: 24px;
}

.search-custom-filter-wrapper .search-input-wrapper .input-box-wrapper {
  box-sizing: border-box;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") 10px 10px no-repeat;
  border: 1px solid #555;
  border-radius: 18px;
  width: 100%;
  padding: 9px 4px 9px 40px;
  font-size: 16px;
  display: block;
}

.search-custom-filter-wrapper .filter-conatiner {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.search-custom-filter-wrapper .filter-conatiner .filter-tabs {
  background-color: #fff;
  flex-flow: row;
  flex: none;
  place-content: stretch flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.search-custom-filter-wrapper .filter-conatiner .filter-tabs .tablinks {
  color: #0b0b0b;
}

.search-custom-filter-wrapper .filter-conatiner .filter-tabs .spacer {
  flex-flow: row;
  flex: 1 0 auto;
  place-content: stretch flex-start;
  align-items: stretch;
  gap: 0;
  display: flex;
}

.search-custom-filter-wrapper .filter-conatiner .filter-tabs .tablinks {
  color: #343a40;
  float: left;
  cursor: pointer;
  background-color: #f9fafb;
  border: none;
  outline: none;
  min-width: 30px;
  padding: 8px;
  transition: padding .3s linear;
}

.search-custom-filter-wrapper .filter-conatiner .filter-tabs .tablinks:hover {
  color: #0b0b0b;
  background-color: #f9f6f4;
}

.search-custom-filter-wrapper .filter-conatiner .filter-tabs .tablinks.active {
  color: #f9fafb;
  background-color: var(--eon-red500);
  border-radius: 8px;
  font-weight: 700;
}

.search-custom-filter-wrapper .filter-conatiner .filter-tabs-right {
  flex-flow: column;
  flex: none;
  place-content: stretch flex-start;
  align-items: stretch;
  gap: 0;
  display: flex;
}

.search-custom-filter-wrapper .filter-conatiner .filter-bottom {
  background: #e9e9e9;
  border: none;
  height: 4px;
  margin-block-start: 0;
}

.search-custom-filter-wrapper .custom-result-container {
  cursor: auto;
  z-index: 1;
  color: #262626;
  background: #fff;
  border: none;
  border-radius: 2px 2px 5px 5px;
  flex-flow: column;
  flex: 80%;
  place-content: stretch flex-start;
  align-items: stretch;
  gap: 12px;
  min-width: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.search-custom-filter-wrapper .custom-result-container .no-more-data {
  text-align: center;
  padding: 4px;
}

.search-custom-filter-wrapper .search-input {
  flex-flow: row;
  flex: none;
  place-content: stretch flex-start;
  align-items: stretch;
  gap: 2px;
  display: flex;
}

@media screen and (width >= 1024px) {
  .search-custom-filter-wrapper {
    gap: 24px;
  }

  .search-custom-filter-wrapper .filter-conatiner .filter-tabs .tablinks {
    padding: 8px 16px;
    font-size: 16px;
  }

  .search-custom-filter-wrapper .filter-conatiner .filter-tabs .tablinks.active {
    font-size: 16px;
    font-weight: 500;
  }

  .search-custom-filter-wrapper .custom-result--reverse {
    flex-flow: row-reverse;
    flex: none;
    place-content: stretch flex-start;
    align-items: stretch;
    gap: 48px;
    display: flex;
  }

  .search-custom-filter-wrapper .custom-result-container .no-data {
    font-size: 24px;
  }
}

.no-data {
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 4px;
  font-size: 16px;
  display: flex;
}

.loader-wrapper {
  text-align: center;
  z-index: 1000;
  background: #04040447;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-wrapper .loader-overlay {
  margin: 0 auto;
}

.table-font {
  font-size: 18px;
}

.table-font-header {
  font-size: 18px;
  font-weight: 700;
}

.action-cell {
  gap: 12px;
  display: flex;
}

.redirect-icon, .action-icon {
  color: var(--primary-color);
}

.child-container .loader-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.child-container .child-no-data-message {
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.child-container .child-ticket-add {
  margin-top: 50px;
}

.ai-response {
  margin: 0;
}

.citation-container {
  flex: 1;
}

.citation-container .citation-item {
  margin: 10px;
}

.iframe-with-links {
  display: flex;
}

.pdf-preview {
  flex: 2;
  width: 100%;
  min-width: 400px;
  min-height: 600px;
}

@media screen and (width <= 920px) {
  .pdf-preview {
    display: none;
  }
}

.results-summary {
  justify-content: space-between;
  display: flex;
}

.bot-reply-container .title {
  align-items: center;
  display: flex;
}

.report-content {
  gap: 12px;
  display: flex;
}

@media (width <= 768px) {
  .report-content {
    flex-direction: column;
  }
}

.device-info {
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  flex-direction: column;
  gap: 12px;
  min-width: 250px;
  padding: 12px;
  display: flex;
}

.device-info .device-label {
  justify-content: space-between;
  display: flex;
}

.device-info .device-name, .device-info .uptime {
  flex-direction: column;
  display: flex;
}

.device-info .device-name .restart-days, .device-info .uptime .restart-days {
  gap: 5px;
  display: flex;
}

.device-info .device-name > div, .device-info .uptime > div {
  font-weight: bold;
}

.device-info .device-name .virtual-tag, .device-info .uptime .virtual-tag {
  font-size: 12px;
}

.device-info .device-name .recommendation, .device-info .uptime .recommendation {
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  display: flex;
}

.energy-info {
  flex-direction: column;
  gap: 12px;
  min-width: 235px;
  display: flex;
}

.energy-info .energy-consumed, .energy-info .energy-emitted {
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  flex-direction: column;
  gap: 12px;
  padding: 10px;
  display: flex;
}

.energy-info .energy-consumed .device-label, .energy-info .energy-emitted .device-label {
  justify-content: space-between;
  display: flex;
}

.energy-info .energy-consumed .value, .energy-info .energy-emitted .value {
  font-weight: bold;
}

.energy-info .energy-consumed .unit, .energy-info .energy-emitted .unit {
  font-size: 12px;
}

.ai-assist-content .feedback {
  margin-left: 44px;
}

.feedback {
  padding: 0 8px;
  font-size: small;
}

.feedback .record-feedback {
  align-items: center;
  display: flex;
}

.feedback .title {
  color: var(--eon-grey800);
}

.feedback .thumb {
  border: unset;
  vertical-align: middle;
  background: none;
}

.feedback .thumb--selected svg path {
  fill: var(--eon-red500);
}

.feedback .like-dislike {
  gap: 8px;
  display: flex;
}

.feedback .feedback-form .label-section {
  padding: 8px 0;
}

.feedback .feedback-form .feedback-tag {
  text-wrap: nowrap;
  color: #333;
  border-radius: 4px;
  flex-wrap: wrap;
  gap: 12px;
  padding: 4px 8px 12px 0;
  font-size: 14px;
  display: inline-block;
}

.feedback .feedback-form .text-area-wrapper {
  padding: 4px 0;
}

.feedback .feedback-form .text-area-wrapper .text-area {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 16px;
}

.feedback .feedback-response {
  gap: 10px;
  display: flex;
}

.feedback .feedback-response .feedback-message-wrapper {
  margin: 4px 0;
}

.feedback .feedback-response .feedback-message-wrapper .feedback-message {
  margin: 0;
}

.feedback .issue-resolver-wrapper {
  margin: 24px auto;
}

.feedback .issue-resolver-wrapper .issue-resolver-content {
  justify-content: center;
  gap: 8px;
  margin: 12px auto;
  display: flex;
}

.feedback .issue-resolver-wrapper .issue-resolver-content .differentiator {
  padding-top: 5px;
}

@media screen and (width >= 1024px) {
  .feedback .issue-resolver-wrapper .issue-resolver-content {
    gap: 24px;
  }
}

.live-agent-chat-container {
  background: #fff;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
  padding: 8px;
  display: flex;
}

.live-agent-chat-container .heading {
  background: #f1f2f6;
  border-radius: 8px;
  gap: 12px;
  padding: 8px;
  display: flex;
}

.live-agent-chat-container .agent-waiting-msg {
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.live-agent-chat-container .agent-waiting-msg .bot-text {
  font-size: 12px !important;
}

.live-agent-chat-container .messages {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.live-agent-chat-container .messages .agent-message {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.live-agent-chat-container .messages .user-message {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.live-agent-chat-container .switch-to-assistance-btn {
  text-align: center;
}

.live-agent-chat-container .agent-icon {
  color: var(--primary-color);
}

.live-agent-chat-container .user-reply-container {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.live-agent-chat-container .user-reply-container .user-heading {
  margin-top: 6px;
  font-weight: normal !important;
}

.live-agent-chat-container .user-header {
  background: #f1f2f6;
  border-radius: 8px;
  flex-direction: row;
  gap: 12px;
  padding: 8px;
  display: flex;
}

.live-agent-chat-container .agent-header {
  background: #f6e5dc;
  border-radius: 8px;
  flex-direction: row;
  gap: 12px;
  padding: 8px;
  display: flex;
}

.live-agent-chat-container .waiting-header {
  border-radius: 8px;
  flex-direction: row;
  gap: 12px;
  padding: 8px;
  display: flex;
}

.live-agent-chat-container .chat-input-box {
  gap: 10px;
  display: flex;
}

.live-agent-chat-container .chat-input-box .form {
  width: 100%;
}

.live-agent-chat-container .message-time {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 3rem;
  font-size: 10px;
  display: flex;
}

.live-agent-chat-container .loader {
  letter-spacing: 1px;
}

.ai-assist-quick-action {
  background-color: #fff;
  flex: 1;
  min-width: max-content;
  position: relative;
}

.ai-assist-quick-action .heading {
  color: #262626;
  border: 1px solid #6b7280;
  border-radius: 6px;
  justify-content: space-between;
  gap: 8px;
  padding: 5px 12px;
  font-size: 16px;
  display: flex;
}

.ai-assist-quick-action .heading .icon {
  display: none;
}

.ai-assist-quick-action .heading .arrow-icon {
  display: block;
}

.ai-assist-quick-action .quick-links-wrapper {
  background: #fff;
  flex-direction: column;
  gap: 12px;
  width: 260px;
  display: none;
  position: absolute;
  right: 0;
}

.ai-assist-quick-action .quick-links-wrapper .item {
  padding: 12px;
}

@media screen and (width >= 1024px) {
  .ai-assist-quick-action {
    width: 300px;
    padding: 8px;
  }

  .ai-assist-quick-action .heading {
    border: none;
    border-bottom: 1px solid #6b7280;
    border-radius: 0;
    justify-content: flex-start;
    padding: 12px;
    font-size: 20px;
  }

  .ai-assist-quick-action .heading .icon {
    display: block;
  }

  .ai-assist-quick-action .heading .arrow-icon {
    display: none;
  }

  .ai-assist-quick-action .quick-links-wrapper {
    position: inherit;
    width: fit-content;
    display: flex;
  }
}

.ai-assist-assistance-task {
  background-color: #fff;
  flex: 1;
  position: relative;
}

.ai-assist-assistance-task .heading {
  color: #262626;
  border: 1px solid #6b7280;
  border-radius: 6px;
  justify-content: space-between;
  gap: 8px;
  padding: 5px 12px;
  font-size: 16px;
  display: none;
}

.ai-assist-assistance-task .heading .icon {
  display: none;
}

.ai-assist-assistance-task .heading .arrow-icon {
  display: block;
}

.ai-assist-assistance-task .assistance-task-wrapper {
  background: #fff;
  flex-direction: column;
  gap: 12px;
  width: 260px;
  display: none;
  position: absolute;
  right: 0;
}

.ai-assist-assistance-task .assistance-task-wrapper .item {
  padding: 12px;
}

.ai-assist-assistance-task .assistance-task-item {
  align-items: center;
  gap: 10px;
  padding: 10px;
  display: flex;
}

@media screen and (width >= 1024px) {
  .ai-assist-assistance-task {
    width: 300px;
    padding: 8px;
  }

  .ai-assist-assistance-task .heading {
    border: none;
    border-bottom: 1px solid #6b7280;
    border-radius: 0;
    justify-content: flex-start;
    padding: 12px;
    font-size: 20px;
    display: flex;
  }

  .ai-assist-assistance-task .heading .icon {
    display: block;
  }

  .ai-assist-assistance-task .heading .arrow-icon {
    display: none;
  }

  .ai-assist-assistance-task .assistance-task-wrapper {
    position: inherit;
    flex-direction: column;
    width: fit-content;
    display: flex;
  }
}

.search-list-component {
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 2px #cccbca;
}

.search-list-component.input--focused {
  box-shadow: inset 0 0 0 2px #1ea2b1;
}

.search-list-component.list--exist {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.search-list-component.reverse-list--exist {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.search-list-component input {
  box-sizing: border-box;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") 97% no-repeat;
  border: 0;
  outline: none;
  width: 100%;
  height: 48px;
  padding: 12px 48px 12px 12px;
  font-size: 16px;
  display: block;
}

.search-list-component .loader {
  position: absolute;
  top: 4px;
  right: 4px;
}

.search-list-component .search-icon-container {
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 4px;
  right: 4px;
}

.search-list-component .result_ul {
  z-index: 1000;
  background-color: #fff;
  border: 2px solid #1ea2b1;
  width: 100%;
  margin: 0;
  padding: 2px 0;
  list-style: none;
  position: absolute;
  overflow: hidden;
}

.search-list-component .result_ul--limited-height {
  max-height: 200px;
  overflow-y: auto;
}

.search-list-component .result_ul--normal {
  border-top: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.search-list-component .result_ul--reverse {
  border-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-direction: column-reverse;
  display: flex;
}

.search-list-component .result_ul .result-list {
  align-items: center;
  font-size: 18px;
  display: flex;
  position: relative;
}

.search-list-component .result_ul .result-list a {
  text-align: left;
  flex: 1;
}

.search-list-component .result_ul .result-list .icon-align-right {
  background-color: #f9f6f4;
  border: 0;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 2px;
  display: flex;
  position: relative;
  right: 0;
  overflow: hidden;
}

.search-list-component .result_ul .result-list .icon-align-right:hover {
  background-color: #f7e8da;
}

.search-list-component .result_ul .result-list .result-redirection-wrapper {
  color: var(--eon-neutral800);
  text-decoration: none;
}

.search-list-component .result_ul .result-list:hover {
  background: #f9f6f4;
  text-decoration: underline;
}

.search-list-component .result_ul .app-section {
  background-color: #f9f6f4;
}

.search-list-component .result_ul .app-section .app-slider {
  width: calc(100% - 24px);
  padding-bottom: 4px;
  display: -webkit-inline-box;
  overflow: auto hidden;
}

.search-list-component .result_ul .app-section .app-slider::-webkit-scrollbar {
  height: 8px;
}

.search-list-component .result_ul .app-section .app-slider::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.search-list-component .result_ul .app-section .app-slider::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.search-list-component .result_ul .app-section .app-slider::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.search-list-component .result_ul .result-search-subheader {
  margin: 4px 12px;
  display: flex;
}

.search-list-component .result_ul .app-slider {
  margin: 0 12px;
}

.search-list-component .result_ul .result-list {
  background-color: #fff;
  border-radius: 8px;
  margin-right: 12px;
  padding-right: 4px;
}

.search-list-component .result_ul .result-list:first-child {
  border-top: 0;
}

.ai-assist-wrapper {
  background-color: #fafafa;
  flex-direction: column;
  gap: 24px;
  min-height: calc(100vh - 385px);
  margin-top: 16px;
  padding: 16px;
  display: flex;
}

.ai-assist-wrapper .ai-assit-container {
  flex: 3;
}

.ai-assist-wrapper .ai-assist-welcome {
  text-align: center;
}

.ai-assist-wrapper .ai-assist-welcome .start-message {
  color: #404040;
  font-size: 32px;
  font-weight: 500;
}

.ai-assist-wrapper .user-header, .ai-assist-wrapper .bot-header {
  background: #f1f2f6;
  border-radius: 8px;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
  padding: 12px;
  display: flex;
}

.ai-assist-wrapper .user-header .ai-action-btn-wrapper, .ai-assist-wrapper .bot-header .ai-action-btn-wrapper {
  gap: 12px;
  display: flex;
}

.ai-assist-wrapper .user-header .user-reply-container, .ai-assist-wrapper .user-header .bot-reply-container, .ai-assist-wrapper .bot-header .user-reply-container, .ai-assist-wrapper .bot-header .bot-reply-container {
  flex-direction: column;
  gap: 12px;
  width: 90%;
  display: flex;
}

.ai-assist-wrapper .user-header .user-reply-container .user-heading, .ai-assist-wrapper .user-header .user-reply-container .bot-heading, .ai-assist-wrapper .user-header .bot-reply-container .user-heading, .ai-assist-wrapper .user-header .bot-reply-container .bot-heading, .ai-assist-wrapper .bot-header .user-reply-container .user-heading, .ai-assist-wrapper .bot-header .user-reply-container .bot-heading, .ai-assist-wrapper .bot-header .bot-reply-container .user-heading, .ai-assist-wrapper .bot-header .bot-reply-container .bot-heading {
  margin: 6px 0;
  font-weight: bold;
}

.ai-assist-wrapper .user-header .user-reply-container .bot-heading, .ai-assist-wrapper .user-header .bot-reply-container .bot-heading, .ai-assist-wrapper .bot-header .user-reply-container .bot-heading, .ai-assist-wrapper .bot-header .bot-reply-container .bot-heading {
  color: var(--heading-color);
}

.ai-assist-wrapper .user-header .user-reply-container .ticket-container, .ai-assist-wrapper .user-header .bot-reply-container .ticket-container, .ai-assist-wrapper .bot-header .user-reply-container .ticket-container, .ai-assist-wrapper .bot-header .bot-reply-container .ticket-container {
  flex-direction: column;
  display: flex;
}

.ai-assist-wrapper .user-header .user-reply-container .ticket-container .ticket-description, .ai-assist-wrapper .user-header .bot-reply-container .ticket-container .ticket-description, .ai-assist-wrapper .bot-header .user-reply-container .ticket-container .ticket-description, .ai-assist-wrapper .bot-header .bot-reply-container .ticket-container .ticket-description {
  background: #f8f5f2;
  padding: 12px;
}

.ai-assist-wrapper .user-header .user-reply-container .ticket-container .message-content, .ai-assist-wrapper .user-header .bot-reply-container .ticket-container .message-content, .ai-assist-wrapper .bot-header .user-reply-container .ticket-container .message-content, .ai-assist-wrapper .bot-header .bot-reply-container .ticket-container .message-content {
  padding: 12px;
}

.ai-assist-wrapper .user-header .user-reply-container .ticket-container .ticket-success, .ai-assist-wrapper .user-header .user-reply-container .ticket-container .failure-msg, .ai-assist-wrapper .user-header .bot-reply-container .ticket-container .ticket-success, .ai-assist-wrapper .user-header .bot-reply-container .ticket-container .failure-msg, .ai-assist-wrapper .bot-header .user-reply-container .ticket-container .ticket-success, .ai-assist-wrapper .bot-header .user-reply-container .ticket-container .failure-msg, .ai-assist-wrapper .bot-header .bot-reply-container .ticket-container .ticket-success, .ai-assist-wrapper .bot-header .bot-reply-container .ticket-container .failure-msg {
  gap: 8px;
  padding: 0 8px;
  display: flex;
}

.ai-assist-wrapper .user-header .user-reply-container .ticket-container .last-message, .ai-assist-wrapper .user-header .bot-reply-container .ticket-container .last-message, .ai-assist-wrapper .bot-header .user-reply-container .ticket-container .last-message, .ai-assist-wrapper .bot-header .bot-reply-container .ticket-container .last-message {
  margin: 8px;
}

.ai-assist-wrapper .user-header .user-reply-container .bot-content, .ai-assist-wrapper .user-header .bot-reply-container .bot-content, .ai-assist-wrapper .bot-header .user-reply-container .bot-content, .ai-assist-wrapper .bot-header .bot-reply-container .bot-content {
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
  display: flex;
}

.ai-assist-wrapper .user-header .user-reply-container .bot-content .heading, .ai-assist-wrapper .user-header .bot-reply-container .bot-content .heading, .ai-assist-wrapper .bot-header .user-reply-container .bot-content .heading, .ai-assist-wrapper .bot-header .bot-reply-container .bot-content .heading {
  font-size: 16px;
  font-weight: 700;
}

.ai-assist-wrapper .user-header .user-reply-container .bot-content .list, .ai-assist-wrapper .user-header .bot-reply-container .bot-content .list, .ai-assist-wrapper .bot-header .user-reply-container .bot-content .list, .ai-assist-wrapper .bot-header .bot-reply-container .bot-content .list {
  gap: 12px;
  max-width: max-content;
  display: flex;
}

.ai-assist-wrapper .user-header .user-reply-container .bot-content .list .list-item, .ai-assist-wrapper .user-header .bot-reply-container .bot-content .list .list-item, .ai-assist-wrapper .bot-header .user-reply-container .bot-content .list .list-item, .ai-assist-wrapper .bot-header .bot-reply-container .bot-content .list .list-item {
  color: #1f2937;
  padding: 8px;
  font-size: 12px;
}

.ai-assist-wrapper .user-header .user-reply-container .non-it-warning, .ai-assist-wrapper .user-header .bot-reply-container .non-it-warning, .ai-assist-wrapper .bot-header .user-reply-container .non-it-warning, .ai-assist-wrapper .bot-header .bot-reply-container .non-it-warning {
  color: #262626;
}

.ai-assist-wrapper .user-header .user-reply-container .non-it-warning .question, .ai-assist-wrapper .user-header .bot-reply-container .non-it-warning .question, .ai-assist-wrapper .bot-header .user-reply-container .non-it-warning .question, .ai-assist-wrapper .bot-header .bot-reply-container .non-it-warning .question {
  font-weight: 700;
}

.ai-assist-wrapper .user-header .user-reply-container .non-it-warning .recommendation, .ai-assist-wrapper .user-header .bot-reply-container .non-it-warning .recommendation, .ai-assist-wrapper .bot-header .user-reply-container .non-it-warning .recommendation, .ai-assist-wrapper .bot-header .bot-reply-container .non-it-warning .recommendation {
  color: #5c5c5c;
  font-size: 12px;
  font-weight: 700;
}

.ai-assist-wrapper .user-header .user-reply-container .non-it-warning .recommendation-text, .ai-assist-wrapper .user-header .bot-reply-container .non-it-warning .recommendation-text, .ai-assist-wrapper .bot-header .user-reply-container .non-it-warning .recommendation-text, .ai-assist-wrapper .bot-header .bot-reply-container .non-it-warning .recommendation-text {
  color: #5c5c5c;
  font-size: 12px;
}

.ai-assist-wrapper .user-header {
  align-items: center;
}

.ai-assist-wrapper .user-header .bot-text {
  font-size: 18px;
}

.ai-assist-wrapper .bot-header {
  background: #fff;
  font-size: 16px;
}

.ai-assist-wrapper .bot-text {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 16px;
}

.ai-assist-wrapper .bot-text a {
  color: #1ea2b1;
  text-decoration: none;
}

.ai-assist-wrapper .bot-text b {
  color: #262626;
  font-family: HvDTrial Brix Sans, sans-serif;
  font-size: xx-small;
  font-weight: normal;
}

.ai-assist-wrapper .ai-assist-right-panel-desktop {
  display: none;
}

@media screen and (width >= 1024px) {
  .ai-assist-wrapper {
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;
    padding: 24px;
    position: relative;
  }

  .ai-assist-wrapper .ai-assit-container {
    flex: none;
    width: calc(100% - 348px);
  }

  .ai-assist-wrapper .ai-assist-right-panel-desktop {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-height: fit-content;
    display: flex;
    position: sticky;
    top: 114px;
  }

  .ai-assist-wrapper .user-header .user-reply-container .bot-content, .ai-assist-wrapper .user-header .bot-reply-container .bot-content, .ai-assist-wrapper .bot-header .user-reply-container .bot-content, .ai-assist-wrapper .bot-header .bot-reply-container .bot-content {
    flex-direction: row;
  }
}

.ai-assist-right-panel-mobile {
  background: #f9f6f4;
  gap: 12px;
  padding: 12px 16px;
  display: flex;
}

@media screen and (width >= 1024px) {
  .ai-assist-right-panel-mobile {
    display: none;
  }
}

.button-toggle-desktop {
  background: #fff;
  border-radius: 8px;
  width: fit-content;
  padding: 4px;
  display: none;
}

.button-toggle-desktop .opt-button {
  color: #404040;
  cursor: pointer;
  background: none;
  border: 2px solid #0000;
  border-radius: 8px;
  min-width: 30px;
  padding: 4px 8px;
}

.button-toggle-desktop .opt-button--active {
  color: #262626;
  background: #f0ebe5;
  box-shadow: 0 0 2px #00000080;
}

@media screen and (width >= 1024px) {
  .button-toggle-desktop {
    display: block;
  }
}

.button-toggle-mobile {
  width: 50%;
  display: block;
}

@media screen and (width >= 1024px) {
  .button-toggle-mobile {
    display: none;
  }
}

.ai-assist-footer {
  background: linear-gradient(#fff6, #fff);
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  padding: 8px 0;
  display: flex;
  position: sticky;
  bottom: 0;
}

.ai-assist-footer .user-heading {
  align-items: center;
  gap: 12px;
  font-weight: bold;
  display: flex;
}

.ai-assist-footer .input-wrapper {
  background-color: #fff;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
}

.ai-assist-footer .search-list-component {
  background-color: #fff;
}

@media screen and (width >= 1024px) {
  .ai-assist-footer {
    width: calc(75% - 8px);
    margin: 0;
  }
}

.disclaimer-text {
  color: #5c5c5c;
  text-align: center;
}

@media screen and (width >= 1024px) {
  .disclaimer-text {
    width: calc(75% - 8px);
    margin: 0;
  }
}

.related-apps-container {
  color: #262626;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.related-apps-container .related-apps-ul {
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  margin: 0;
  overflow: auto hidden;
}

.related-apps-container .related-apps-ul .related-apps-li {
  background: #fff;
  border-bottom: .5px solid #e7e8ec;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: -webkit-fill-available;
  padding: 8px 12px;
  list-style: none;
  display: flex;
}

.related-apps-container .related-apps-ul .related-apps-li:hover {
  background-color: #f8f5f2;
}

.related-apps-container .related-apps-ul .related-apps-li .related-apps-link {
  width: max-content;
  text-decoration: none;
}

.related-apps-container .related-apps-ul .related-apps-li .related-apps-link:hover {
  text-decoration: underline #000;
}

.related-apps-container .related-apps-ul .related-apps-li .related-apps-link .related-apps-label {
  color: #262626;
  flex: 1;
  margin: 0;
  font-size: 16px;
  list-style: none;
}

.related-apps-container .related-apps-ul .related-apps-li .add-icon {
  background-color: #f9f6f4;
  border: 0;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 2px;
  display: flex;
  position: relative;
  right: 0;
  overflow: hidden;
}

.related-apps-container .related-apps-ul .related-apps-li .add-icon:hover {
  background-color: #f7e8da;
}

.related-apps-container .app-item {
  border-bottom: 1px solid #e8e8e8;
  align-items: center;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.related-apps-container .app-item .app-item-name {
  margin-right: 10px;
}

.related-apps-container .view-more-apps {
  display: none;
}

@media screen and (width >= 1024px) {
  .related-apps-container {
    width: 260px;
    height: auto;
  }

  .related-apps-container .related-apps-ul {
    flex-direction: column;
    display: flex;
  }

  .related-apps-container .related-apps-ul .related-apps-li .related-apps-link {
    width: auto;
  }

  .related-apps-container .view-more-apps {
    justify-content: flex-end;
    display: flex;
  }

  .related-apps-container .view-more-apps .view-more-icon {
    margin-left: 5px;
  }
}

.advance-unified-assist {
  padding: 8px 8px 0;
}

.advance-unified-assist--flex {
  flex-flow: column;
  flex: none;
  place-content: stretch flex-start;
  align-items: stretch;
  gap: 0;
  display: flex;
}

.advance-unified-assist--flex-rev {
  flex-flow: column-reverse;
  flex: none;
  place-content: stretch flex-start;
  align-items: stretch;
  gap: 8px;
  display: flex;
}

.advance-unified-assist .advance-unified-ai {
  width: 100%;
}

.advance-unified-assist .ai-logo {
  align-items: center;
  gap: 12px;
  display: flex;
}

.advance-unified-assist .role-list {
  height: 50px;
  margin-bottom: 24px;
}

.advance-unified-assist .role-list .unified-bot-text-format {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 16px;
}

.advance-unified-assist .role-list .unified-bot-text-format a {
  color: #1ea2b1;
  text-decoration: none;
}

.advance-unified-assist .role-list .unified-bot-text-format b {
  color: #262626;
}

.advance-unified-assist .show-more-button {
  z-index: 1;
  background: linear-gradient(#fff6, #fff);
  justify-content: center;
  margin-bottom: -12px;
  padding: 10px;
  display: flex;
}

.advance-unified-assist .chatbot-response {
  cursor: auto;
  z-index: 1;
  color: #262626;
  border: none;
  border-radius: 2px 2px 5px 5px;
  flex-flow: column;
  flex: 80%;
  place-content: stretch space-between;
  align-items: stretch;
  gap: 0;
  min-width: 200px;
  padding: 8px;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.advance-unified-assist .chatbot-response .unified-input-wrapper {
  border: 1px solid gray;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 8px;
  display: flex;
}

.advance-unified-assist .no-response-loader {
  flex-direction: column;
  flex-basis: 80%;
  margin-top: 8px;
  display: flex;
}

@media screen and (width >= 1024px) {
  .advance-unified-assist {
    flex-direction: row;
    padding: 12px 12px 0;
    -webkit-mask-image: none;
    mask-image: none;
  }

  .advance-unified-assist--flex {
    flex-flow: row;
    flex: none;
    place-content: stretch flex-start;
    align-items: stretch;
    gap: 12px;
    display: flex;
  }

  .advance-unified-assist .role-list {
    height: 80px;
  }
}

.search-item-row {
  border-bottom: 1px solid var(--eon-grey300);
  word-break: break-word;
  padding: 12px;
  font-size: 14px;
}

.search-item-row:hover {
  background: var(--bg-hover-color);
}

.search-item-row .row-1 {
  margin-bottom: 4px;
  font-size: 16px;
  display: flex;
}

.search-item-row .row-1 .search-item-logo {
  object-fit: cover;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.search-item-row .row-1.links {
  flex-direction: column;
  margin-inline: 8px;
  display: flex;
}

.search-item-row .row-2 {
  color: var(--eon-grey700);
  align-items: center;
  gap: 24px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.search-item-row .row-2 span .value {
  color: var(--eon-grey900);
  font-size: 14px;
}

.search-item-row .row-3 {
  color: var(--eon-grey800);
  padding-block: 4px;
  font-size: 16px;
  font-weight: 400;
}

.search-item-row .row-3 p {
  margin: 4px 0;
}

.search-item-row .row-3 td, .search-item-row .row-3 th {
  border: 1px solid #9d9d9d;
  min-width: 100px;
}

.search-item-row .row-3 h1, .search-item-row .row-3 h2, .search-item-row .row-3 h3, .search-item-row .row-3 h4, .search-item-row .row-3 h5, .search-item-row .row-3 h6 {
  color: var(--eon-grey800);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.12px;
}

.search-item-row .row-3 .image-content {
  width: 96px;
  height: auto;
}

.search-item-row .row-3 .image-content img {
  object-fit: contain;
  height: 96px;
}

.search-item-row .data-type {
  padding-top: 4px;
  font-size: 14px;
}

.search-item-row .search-item-link, .search-item-row .search-item-link-full {
  color: var(--eon-grey900);
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
}

.search-item-row .search-item-link:hover, .search-item-row .search-item-link-full:hover {
  color: var(--eon-red500);
}

.search-item-row .search-item-link-full {
  width: 100%;
  color: var(--eon-grey500);
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 400;
  display: block;
  overflow: hidden;
}

.search-item-row .meta-data-list {
  gap: 24px;
  display: flex;
}

.search-item-row .meta-data-list .key-value {
  flex-direction: column;
  display: flex;
}

.search-item-row .meta-data-list .key-value .key {
  color: var(--eon-grey700);
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
}

.search-item-row .meta-data-list .key-value .value {
  color: var(--eon-grey900);
  font-size: 14px;
  font-weight: 400;
}

@media screen and (width >= 1024px) {
  .search-item-row .row-1 .search-item-logo {
    height: auto;
  }
}

.nav-bar-dropdown .nav-bar-li button {
  width: 100%;
  border-bottom: 0 !important;
}

.nav-bar-dropdown .nav-bar-li:last-child button {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.advance-page-result-root .all_data_view_row_2 {
  padding: 0 8px 8px;
}

.advance-page-result-root .all_data_view_row_2 .sharepoint-wrapper {
  height: 100%;
}

.advance-page-result-root .all_data_view_row_2 .sharepoint-wrapper .unified-box-content {
  max-height: 200px;
  overflow-y: auto;
}

@media screen and (width >= 768px) {
  .advance-page-result-root .all_data_view_row_2 {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 8px 8px;
    display: flex;
  }

  .advance-page-result-root .all_data_view_row_2 .sharepoint-wrapper .unified-box-content {
    max-height: 1000px;
  }

  .advance-page-result-root .all-list-wrapper, .advance-page-result-root .sharepoint-wrapper {
    width: calc(50% - 6px);
  }
}

@media screen and (width >= 1024px) {
  .advance-page-result-root .all_data_view_row_2 {
    padding: 0 12px 12px;
  }

  .advance-page-result-root .all-list-wrapper {
    width: calc(100% - 412px);
  }

  .advance-page-result-root .sharepoint-wrapper {
    width: 400px;
  }
}

.simple_data_rows {
  flex-direction: column;
}

.simple_data_rows code {
  white-space: break-spaces;
}

@media screen and (width >= 540px) {
  .simple_data_rows {
    flex-direction: row;
  }
}

.refine-filters {
  flex-direction: column;
  gap: 20px;
  min-width: 280px;
  display: flex;
}

@media screen and (width >= 1024px) {
  .widget-reduced-width {
    width: calc(100% - 292px);
  }
}

.search-filter-header {
  border-bottom: 1px solid #b3aca9;
  justify-content: space-between;
  padding: 12px 0;
  display: flex;
}

.search-filter-header .filter-header-left {
  cursor: pointer;
  align-items: center;
  gap: 12px;
  width: max-content;
  font-size: 18px;
  font-weight: 900;
  display: flex;
}

.search-filter-header .arrow-container {
  gap: 10px;
  display: flex;
}

.search-filter-header .arrow-container .filter-count {
  color: var(--primary-color);
  align-content: center;
  font-size: 18px;
  font-weight: 700;
}

.search-filter-header .filter-heading {
  font-size: 18px;
  font-weight: 700;
}

.search-filter-wrapper {
  color: #262626;
}

.search-filter-wrapper .filter-section-heading {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 700;
}

.search-filter-wrapper .filter-search-section {
  display: none;
}

.search-filter-wrapper .reload-icon {
  cursor: pointer;
}

@media screen and (width >= 1024px) {
  .search-filter-wrapper {
    width: 286px;
  }

  .search-filter-wrapper .search-filter-header .filter-header-left {
    cursor: default;
  }

  .search-filter-wrapper .search-filter-header .arrow-container {
    display: none;
  }

  .search-filter-wrapper .filter-search-section {
    display: block;
  }
}

.search-filters-modal-container .btn {
  gap: 20px;
  margin-top: 20px;
  display: flex;
}

.search-filters-modal-container .search-filter-wrapper .search-filter-header .arrow-container {
  display: none;
}

.search-filters-modal-container .filter-search-section {
  display: block;
}

@media screen and (width >= 320px) {
  .search-filters-modal-container {
    width: 200px;
  }
}

@media screen and (width >= 480px) {
  .search-filters-modal-container {
    width: 260px;
  }
}

.filter-list-wrapper .filter-opt {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  min-height: 48px;
  padding: 8px;
  font-size: 16px;
  display: flex;
}

.filter-list-wrapper .filter-opt .current-opt-left {
  align-items: center;
  gap: 12px;
  display: flex;
}

.filter-list-wrapper .filter-opt--selected, .filter-list-wrapper .filter-opt:hover, .filter-list-wrapper .filter-opt:focus {
  color: var(--primary-color);
  cursor: pointer;
  background-color: #f8f5f2;
  text-decoration: underline;
}

.filter-list-wrapper .filter-opt--selected, .filter-list-wrapper .filter-opt--selected:hover {
  background-color: #f8f5f2;
}

.filter-list-wrapper .filter-opt input[type="radio"]:checked {
  accent-color: var(--primary-color);
}

.filter-list-wrapper .filter-opt input[type="radio"]:checked + label {
  color: var(--primary-color);
}

.room-booking-wrapper {
  min-height: 400px;
}

.room-booking-wrapper .page-title {
  color: var(--primary-color);
  justify-content: space-between;
  margin: 8px 0 24px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
}

.room-booking-wrapper .page-title .link-button {
  justify-content: flex-end;
}

.room-booking-wrapper .search-form-row {
  flex-flow: column wrap;
  gap: 18px;
  display: flex;
}

.room-booking-wrapper .search-form-row .search-form-col {
  width: 100%;
}

.room-booking-wrapper .api-message {
  text-align: center;
  border-radius: 8px;
  min-height: 100px;
  margin-top: 48px;
  padding: 38px;
  font-size: 18px;
  font-weight: bold;
}

.room-booking-wrapper .api-message.api--error {
  color: #b00402;
  border: 1px solid #b00402;
}

.room-booking-wrapper .api-message.api--success {
  color: #1ea2b1;
  border: 1px solid #1ea2b1;
}

.room-booking-wrapper .room-not-available {
  color: #b00402;
  font-size: 15px;
  font-weight: bold;
  display: flex;
}

.room-booking-wrapper .room-not-available .error-icon {
  color: #b00402;
}

.room-booking-wrapper .room-not-available .error-text {
  margin-left: 8px;
  line-height: 32px;
}

@media (width >= 768px) {
  .room-booking-wrapper .search-form-row {
    flex-direction: row;
  }

  .room-booking-wrapper .tab--fix-size {
    min-width: 100px;
    width: auto !important;
  }
}

@media (width >= 1024px) {
  .room-booking-wrapper .search-form-row {
    flex-wrap: nowrap;
  }

  .room-booking-wrapper .page-title {
    font-size: 24px;
  }

  .room-booking-wrapper .col--auto-size {
    width: auto !important;
  }
}

@media (width >= 1200px) {
  .room-booking-wrapper .tab--fix-size {
    width: 100% !important;
  }
}

.under-dev-wrapper {
  color: #ea1c0a;
  gap: 18px;
  margin: 24px 40px;
  padding: 32px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
}

.selected-location-error {
  color: #b00402;
  font-size: 14px;
  position: absolute;
}

.pre-loader-wrapper {
  text-align: center;
  margin-top: 36px;
}

.location-and-legends {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: .5em;
  display: flex;
}

.location-and-legends .availability-pointers__label {
  gap: 24px;
  display: flex;
}

.location-and-legends .availability-pointers__label .indicator {
  align-items: baseline;
  gap: 4px;
  display: flex;
}

.location-and-legends .icon-theme {
  border-radius: 9px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-top: 4px;
}

.location-and-legends .icon-theme--free {
  background-color: #00b876 !important;
}

.location-and-legends .icon-theme--partial {
  background-color: #f07c00 !important;
}

.mt-auto {
  margin-top: auto;
}

.col-min-width {
  min-width: 240px;
}

.cl-mw-150 {
  min-width: 150px !important;
}

.room-card-wrapper {
  margin-top: 36px;
}

.room-card-wrapper .card-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.room-card-wrapper .card-layout {
  background-color: #f6f6f6;
  border-radius: 25px;
  width: 200px;
  min-width: 200px;
  height: 130px;
  margin: 8px 8px auto;
  padding: 10px;
  font-size: 14px;
  transition: transform .2s;
  position: relative;
}

.room-card-wrapper .card-layout .card-row {
  display: flex;
}

.room-card-wrapper .card-layout:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.room-card-wrapper .card-layout .type-icon-wrapper {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.room-card-wrapper .card-layout .type-icon-wrapper svg {
  width: 30px;
  height: 30px;
}

.room-card-wrapper .card-heading {
  padding-left: 12px;
}

.room-card-wrapper .display-flex {
  gap: 20px;
  display: flex;
}

.room-card-wrapper .card-text__bold {
  font-weight: bold;
}

.room-card-wrapper .availability-pointers__label {
  gap: 24px;
  margin-top: 24px;
  display: flex;
}

.room-card-wrapper .availability-pointers__label .indicator {
  align-items: baseline;
  gap: 4px;
  display: flex;
}

.room-card-wrapper .icon-theme {
  border-radius: 9px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-top: 4px;
}

.room-card-wrapper .icon-theme--free {
  background-color: #00b876 !important;
}

.room-card-wrapper .icon-theme--partial {
  background-color: #f07c00 !important;
}

.room-card-wrapper .icon-theme--busy {
  background-color: #e63121 !important;
}

.feh-modal {
  z-index: 10005;
  background-color: #0006;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.feh-modal .feh-modal-container {
  background-color: #fff;
  border: #ecebeb;
  border-radius: 8px;
  width: fit-content;
  max-height: fit-content;
  margin: 0 auto;
  padding: 8px 24px 12px;
  overflow: auto;
}

.feh-modal .feh-modal-container::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #0000004d;
}

.feh-modal .feh-modal-container::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 6px;
}

.feh-modal .feh-modal-container::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #0000004d;
}

.feh-modal .close {
  color: #39393a;
  justify-content: flex-end;
  margin-bottom: 12px;
  font-size: 25px;
  font-weight: bold;
  display: flex;
  opacity: 1 !important;
}

.feh-modal .close .close-icon-button {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
}

.feh-modal .close:hover, .feh-modal .close:focus {
  opacity: 1;
  text-decoration: none;
}

@media only screen and (width >= 768px) {
  .feh-modal .close {
    margin-top: -17px;
    margin-right: -9px;
  }
}

@media only screen and (width >= 1024px) {
  .feh-modal .feh-modal-container {
    padding: 40px;
  }
}

.feh-modal.b2c-cancel-assessment .feh-modal-container .close {
  color: #000;
}

@media screen and (width >= 768px) {
  .feh-modal.b2c-cancel-assessment .cancel-assessment-custom-model {
    padding: 55px 32px 0 64px;
  }
}

.feh-modal.your-contact-details-popup .feh-modal-container {
  padding: 0;
}

.feh-modal.your-contact-details-popup .close {
  padding: 24px;
}

.meeting-popup {
  width: 220px;
}

.meeting-popup .form-wrapper {
  flex-direction: column;
  gap: 18px;
  display: flex;
}

.meeting-popup .form-wrapper .eon-form-label {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 18px;
  display: block;
}

.meeting-popup .form-wrapper .field-value {
  font-size: 12px;
}

.meeting-popup .room-not-available {
  color: #b00402;
  font-size: 15px;
  font-weight: bold;
  display: flex;
}

.meeting-popup .room-not-available .error-icon {
  color: #b00402;
}

.meeting-popup .room-not-available .error-text {
  margin-left: 8px;
  line-height: 32px;
}

.meeting-popup .room-partially-available {
  color: #ffa947;
  font-size: 15px;
  font-weight: bold;
  display: flex;
}

.meeting-popup .room-partially-available .partial-error-icon {
  color: #ffa947;
}

.meeting-popup .room-partially-available .error-text {
  margin-left: 8px;
  line-height: 32px;
}

.meeting-popup .capacity_wrapper {
  margin-bottom: 0;
  line-height: 24px;
  display: flex;
}

.meeting-popup .capacity_wrapper .capacity_icon {
  margin-right: 8px;
}

@media screen and (width >= 540px) {
  .meeting-popup {
    width: 400px;
  }
}

@media screen and (width >= 768px) {
  .meeting-popup {
    width: 540px;
  }
}

.timeline-wrapper {
  width: 100%;
  margin-bottom: 18px;
  position: relative;
}

.timeline-wrapper .timeline-container {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  height: 32px;
  position: relative;
}

.timeline-wrapper .timeline-container .booking-slot {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
  background-color: #ea1c0a;
  height: 100%;
  line-height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.timeline-wrapper .timeline-container .available-slot {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.timeline-wrapper .time-scale {
  height: 15px;
  margin-top: 5px;
  position: relative;
}

.timeline-wrapper .time-scale .time-label {
  white-space: nowrap;
  font-size: 8px;
  position: absolute;
  transform: translateX(-50%);
}

.location-dropdown {
  width: 100%;
}

.location-dropdown .form-label {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 18px;
  display: block;
}

.location-dropdown .selected-location {
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 2px solid #cccbca;
  border-radius: 6px;
  height: 32px;
  padding-left: 8px;
  font-size: 16px;
  line-height: 30px;
  overflow: hidden;
}

.location-dropdown .selected-location:hover {
  border: 2px solid #1ea2b1;
}

.location-dropdown-error {
  border: 2px solid #b00402 !important;
}

.location-dropdown-placeholder {
  color: #cccbca !important;
}

.location-dropdown-show {
  position: absolute;
  z-index: 98 !important;
}

.location-dropdown-hide {
  display: none;
}

.room-locations {
  width: 276px;
  margin-top: 8px;
  position: absolute;
}

.room-locations .location-list-wrapper {
  background: #fff;
  border: 2px solid #1ea2b1;
  border-radius: 6px;
  max-height: 250px;
  padding-top: 4px;
  overflow-y: scroll;
}

.room-locations button {
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  width: -webkit-fill-available;
  line-height: 36px;
  display: flex;
}

.room-locations button.collapsible {
  border-radius: 40px;
}

.room-locations button.collapsible .location-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.room-locations button.collapsible .home-icon {
  margin-left: auto;
}

.room-locations button.collapsible .home-icon svg, .room-locations button.collapsible .home-icon svg path {
  fill: #fff;
}

.room-locations button.collapsible--isHome {
  color: #fff;
  background: #ea1c0a80;
  font-weight: bold;
}

.room-locations button.collapsible--selected, .room-locations button.collapsible:hover, .room-locations button.collapsible:focus {
  border: 2px solid #ea1c0a80;
  outline: 0;
}

.room-locations svg {
  width: 24px;
  height: 24px;
  padding-top: 4px;
  padding-right: 8px;
}

.time-picker {
  width: 100%;
  position: relative;
}

.time-picker .timepicker--error {
  border: 1px solid red;
}

.time-picker .dropdown-menu {
  z-index: 1;
  background: #fff;
  border: 2px solid #1ea2b1;
  border-radius: 6px;
  width: 276px;
  max-height: 250px;
  padding-top: 4px;
  position: absolute;
  top: 65px;
  overflow-y: scroll;
}

.time-picker .dropdown-menu .dropdown-item {
  cursor: pointer;
  padding: 8px;
}

.time-picker .dropdown-menu .dropdown-item:hover {
  background-color: #f1f1f1;
}

.child-container {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.child-container .page-sub-title {
  border-bottom: 2px solid var(--primary-color);
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.child-container .search-container {
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 16px;
  display: flex;
  position: relative;
}

.child-container .search-container .local-filters, .child-container .search-container .date-filters {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.child-container .manage-bookings-container .table-font-header, .child-container .manage-bookings-container .table-font {
  font-size: 16px;
}

@media screen and (width >= 768px) {
  .child-container .search-container {
    flex-direction: column;
  }

  .child-container .search-container .local-filters {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .child-container .search-container .local-filters .free-text {
    width: 100%;
  }

  .child-container .search-container .local-filters .booking-type {
    width: 40%;
  }

  .child-container .search-container .date-filters {
    flex-direction: row;
  }
}

@media screen and (width >= 1024px) {
  .child-container .search-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .child-container .search-container .date-filters {
    justify-content: space-between;
  }
}

.edit-booking-container {
  color: #262626;
  flex-direction: column;
  gap: 25px;
  height: 45rem;
  display: flex;
}

.edit-booking-container .heading {
  color: var(--heading-color);
  font-size: 30px;
  font-weight: 500;
}

.edit-booking-container .title {
  font-size: 24px;
  font-weight: 400;
}

.edit-booking-container .availability-info {
  flex-direction: column;
  display: flex;
}

.edit-booking-container .availability-info .heading {
  align-items: center;
  gap: 10px;
  font-size: 36px;
  display: flex;
}

.edit-booking-container .availability-info .error {
  color: #ef7115;
}

.edit-booking-container .info {
  color: #00b876;
}

.edit-booking-container .location {
  font-weight: 500;
}

.edit-booking-container .location .label {
  font-size: 16px;
}

.edit-booking-container .location .loc {
  font-size: 22px;
}

.edit-booking-container .other-details {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.edit-booking-container .other-details .timing {
  font-weight: 500;
}

.edit-booking-container .other-details .timing .date {
  font-size: 18px;
}

.edit-booking-container .other-details .timing .time {
  font-size: 22px;
}

.edit-booking-container .other-details .timing .duration {
  color: #404040;
  font-size: 14px;
}

.edit-booking-container .reschedule-to {
  font-size: 18px;
  font-weight: 500;
}

.edit-booking-container .form, .edit-booking-container .form .row {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.edit-booking-container .btn {
  gap: 25px;
  display: flex;
}

.edit-booking-container .btn .confirm, .edit-booking-container .btn .cancel {
  font: 16px;
  font-weight: 400;
}

@media screen and (width >= 768px) {
  .edit-booking-container .other-details {
    flex-direction: row;
    gap: 125px;
  }

  .edit-booking-container .form {
    flex-direction: row;
  }
}

@media screen and (width >= 1024px) {
  .edit-booking-container .other-details {
    flex-direction: row;
    gap: 250px;
  }

  .edit-booking-container .form {
    flex-direction: row;
    justify-content: flex-start;
    gap: 25px;
  }

  .edit-booking-container .form .row-1, .edit-booking-container .form .row-2 {
    flex-direction: row;
  }

  .edit-booking-container .form .dropdown {
    width: 10rem;
  }
}

@media screen and (width >= 1440px) {
  .edit-booking-container {
    max-width: 1440px;
  }
}

.cancel-booking-container {
  color: #262626;
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.cancel-booking-container .heading {
  color: var(--heading-color);
  font-size: 30px;
  font-weight: 500;
}

.cancel-booking-container .title {
  font-size: 24px;
  font-weight: 400;
}

.cancel-booking-container .location {
  font-weight: 500;
}

.cancel-booking-container .location .label {
  font-size: 16px;
}

.cancel-booking-container .location .loc {
  font-size: 22px;
}

.cancel-booking-container .other-details {
  flex-direction: column;
  gap: 25px;
  display: flex;
}

.cancel-booking-container .other-details .timing {
  font-weight: 500;
}

.cancel-booking-container .other-details .timing .date {
  font-size: 18px;
}

.cancel-booking-container .other-details .timing .time {
  font-size: 22px;
}

.cancel-booking-container .other-details .timing .duration {
  color: #404040;
  font-size: 14px;
}

.cancel-booking-container .btn {
  gap: 25px;
  display: flex;
}

.cancel-booking-container .btn .confirm, .cancel-booking-container .btn .cancel {
  font: 16px;
  font-weight: 400;
}

@media screen and (width >= 768px) {
  .cancel-booking-container .other-details {
    flex-direction: row;
    gap: 50px;
  }
}

@media screen and (width >= 1024px) {
  .cancel-booking-container .other-details {
    flex-direction: row;
    gap: 100px;
  }
}

@media screen and (width >= 1440px) {
  .cancel-booking-container {
    max-width: 1440px;
  }
}

.child-container {
  margin-bottom: 16px;
}

.child-container .page-sub-title {
  font-size: 18px;
  font-weight: bold;
}

.child-container .book-again-container {
  background-color: #fcfbfa;
  border: 1px solid #0000;
  gap: 15px;
  width: 100%;
  padding: 8px;
  transition: all .3s;
  display: flex;
  overflow-x: auto;
}

.child-container .book-again-container .box {
  cursor: pointer;
  background: #f9f6f4;
  gap: 15px;
  min-width: max-content;
  padding: 16px;
  display: flex;
}

.child-container .book-again-container .box:hover {
  box-shadow: 5px 5px 10px #0000001a;
}

.child-container .book-again-container .box .item {
  flex-direction: column;
  gap: 5px;
  max-width: 15rem;
  display: flex;
}

.child-container .book-again-container .box .item .link {
  color: var(--primary-color);
  text-align: center;
  font-weight: 600;
}

.child-container .book-again-container .box .type-icon-wrapper {
  align-items: center;
  display: flex;
}

.child-container .book-again-container .box .type-icon-wrapper svg {
  width: 30px;
  height: 30px;
}

.banner-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  max-width: 600px;
  margin: 10px auto;
  padding: 24px;
}

.banner-wrapper .banner-heading {
  align-items: center;
  gap: 12px;
  font-size: 15px;
  display: flex;
}

.fixed {
  z-index: 1001;
  text-align: center;
  position: fixed;
  bottom: 200px;
  right: -70px;
  transform: rotate(270deg);
}

.fixed .tour-button {
  border: 1px solid var(--primary-color);
  cursor: pointer;
  background-color: var(--super-light-bg);
  color: #262626;
  border-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 160px;
  height: 24px;
  padding: 4px 14px;
}

.fixed .tour-button:hover {
  background-color: var(--light-bg);
  height: 32px;
  color: var(--primary-color);
  padding: 4px 12px;
}

.fixed:hover {
  right: -68px;
}

@media screen and (width >= 1200px) {
  .fixed {
    bottom: calc(50% - 70px);
  }
}

.TilesContainer-module_container__2hgud {
  width: 100%;
  position: relative;
}

.TilesContainer-module_tile__1R3DP {
  touch-action: none;
  z-index: 0;
  align-items: stretch;
  transition: all .3s ease-in-out;
  display: flex;
  position: absolute;
}

.TilesContainer-module_tile__1R3DP:hover {
  z-index: 1;
}

.TilesContainer-module_tile__1R3DP.TilesContainer-module_dragging__2mUA2 {
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  transition: none;
}

.TilesContainer-module_indicator__t_Y_b {
  z-index: 0;
  align-items: stretch;
  display: flex;
  position: absolute;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(.9);
  }

  99% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.tile-component, .tile-component .drag-wrapper {
  margin: 0 auto;
}

.tile-component .tile-text-wrapper {
  width: 100%;
  text-align: initial;
  margin-left: 12px;
}

.tile-component .drag-container {
  flex-wrap: wrap;
  margin: 8px auto;
  transition: transform .3s;
  display: flex;
  position: relative;
  justify-content: center !important;
  width: 100% !important;
}

.tile-component .drag-container.highlight {
  animation: .4s forwards zoom;
}

.tile-component .drag-container .card {
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  height: 76px;
  transition: transform .2s;
  box-shadow: 0 0 10px #00000040;
}

.tile-component .drag-container .card .tile-body {
  width: 100%;
  height: 100%;
  color: var(--eon-neutral800);
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.tile-component .drag-container .card .tile-body:hover {
  background-color: var(--normal-bg);
}

.tile-component .drag-container .card .tile-body .tile-title {
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0 8px;
  font-size: 16px;
  display: -webkit-box;
  overflow: hidden;
}

.tile-component .drag-container .card .tile-body .tile-subtext {
  margin-top: 12px;
  padding: 0 8px;
  font-size: 12px;
}

.tile-component .drag-container .card .tile-body .tile-numbers {
  color: var(--primary-color);
  margin-top: 12px;
  padding: 0 8px;
  font-size: 12px;
}

.tile-component .drag-container .card .tile-icon {
  margin-left: 18px;
}

.tile-component .drag-container .card .tile-icon, .tile-component .drag-container .card .trash-icon {
  color: var(--primary-color);
}

.tile-component .drag-container .card .close-icon {
  cursor: pointer;
  background-color: #fff;
  border: 0;
  border-radius: 16px;
  outline-color: #0075c2;
  width: 32px;
  height: 32px;
  padding: 4px 4px 4px 9px;
  position: absolute;
  top: 22px;
  right: 10px;
}

.tile-component .drag-container .card .close-icon:hover {
  background-color: #f9f6f4;
}

.tile-component .drag-container .card .close-icon--editmode {
  opacity: .5;
  background-color: #0000 !important;
}

.tile-component .drag-container .card:hover {
  transform: scale(1.03);
}

.tile-component .drag-container .card--movable {
  cursor: move;
  border: 1px dashed;
}

.tile-component .drag-container .card--movable .close-icon, .tile-component .drag-container .card--movable .tile-body {
  cursor: move !important;
}

.tile-component .drag-container .card--moving {
  border-color: #1ea2b1;
}

.tile-component .drag-container .card--moving .tile-body {
  background-color: #fffc !important;
}

.tile-component .drag-container .card--moving .trash-icon, .tile-component .drag-container .card--moving .tile-icon, .tile-component .drag-container .card--moving .tile-body {
  color: #1ea2b1;
}

.tile-component .TilesContainer-module_tile__1R3DP {
  width: 100% !important;
}

@media screen and (width >= 440px) {
  .tile-component .TilesContainer-module_tile__1R3DP {
    width: 160px !important;
  }

  .tile-component .tile-text-wrapper {
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  .tile-component .drag-container .card {
    width: 140px;
    height: 159px;
    margin: 10px;
    position: relative;
  }

  .tile-component .drag-container .card .tile-body {
    flex-direction: column;
    padding: 0;
  }

  .tile-component .drag-container .card .tile-body .tile-icon {
    margin: 24px 0 8px;
  }

  .tile-component .drag-container .card .tile-body .tile-title {
    line-clamp: 3;
    -webkit-line-clamp: 3;
    max-height: 58px;
  }

  .tile-component .drag-container .card .close-icon {
    top: 2px;
    right: 2px;
  }

  .tile-component .common-button-wrapper {
    flex-direction: row;
  }
}

.tab-wrapper {
  justify-content: space-between;
  margin-top: 24px;
  display: flex;
  box-shadow: inset 0 -5px 1px -3px #cccbca;
}

.tab-wrapper .tab-scroller {
  z-index: 10;
  scrollbar-width: none;
  display: flex;
  position: relative;
  overflow: auto hidden;
}

.tab-wrapper .tab {
  text-overflow: ellipsis;
  color: #555;
  cursor: pointer;
  background: none;
  border: 0;
  border-bottom: 1px solid #cccbca;
  height: 36px;
  max-height: 50px;
  padding: 1px 16px;
  display: block;
}

.tab-wrapper .tab:hover {
  background-color: var(--super-light-bg);
  border-bottom: 2px solid #767676;
}

.tab-wrapper .tab--active {
  background-color: var(--super-light-bg);
  color: #262626;
  border-bottom: 2px solid #262626;
  font-weight: 700;
}

.tab-wrapper .tab--active:hover {
  border-bottom: 2px solid #262626;
}

.tab-wrapper .tab.add-new {
  border-bottom: 2px solid var(--primary-color);
  background-color: #fff;
  justify-content: flex-end;
}

.tab-wrapper .tab.add-new .add-new-desktop {
  margin: auto;
  padding-left: 8px;
  display: none;
}

.tab-wrapper .tab.add-new .icon-wrapper {
  margin: auto;
}

.tab-wrapper .tab.add-new:hover {
  background-color: var(--super-light-bg);
}

@media (width >= 768px) {
  .tab-wrapper .tab {
    height: 48px;
  }

  .tab-wrapper .tab.add-new {
    display: flex;
  }

  .tab-wrapper .tab.add-new .add-new-desktop {
    color: var(--primary-color);
    font-weight: 700;
    display: block;
  }

  .tab-wrapper .button-wrapper {
    flex-direction: row-reverse;
  }
}

.new-tab-wrapper {
  width: 400px;
}

.new-tab-wrapper .common-button-wrapper {
  flex-direction: row;
  justify-content: start;
  margin-bottom: 0;
}

.new-tab-wrapper .heading {
  color: var(--heading-color);
  margin: -1em 0 .5em;
}

@media (width <= 600px) {
  .new-tab-wrapper {
    width: 100%;
  }

  .new-tab-wrapper .common-button-wrapper {
    flex-direction: column;
    justify-content: center;
  }
}

.tilePage .edit-mode--opaque {
  opacity: .5;
  pointer-events: none;
}

.tilePage .tile-width--limited {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.tilePage .tile-width--limited .tile-heading {
  color: var(--heading-color);
}

.tilePage .tile-width--limited .search-wrapper {
  gap: 2px;
  display: flex;
}

.tilePage .tile-background {
  background-color: var(--super-light-bg);
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 12px 16px;
}

.search-wrapper {
  z-index: 97;
  background-color: #fff;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 68px;
}

.search-wrapper .new-ai-chat {
  color: #262626;
  cursor: pointer;
  background-color: #fff;
  border: none;
  justify-content: flex-end;
  display: flex;
}

.search-wrapper .new-ai-chat .add-new-ai-chat {
  margin: auto;
  padding-left: 8px;
  font-weight: bold;
}

.search-wrapper .new-ai-chat .add-new-ai-chat:hover {
  color: var(--heading-color);
  background-color: #f9f6f4;
}

.search-wrapper .new-ai-chat .icon-wrapper {
  margin: auto;
}

.search-wrapper .new-ai-chat:hover {
  color: var(--heading-color);
  border-bottom: 2px solid var(--heading-color-hover);
  background-color: #f9f6f4;
}

.search-wrapper .header-assitance-task {
  display: inline-block;
  position: relative;
}

.search-wrapper .header-assitance-task .task-button {
  background-color: #fff;
  border: none;
}

.search-wrapper .header-assitance-task .assistance-task-wrapper {
  display: flex;
  box-shadow: 0 8px 16px #0003;
}

.search-wrapper .header-assitance-task .dropdown-menu {
  z-index: 1;
  background-color: #fff;
  display: block;
  position: absolute;
  right: 0;
}

@media screen and (width >= 1024px) {
  .search-wrapper .header-assitance-task {
    display: none;
  }
}

.orgnize-wrapper {
  justify-content: space-between;
  display: flex;
}

.personalize-dashboard {
  width: 220px;
}

.personalize-dashboard .heading {
  color: var(--heading-color);
}

.personalize-dashboard .margin-category {
  margin-top: 12px;
}

.personalize-dashboard .tab-data-container {
  gap: 12px;
  margin-top: 12px;
  display: flex;
}

.personalize-dashboard .tab-data-container .disabled-icon {
  color: #faebd7;
}

@media screen and (width >= 540px) {
  .personalize-dashboard {
    width: 400px;
  }
}

@media screen and (width >= 768px) {
  .personalize-dashboard {
    width: 540px;
  }
}

.delete-tab-confirm .delete-tab-message {
  color: var(--primary-color);
  margin-top: 24px;
}

.ai-button-wrapper {
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
}

.ai-button-wrapper-as-button {
  border-radius: 8px;
  padding: 8px;
  box-shadow: inset 0 0 0 2px #cccbca;
}

.ai-button-wrapper-as-button:hover {
  box-shadow: inset 0 0 0 2px var(--heading-color-hover);
}

.ai-button-wrapper-as-button:hover .ai-button-label {
  color: var(--heading-color-hover);
  border-bottom: 2px solid var(--heading-color-hover);
}

.ai-button-wrapper-as-button:hover svg {
  fill: var(--heading-color-hover);
}

.ai-button-wrapper .ai-button-label {
  color: var(--heading-color);
  margin: 4px 0 0 8px;
  font-size: large;
  font-weight: bold;
  transition: color .25s ease-in-out;
  display: none;
}

.ai-button-wrapper svg {
  fill: var(--heading-color);
}

@media screen and (width >= 540px) {
  .ai-button-wrapper .ai-button-label {
    display: inline;
  }
}

.widget-container {
  z-index: 1001;
  text-align: center;
  position: fixed;
  bottom: 350px;
  right: -40px;
  transform: rotate(270deg);
}

.widget-container .widget-button {
  border: 1px solid var(--primary-color);
  cursor: pointer;
  background-color: var(--super-light-bg);
  color: #262626;
  border-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100px;
  height: 24px;
  padding: 4px 14px;
}

.widget-container .widget-button:hover {
  background-color: var(--light-bg);
  height: 32px;
  color: var(--primary-color);
  padding: 4px 12px;
}

.widget-container:hover {
  right: -38px;
}

@media screen and (width >= 1200px) {
  .widget-container {
    bottom: calc(50% + 80px);
  }
}

.about-us-container {
  color: #262626;
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.about-us-container .about-us-header-section {
  background: #f9fafb;
  gap: 48px;
  padding: 12px 16px;
  display: flex;
}

.about-us-container .about-us-header-section .about-us-header-label {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 12px 16px;
  display: flex;
}

.about-us-container .about-us-header-section .about-us-header-label .looking-label {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.about-us-container .about-us-header-section .about-us-header-label .find-out-label {
  color: #5c5c5c;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.about-us-container .about-us-header-section .about-us-image {
  display: none;
}

.about-us-container .about-us-header-section .about-us-image .about-us-img {
  background: #f2f2f2;
  width: 616.88px;
  height: 245px;
}

.about-us-container .about-us-hint-section {
  text-align: left;
  gap: 10px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.about-us-container .release-history .release-date {
  font-size: 16px;
  font-weight: 500;
}

.about-us-container ul {
  line-height: 24px;
  list-style-type: square;
}

@media screen and (width >= 1024px) {
  .about-us-container {
    gap: 24px;
  }

  .about-us-container .about-us-header-section {
    padding: 24px;
  }

  .about-us-container .about-us-header-section .about-us-header-label {
    padding: 12px 24px;
  }

  .about-us-container .about-us-header-section .about-us-header-label .looking-label {
    font-size: 30px;
  }

  .about-us-container .about-us-header-section .about-us-header-label .find-out-label {
    font-size: 24px;
  }

  .about-us-container .about-us-header-section .about-us-image {
    display: inline;
  }

  .about-us-container .about-us-hint-section {
    gap: 48px;
    padding: 0 24px;
    font-size: 20px;
  }

  .about-us-container .release-history .release-date {
    font-size: 20px;
  }

  .about-us-container ul {
    line-height: 30px;
  }
}

.hero-wrapper {
  height: 340px;
  position: relative;
}

.hero-wrapper .bg-img-hero {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 340px;
  position: absolute;
  top: 0;
  background-image: url("home_img.f869bd54.jpg") !important;
}

.hero-wrapper .content-wrapper {
  justify-content: flex-end;
  display: flex;
}

.hero-wrapper .welcome-content {
  text-align: right;
  z-index: 1;
  background-color: #fff;
  max-width: calc(100% - 40px);
  margin-top: 20px;
  padding: 20px;
  position: absolute;
}

.hero-wrapper .welcome-content .headline-font-welcome {
  letter-spacing: .1px;
  text-align: right;
  color: var(--primary-color);
  padding: .1px 20px 0 0;
  font-size: 36px;
  font-weight: 750;
  line-height: 100%;
}

@media (width >= 768px) {
  .hero-wrapper, .hero-wrapper .bg-img-hero {
    height: 380px;
  }

  .hero-wrapper .welcome-content {
    width: 380px;
  }

  .hero-wrapper .welcome-content .headline-font-welcome {
    font-size: 48px;
  }
}

@media (width >= 1024px) {
  .hero-wrapper, .hero-wrapper .bg-img-hero {
    height: 480px;
  }

  .hero-wrapper .welcome-content {
    width: 540px;
    margin-top: 50px;
  }

  .hero-wrapper .welcome-content .headline-font-welcome {
    font-size: 64px;
  }
}
/*# sourceMappingURL=public.d5e63baa.css.map */
