.me-eon-header {
    position: sticky;
    top: 0;
    z-index: 1000;

    @media screen and (max-width: 540px) {
        .eon-logo-wrapper {
            display: none;
        }
    }
}
