.banner-wrapper {
    max-width: 600px;
    margin: 10px auto;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    padding: 24px;
    .banner-heading {
        font-size: 15px;
        gap: 12px;
        align-items: center;
        display: flex;
    }
}
