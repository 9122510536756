/*roombooking.scss*/
.room-booking-wrapper {
    min-height: 400px;
    .page-title {
        font-size: 18px;
        font-weight: bold;
        color: var(--primary-color);
        margin: 8px 0px 24px 0px;
        display: flex;
        justify-content: space-between;
        .link-button {
            justify-content: flex-end;
        }
    }
    .search-form-row {
        display: flex;
        gap: 18px;
        flex-wrap: wrap;
        flex-direction: column;
        .search-form-col {
            width: 100%;
        }
    }
    .api-message {
        min-height: 100px;
        text-align: center;
        margin-top: 48px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 8px;
        padding: 38px;
        &.api--error {
            color: #b00402;
            border: 1px solid #b00402;
        }
        &.api--success {
            color: #1ea2b1;
            border: 1px solid #1ea2b1;
        }
    }
    .room-not-available {
        color: #b00402;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        .error-icon {
            color: #b00402;
        }
        .error-text {
            line-height: 32px;
            margin-left: 8px;
        }
    }
    @media (min-width: 768px) {
        .search-form-row {
            flex-direction: row;
        }
        .tab--fix-size {
            min-width: 100px;
            width: auto !important;
        }
    }
    @media (min-width: 1024px) {
        .search-form-row {
            flex-wrap: nowrap;
        }
        .page-title {
            font-size: 24px;
        }
        .col--auto-size {
            width: auto !important;
        }
    }
    @media (min-width: 1200px) {
        .tab--fix-size {
            width: 100% !important;
        }
    }
}

.under-dev-wrapper {
    margin: 24px 40px;
    font-weight: bold;
    display: flex;
    gap: 18px;
    padding: 32px;
    font-size: 24px;
    color: #ea1c0a;
}

/*Grid layout CSS for meeting room booking*/

.selected-location-error {
    color: #b00402;
    position: absolute;
    font-size: 14px;
}

.pre-loader-wrapper {
    margin-top: 36px;
    text-align: center;
}

.location-and-legends {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5em;
    .availability-pointers__label {
        display: flex;
        gap: 24px;
        .indicator {
            display: flex;
            align-items: baseline;
            gap: 4px;
        }
    }
    .icon-theme {
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: 9px;
        margin-top: 4px;
        &--free {
            background-color: #00b876 !important;
        }
        &--partial {
            background-color: #F07C00 !important;
        }
    }
}

.mt-auto {
    margin-top: auto;
}

.col-min-width {
    min-width: 240px;
}

.cl-mw-150 {
    min-width: 150px !important;
}