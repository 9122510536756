:root {
    --eon-transparent: transparent;
    --eon-bright-font: #262626;
    --eon-dark-font: #ffffff;
    --eon-neutral100-font: #262626;
    --eon-neutral300-font: #262626;
    --eon-neutral800-font: #ffffff;
    --eon-red100-font: #262626;
    --eon-red300-font: #262626;
    --eon-red500-font: #ffffff;
    --eon-red800-font: #ffffff;
    --eon-yellow100-font: #262626;
    --eon-yellow300-font: #262626;
    --eon-yellow500-font: #262626;
    --eon-turquoise100-font: #262626;
    --eon-turquoise300-font: #262626;
    --eon-turquoise500-font: #262626;
    --eon-black-font: #ffffff;
    --eon-violet-dark: #6d199a;
    --eon-red200: #f6c1b0;
    --eon-red200-opacity-25: rgba(246, 193, 176, 0.25);
    --eon-red400: #f05b48;
    --eon-red400-opacity-25: rgba(240, 91, 72, 0.25);
    --eon-red600: #e01000;
    --eon-red700: #c90a01;
    --eon-red700-opacity-20: rgba(201, 10, 1, 0.2);
    --eon-red900: #76020d;
    --eon-yellow200: #f2efa3;
    --eon-yellow400: #e8e536;
    --eon-yellow600: #9eb22d;
    --eon-yellow700: #61822a;
    --eon-yellow800: #3d5327;
    --eon-yellow900: #1c301e;
    --eon-turquoise200: #b0dade;
    --eon-turquoise400: #4fb5c0;
    --eon-turquoise600: #178a9d;
    --eon-turquoise700: #0f738a;
    --eon-turquoise800: #144d62;
    --eon-turquoise900: #182739;
    --eon-neutral200: #fcfbfa;
    --eon-neutral400: #e6e3e1;
    --eon-neutral500: #cccbca;
    --eon-neutral600: #999999;
    --eon-neutral600-opacity-25: rgba(153, 153, 153, 0.25);
    --eon-neutral700: #666666;
    --eon-neutral700-opacity-25: rgba(102, 102, 102, 0.25);
    --eon-neutral900: #000000;
    --eon-system-success-light: #d7e9ca;
    --eon-system-success: #18a087;
    --eon-system-success-dark: #0d6d68;
    --eon-system-warning-light: #f9f2d8;
    --eon-system-warning: #ffc940;
    --eon-system-warning-dark: #ff711a;
    --eon-system-error-light: #f6c1b0;
    --eon-system-error: #f05b48;
    --eon-system-error-dark: #c41708;
    --eon-system-highlight-light: #ccebff;
    --eon-system-highlight: #0075c2;
    --eon-system-highlight-dark: #00568f;
    --eon-white: #ffffff;
    --eon-grey100: #f8f5f2;
    --eon-grey200: #f0ebe5;
    --eon-grey300: #d7d0cc;
    --eon-grey400: #b3aca9;
    --eon-grey500: #958d8b;
    --eon-grey600: #767676;
    --eon-grey700: #5c5c5c;
    --eon-grey800: #404040;
    --eon-grey900: #262626;
    --eon-blue100: #ccebff;
    --eon-blue200: #a3daff;
    --eon-blue300: #7acaff;
    --eon-blue400: #52baff;
    --eon-blue500: #27a9ff;
    --eon-blue600: #0093f5;
    --eon-blue700: #0075c2;
    --eon-blue800: #00568f;
    --eon-blue900: #00375c;
    --eon-purple100: #f0d8eb;
    --eon-purple200: #dba6d1;
    --eon-purple300: #ca6fba;
    --eon-purple400: #b0429d;
    --eon-purple500: #961482;
    --eon-purple600: #821171;
    --eon-purple700: #710f62;
    --eon-purple800: #5f0c52;
    --eon-purple900: #4d0a42;
    --eon-anchor-navigation-background-color: #ffffff;
    --eon-anchor-navigation-background-hover-color: #ffffff;
    --eon-anchor-navigation-icon-color: #262626;
    --eon-anchor-navigation-icon-hover-color: #c90a01;
    --eon-anchor-navigation-icon-highlight-color: #ea1b0a;
    --eon-anchor-navigation-item-background-color: #ffffff;
    --eon-anchor-navigation-item-hover-color: #f9f6f4;
    --eon-anchor-navigation-link-color: #262626;
    --eon-anchor-navigation-link-hover-color: #c90a01;
    --eon-anchor-navigation-link-highlight-color: #ea1b0a;
    --eon-context-menu-border-color: #1ea2b1;
    --eon-context-menu-divider-border-color: #cccbca;
    --eon-context-menu-item-background-hover-color: #f9f6f4;
    --eon-context-menu-item-background-active-color: #e1eded;
    --eon-data-table-head-cell-color: #262626;
    --eon-data-table-head-cell-icon-color: #262626;
    --eon-data-table-head-cell-background: #f9f6f4;
    --eon-data-table-head-cell-background-hover: #e6e3e1;
    --eon-data-table-head-cell-background-act: #e6e3e1;
    --eon-data-table-head-cell-background-act-hover: #cccbca;
    --eon-data-table-head-cell-border: #e6e3e1;
    --eon-data-table-head-cell-border-hover: #cccbca;
    --eon-data-table-head-cell-border-act: #cccbca;
    --eon-data-table-head-cell-border-act-hover: #cccbca;
    --eon-data-table-cell-color: #262626;
    --eon-data-table-cell-icon-color: #262626;
    --eon-data-table-cell-background: #ffffff;
    --eon-data-table-cell-background-hover: #fcfbfa;
    --eon-data-table-cell-background-selected: #e1eded;
    --eon-data-table-cell-background-selected-hover: #b0dade;
    --eon-data-table-cell-background-odd: #ffffff;
    --eon-data-table-cell-background-odd-hover: #f9f6f4;
    --eon-data-table-cell-background-even: #fcfbfa;
    --eon-data-table-cell-background-even-hover: #f9f6f4;
    --eon-data-table-cell-border: #e6e3e1;
    --eon-data-table-cell-border-hover: #e6e3e1;
    --eon-data-table-cell-border-selected: #b0dade;
    --eon-data-table-cell-border-selected-hover: #7fc7cf;
    --eon-data-table-cell-border-odd: #e6e3e1;
    --eon-data-table-cell-border-odd-hover: #e6e3e1;
    --eon-data-table-cell-border-even: #e6e3e1;
    --eon-data-table-cell-border-even-hover: #e6e3e1;
    --eon-data-table-toolbar-background: #ffffff;
    --eon-data-table-toolbar-color: #262626;
    --eon-data-table-toolbar-selected-background: #7fc7cf;
    --eon-data-table-toolbar-selected-color: #262626;
    --eon-footer-bar-copyright-color: #666666;
    --eon-footer-bar-background-color: #ffffff;
    --eon-footer-bar-link-color: #262626;
    --eon-footer-bar-link-hover-color: #262626;
    --eon-navigation-box-shadow: none;
    --eon-navigation-meta-bg-color: #f9f6f4;
    --eon-navigation-meta-border-color: #e6e3e1;
    --eon-navigation-meta-link-font-color: #262626;
    --eon-navigation-meta-link-font-color-hover: #262626;
    --eon-navigation-meta-link-font-color-focus: #262626;
    --eon-navigation-meta-link-font-color-current: #262626;
    --eon-navigation-meta-link-font-color-current-hover: #262626;
    --eon-navigation-meta-link-font-color-current-focus: #262626;
    --eon-navigation-meta-link-bg-color: transparent;
    --eon-navigation-meta-link-bg-color-hover: #e6e3e1;
    --eon-navigation-meta-link-bg-color-focus: transparent;
    --eon-navigation-meta-link-bg-color-current: transparent;
    --eon-navigation-meta-link-bg-color-current-hover: #e6e3e1;
    --eon-navigation-meta-link-bg-color-current-focus: transparent;
    --eon-navigation-meta-link-border-color: transparent;
    --eon-navigation-meta-link-border-color-hover: #262626;
    --eon-navigation-meta-link-border-color-focus: #999999;
    --eon-navigation-meta-link-border-color-current: #262626;
    --eon-navigation-meta-link-border-color-current-hover: #262626;
    --eon-navigation-meta-link-border-color-current-focus: #262626;
    --eon-navigation-toolbar-bg-color: #ffffff;
    --eon-navigation-toolbar-icon-link-font-color: #262626;
    --eon-navigation-toolbar-icon-link-font-color-hover: #262626;
    --eon-navigation-toolbar-icon-link-font-color-focus: #262626;
    --eon-navigation-toolbar-icon-link-font-color-current: #ea1b0a;
    --eon-navigation-toolbar-icon-link-font-color-current-hover: #ea1b0a;
    --eon-navigation-toolbar-icon-link-font-color-current-focus: #76020d;
    --eon-navigation-toolbar-icon-link-font-color-active: #262626;
    --eon-navigation-toolbar-icon-link-font-color-active-hover: #262626;
    --eon-navigation-toolbar-icon-link-font-color-active-focus: #262626;
    --eon-navigation-toolbar-icon-link-bg-color: transparent;
    --eon-navigation-toolbar-icon-link-bg-color-hover: #f9f6f4;
    --eon-navigation-toolbar-icon-link-bg-color-focus: transparent;
    --eon-navigation-toolbar-icon-link-bg-color-current: transparent;
    --eon-navigation-toolbar-icon-link-bg-color-current-hover: #f9f6f4;
    --eon-navigation-toolbar-icon-link-bg-color-current-focus: #f9f6f4;
    --eon-navigation-toolbar-icon-link-bg-color-active: #f9f6f4;
    --eon-navigation-toolbar-icon-link-bg-color-active-hover: #f9f6f4;
    --eon-navigation-toolbar-icon-link-bg-color-active-focus: #f9f6f4;
    --eon-navigation-toolbar-sub-bg-color: #f9f6f4;
    --eon-navigation-main-bg-color: #ffffff;
    --eon-navigation-main-border-color: #e6e3e1;
    --eon-navigation-main-link-font-color: #262626;
    --eon-navigation-main-link-font-color-hover: #262626;
    --eon-navigation-main-link-font-color-focus: #262626;
    --eon-navigation-main-link-font-color-current: #ea1b0a;
    --eon-navigation-main-link-font-color-current-hover: #262626;
    --eon-navigation-main-link-font-color-current-focus: #ea1b0a;
    --eon-navigation-main-link-font-color-active: #262626;
    --eon-navigation-main-link-font-color-active-hover: #262626;
    --eon-navigation-main-link-font-color-active-focus: #262626;
    --eon-navigation-main-link-bg-color: transparent;
    --eon-navigation-main-link-bg-color-hover: #f9f6f4;
    --eon-navigation-main-link-bg-color-focus: transparent;
    --eon-navigation-main-link-bg-color-current: transparent;
    --eon-navigation-main-link-bg-color-current-hover: #f9f6f4;
    --eon-navigation-main-link-bg-color-current-focus: transparent;
    --eon-navigation-main-link-bg-color-active: #f9f6f4;
    --eon-navigation-main-link-bg-color-active-hover: #f9f6f4;
    --eon-navigation-main-link-bg-color-active-focus: #f9f6f4;
    --eon-navigation-main-link-border-color: transparent;
    --eon-navigation-main-link-border-color-hover: #262626;
    --eon-navigation-main-link-border-color-focus: #262626;
    --eon-navigation-main-link-border-color-current: #ea1b0a;
    --eon-navigation-main-link-border-color-current-hover: #262626;
    --eon-navigation-main-link-border-color-current-focus: #0075c2;
    --eon-navigation-main-link-border-color-active: transparent;
    --eon-navigation-main-link-border-color-active-hover: #262626;
    --eon-navigation-main-link-border-color-active-focus: #f9f6f4;
    --eon-navigation-main-icon-link-font-color: #262626;
    --eon-navigation-main-icon-link-font-color-hover: #262626;
    --eon-navigation-main-icon-link-font-color-focus: #262626;
    --eon-navigation-main-icon-link-font-color-current: #ea1b0a;
    --eon-navigation-main-icon-link-font-color-current-hover: #262626;
    --eon-navigation-main-icon-link-font-color-current-focus: #262626;
    --eon-navigation-main-icon-link-font-color-active: #262626;
    --eon-navigation-main-icon-link-font-color-active-hover: #262626;
    --eon-navigation-main-icon-link-font-color-active-focus: #262626;
    --eon-navigation-main-icon-link-bg-color: transparent;
    --eon-navigation-main-icon-link-bg-color-hover: #f9f6f4;
    --eon-navigation-main-icon-link-bg-color-focus: transparent;
    --eon-navigation-main-icon-link-bg-color-current: transparent;
    --eon-navigation-main-icon-link-bg-color-current-hover: #f9f6f4;
    --eon-navigation-main-icon-link-bg-color-current-focus: #f9f6f4;
    --eon-navigation-main-icon-link-bg-color-active: #f9f6f4;
    --eon-navigation-main-icon-link-bg-color-active-hover: #f9f6f4;
    --eon-navigation-main-icon-link-bg-color-active-focus: #f9f6f4;
    --eon-navigation-main-icon-link-border-color: transparent;
    --eon-navigation-main-icon-link-border-color-hover: #262626;
    --eon-navigation-main-icon-link-border-color-focus: #262626;
    --eon-navigation-main-icon-link-border-color-current: transparent;
    --eon-navigation-main-icon-link-border-color-current-hover: transparent;
    --eon-navigation-main-icon-link-border-color-current-focus: transparent;
    --eon-navigation-main-icon-link-border-color-active: #f9f6f4;
    --eon-navigation-main-icon-link-border-color-active-hover: #262626;
    --eon-navigation-main-icon-link-border-color-active-focus: #f9f6f4;
    --eon-navigation-main-sub-bg-color: #f9f6f4;
    --eon-navigation-main-sub-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px;
    --eon-navigation-flyout-divider-bg-color: #e6e3e1;
    --eon-navigation-flyout-link-font-color: #262626;
    --eon-navigation-flyout-link-font-color-hover: #ea1b0a;
    --eon-navigation-flyout-link-font-color-focus: #76020d;
    --eon-navigation-flyout-link-font-color-current: #ea1b0a;
    --eon-navigation-flyout-link-border-color-current: #ea1b0a;
    --eon-navigation-flyout-link-large-font-color: #262626;
    --eon-navigation-flyout-link-large-font-color-hover: #ea1b0a;
    --eon-navigation-flyout-link-large-font-color-focus: #76020d;
    --eon-navigation-flyout-link-large-font-color-current: #ea1b0a;
    --eon-navigation-flyout-link-large-border-color-current: #ea1b0a;
    --eon-navigation-mobile-bg-color: #f9f6f4;
    --eon-navigation-mobile-link-font-color: #262626;
    --eon-navigation-mobile-link-highlight-border-color: #ea1b0a;
    --eon-navigation-mobile-link-border-color: #e6e3e1;
    --eon-navigation-focus-border-color: #0075c2;
    --eon-navigation-main-border-color-focus-visible: transparent;
    --eon-navigation-logo-focus-color: #0075c2;
    --eon-section-video-control-color-light: #ffffff;
    --eon-section-video-control-color-dark: #262626;
    --eon-search-result-item-mark-color: #edea6c;
    --eon-search-result-item-visited-color: #6d199a;
    --eon-search-result-item-background-color: #ffffff;
    --eon-table-renderer-row-hover-color: #e1eded;
    --eon-videoplayer-focus-outline-color: #7fc7cf;
    --eon-videoplayer-error-color: #ffffff;
    --eon-videoplayer-error-background-color: #cccbca;
    --eon-videoplayer-controls-background: #ffffff;
    --eon-videoplayer-progress-background: #ffffff;
    --eon-videoplayer-progress-color: #edea6c;
    --eon-videoplayer-progress-bar-color: #edea6c;
    --eon-videoplayer-progress-bar-background: #7fc7cf;
    --eon-videoplayer-progress-bar-active-background: #f7f4d9;
    --eon-videoplayer-progress-bar-time-color: #262626;
    --eon-videoplayer-progress-bar-value-background: #7fc7cf;
    --eon-videoplayer-progress-bar-button-color: #ffffff;
    --eon-videoplayer-progress-bar-button-focus: #ffffff;
    --eon-videoplayer-progress-bar-play-button-background: #1ea2b1;
    --eon-videoplayer-progress-bar-play-button-hover-background: #e1eded;
    --eon-videoplayer-progress-bar-mute-button-background: #f9f6f4;
    --eon-videoplayer-progress-bar-mute-button-hover-background: #f59a86;
    --eon-videoplayer-progress-bar-full-screen-button-background: #f9f6f4;
    --eon-videoplayer-progress-bar-full-screen-button-hover-background: #c90a01;
    --eon-videoplayer-icon-color: #262626;
    --eon-website-navigation-box-shadow: none;
    --eon-website-navigation-meta-bg-color: #f9f6f4;
    --eon-website-navigation-meta-border-color: #e6e3e1;
    --eon-website-navigation-meta-link-font-color: #262626;
    --eon-website-navigation-meta-link-font-color-hover: #262626;
    --eon-website-navigation-meta-link-font-color-current: #262626;
    --eon-website-navigation-meta-link-font-color-current-hover: #262626;
    --eon-website-navigation-meta-link-bg-color: transparent;
    --eon-website-navigation-meta-link-bg-color-hover: #e6e3e1;
    --eon-website-navigation-meta-link-bg-color-current: transparent;
    --eon-website-navigation-meta-link-bg-color-current-hover: #e6e3e1;
    --eon-website-navigation-meta-link-border-color: transparent;
    --eon-website-navigation-meta-link-border-color-hover: #262626;
    --eon-website-navigation-meta-link-border-color-current: #262626;
    --eon-website-navigation-meta-link-border-color-current-hover: #262626;
    --eon-website-navigation-toolbar-bg-color: #ffffff;
    --eon-website-navigation-toolbar-link-font-color: #262626;
    --eon-website-navigation-toolbar-link-font-color-hover: #262626;
    --eon-website-navigation-toolbar-link-font-color-current: #ea1b0a;
    --eon-website-navigation-toolbar-link-font-color-current-hover: #ea1b0a;
    --eon-website-navigation-toolbar-link-font-color-active: #ea1b0a;
    --eon-website-navigation-toolbar-link-font-color-active-hover: #ea1b0a;
    --eon-website-navigation-toolbar-link-bg-color: transparent;
    --eon-website-navigation-toolbar-link-bg-color-hover: #f9f6f4;
    --eon-website-navigation-toolbar-link-bg-color-current: transparent;
    --eon-website-navigation-toolbar-link-bg-color-current-hover: #f9f6f4;
    --eon-website-navigation-toolbar-link-bg-color-active: #f9f6f4;
    --eon-website-navigation-toolbar-link-bg-color-active-hover: #f9f6f4;
    --eon-website-navigation-toolbar-sub-bg-color: #f9f6f4;
    --eon-website-navigation-main-bg-color: #ffffff;
    --eon-website-navigation-main-border-color: #e6e3e1;
    --eon-website-navigation-main-link-font-color: #262626;
    --eon-website-navigation-main-link-font-color-hover: #262626;
    --eon-website-navigation-main-link-font-color-current: #ea1b0a;
    --eon-website-navigation-main-link-font-color-current-hover: #262626;
    --eon-website-navigation-main-link-font-color-active: #262626;
    --eon-website-navigation-main-link-font-color-active-hover: #262626;
    --eon-website-navigation-main-link-bg-color: transparent;
    --eon-website-navigation-main-link-bg-color-hover: #f9f6f4;
    --eon-website-navigation-main-link-bg-color-current: transparent;
    --eon-website-navigation-main-link-bg-color-current-hover: #f9f6f4;
    --eon-website-navigation-main-link-bg-color-active: #f9f6f4;
    --eon-website-navigation-main-link-bg-color-active-hover: #f9f6f4;
    --eon-website-navigation-main-link-border-color: transparent;
    --eon-website-navigation-main-link-border-color-hover: #262626;
    --eon-website-navigation-main-link-border-color-current: #ea1b0a;
    --eon-website-navigation-main-link-border-color-current-hover: #262626;
    --eon-website-navigation-main-link-border-color-active: #ea1b0a;
    --eon-website-navigation-main-link-border-color-active-hover: #262626;
    --eon-website-navigation-main-sub-bg-color: #f9f6f4;
    --eon-website-navigation-main-sub-box-shadow: rgba(0, 0, 0, 0.08) 0px 4px
        8px;
    --eon-website-navigation-flyout-divider-bg-color: #e6e3e1;
    --eon-website-navigation-flyout-link-font-color: #262626;
    --eon-website-navigation-flyout-link-font-color-hover: #ea1b0a;
    --eon-website-navigation-flyout-link-font-color-current: #ea1b0a;
    --eon-website-navigation-flyout-link-border-color-current: #ea1b0a;
    --eon-website-navigation-flyout-link-large-font-color: #262626;
    --eon-website-navigation-flyout-link-large-font-color-hover: #ea1b0a;
    --eon-website-navigation-flyout-link-large-font-color-current: #ea1b0a;
    --eon-website-navigation-flyout-link-large-border-color-current: #ea1b0a;
    --eon-website-navigation-mobile-bg-color: #f9f6f4;
    --eon-website-navigation-mobile-link-font-color: #262626;
    --eon-website-navigation-mobile-link-highlight-border-color: #ea1b0a;
    --eon-website-navigation-mobile-link-border-color: #e6e3e1;
    --eon-bright: #ffffff;
    --eon-bright-shade-1: #e6e6e6;
    --eon-bright-shade-2: #cccccc;
    --eon-bright-tint-1: white;
    --eon-bright-tint-2: white;
    --eon-dark: #262626;
    --eon-dark-shade-1: #0d0d0d;
    --eon-dark-shade-2: black;
    --eon-dark-tint-1: #404040;
    --eon-dark-tint-2: #595959;
    --eon-neutral100: #ffffff;
    --eon-neutral100-shade-1: #e6e6e6;
    --eon-neutral100-shade-2: #cccccc;
    --eon-neutral100-tint-1: white;
    --eon-neutral100-tint-2: white;
    --eon-neutral300: #f9f6f4;
    --eon-neutral300-shade-1: #e7dbd3;
    --eon-neutral300-shade-2: #d5c0b2;
    --eon-neutral300-tint-1: white;
    --eon-neutral300-tint-2: white;
    --eon-neutral800: #262626;
    --eon-neutral800-shade-1: #0d0d0d;
    --eon-neutral800-shade-2: black;
    --eon-neutral800-tint-1: #404040;
    --eon-neutral800-tint-2: #595959;
    --eon-red100: #f7e8da;
    --eon-red100-shade-1: #eeceb0;
    --eon-red100-shade-2: #e5b486;
    --eon-red100-tint-1: white;
    --eon-red100-tint-2: white;
    --eon-red300: #f59a86;
    --eon-red300-shade-1: #f17357;
    --eon-red300-shade-2: #ed4b28;
    --eon-red300-tint-1: #f9c1b5;
    --eon-red300-tint-2: #fde9e4;
    --eon-red500: #ea1b0a;
    --eon-red500-shade-1: #b91508;
    --eon-red500-shade-2: #881006;
    --eon-red500-tint-1: #f64031;
    --eon-red500-tint-2: #f86d62;
    --eon-red800: #b00402;
    --eon-red800-shade-1: #7e0301;
    --eon-red800-shade-2: #4b0201;
    --eon-red800-tint-1: #e20503;
    --eon-red800-tint-2: #fc1e1c;
    --eon-yellow100: #f7f4d9;
    --eon-yellow100-shade-1: #eee8af;
    --eon-yellow100-shade-2: #e5dc85;
    --eon-yellow100-tint-1: white;
    --eon-yellow100-tint-2: white;
    --eon-yellow300: #edea6c;
    --eon-yellow300-shade-1: #e7e43f;
    --eon-yellow300-shade-2: #d8d41b;
    --eon-yellow300-tint-1: #f3f099;
    --eon-yellow300-tint-2: #f8f7c7;
    --eon-yellow500: #e3e000;
    --eon-yellow500-shade-1: #b0ae00;
    --eon-yellow500-shade-2: #7d7b00;
    --eon-yellow500-tint-1: #fffc17;
    --eon-yellow500-tint-2: #fffd4a;
    --eon-turquoise100: #e1eded;
    --eon-turquoise100-shade-1: #c1dada;
    --eon-turquoise100-shade-2: #a1c7c7;
    --eon-turquoise100-tint-1: white;
    --eon-turquoise100-tint-2: white;
    --eon-turquoise300: #7fc7cf;
    --eon-turquoise300-shade-1: #5ab7c1;
    --eon-turquoise300-shade-2: #3f9ea9;
    --eon-turquoise300-tint-1: #a4d7dd;
    --eon-turquoise300-tint-2: #c9e7eb;
    --eon-turquoise500: #1ea2b1;
    --eon-turquoise500-shade-1: #177a85;
    --eon-turquoise500-shade-2: #0f525a;
    --eon-turquoise500-tint-1: #28c8da;
    --eon-turquoise500-tint-2: #53d3e2;
    --eon-black: #000000;
    --eon-black-shade-1: black;
    --eon-black-shade-2: black;
    --eon-black-tint-1: #1a1a1a;
    --eon-black-tint-2: #333333;
    --eon-bright-5: white;
    --eon-dark-5: #f4f4f4;
    --eon-neutral100-5: white;
    --eon-neutral300-5: #fffffe;
    --eon-neutral800-5: #f4f4f4;
    --eon-red100-5: #fffefd;
    --eon-red300-5: #fffaf9;
    --eon-red500-5: #fef4f3;
    --eon-red800-5: #fbf2f2;
    --eon-yellow100-5: #fffefd;
    --eon-yellow300-5: #fefef8;
    --eon-yellow500-5: #fefdf2;
    --eon-turquoise100-5: #fefefe;
    --eon-turquoise300-5: #f9fcfd;
    --eon-turquoise500-5: #f4fafb;
    --eon-black-5: #f2f2f2;
    --eon-bright-10: white;
    --eon-dark-10: #e9e9e9;
    --eon-neutral100-10: white;
    --eon-neutral300-10: #fefefe;
    --eon-neutral800-10: #e9e9e9;
    --eon-red100-10: #fefdfb;
    --eon-red300-10: #fef5f3;
    --eon-red500-10: #fde8e7;
    --eon-red800-10: #f7e6e6;
    --eon-yellow100-10: #fefefb;
    --eon-yellow300-10: #fdfdf0;
    --eon-yellow500-10: #fcfce6;
    --eon-turquoise100-10: #fcfdfd;
    --eon-turquoise300-10: #f2f9fa;
    --eon-turquoise500-10: #e9f6f7;
    --eon-black-10: #e6e6e6;
    --eon-bright-15: white;
    --eon-dark-15: #dedede;
    --eon-neutral100-15: white;
    --eon-neutral300-15: #fefefd;
    --eon-neutral800-15: #dedede;
    --eon-red100-15: #fefcf9;
    --eon-red300-15: #fef0ed;
    --eon-red500-15: #fcddda;
    --eon-red800-15: #f3d9d9;
    --eon-yellow100-15: #fefdf9;
    --eon-yellow300-15: #fcfce9;
    --eon-yellow500-15: #fbfad9;
    --eon-turquoise100-15: #fbfcfc;
    --eon-turquoise300-15: #ecf7f8;
    --eon-turquoise500-15: #ddf1f3;
    --eon-black-15: #d9d9d9;
    --eon-bright-20: white;
    --eon-dark-20: #d4d4d4;
    --eon-neutral100-20: white;
    --eon-neutral300-20: #fefdfd;
    --eon-neutral800-20: #d4d4d4;
    --eon-red100-20: #fdfaf8;
    --eon-red300-20: #fdebe7;
    --eon-red500-20: #fbd1ce;
    --eon-red800-20: #efcdcc;
    --eon-yellow100-20: #fdfdf7;
    --eon-yellow300-20: #fbfbe2;
    --eon-yellow500-20: #f9f9cc;
    --eon-turquoise100-20: #f9fbfb;
    --eon-turquoise300-20: #e5f4f5;
    --eon-turquoise500-20: #d2ecef;
    --eon-black-20: #cccccc;
    --eon-bright-25: white;
    --eon-dark-25: #c9c9c9;
    --eon-neutral100-25: white;
    --eon-neutral300-25: #fefdfc;
    --eon-neutral800-25: #c9c9c9;
    --eon-red100-25: #fdf9f6;
    --eon-red300-25: #fde6e1;
    --eon-red500-25: #fac6c2;
    --eon-red800-25: #ebc0c0;
    --eon-yellow100-25: #fdfcf6;
    --eon-yellow300-25: #fbfada;
    --eon-yellow500-25: #f8f7bf;
    --eon-turquoise100-25: #f8fbfb;
    --eon-turquoise300-25: #dff1f3;
    --eon-turquoise500-25: #c7e8ec;
    --eon-black-25: #bfbfbf;
    --eon-bright-30: white;
    --eon-dark-30: #bebebe;
    --eon-neutral100-30: white;
    --eon-neutral300-30: #fdfcfc;
    --eon-neutral800-30: #bebebe;
    --eon-red100-30: #fdf8f4;
    --eon-red300-30: #fce1db;
    --eon-red500-30: #f9bbb6;
    --eon-red800-30: #e7b4b3;
    --eon-yellow100-30: #fdfcf4;
    --eon-yellow300-30: #faf9d3;
    --eon-yellow500-30: #f7f6b3;
    --eon-turquoise100-30: #f6fafa;
    --eon-turquoise300-30: #d9eef1;
    --eon-turquoise500-30: #bce3e8;
    --eon-black-30: #b3b3b3;
    --eon-bright-35: white;
    --eon-dark-35: #b3b3b3;
    --eon-neutral100-35: white;
    --eon-neutral300-35: #fdfcfb;
    --eon-neutral800-35: #b3b3b3;
    --eon-red100-35: #fcf7f2;
    --eon-red300-35: #fcdcd5;
    --eon-red500-35: #f8afa9;
    --eon-red800-35: #e3a7a6;
    --eon-yellow100-35: #fcfbf2;
    --eon-yellow300-35: #f9f8cc;
    --eon-yellow500-35: #f5f4a6;
    --eon-turquoise100-35: #f5f9f9;
    --eon-turquoise300-35: #d2ebee;
    --eon-turquoise500-35: #b0dee4;
    --eon-black-35: #a6a6a6;
    --eon-bright-40: white;
    --eon-dark-40: #a8a8a8;
    --eon-neutral100-40: white;
    --eon-neutral300-40: #fdfbfb;
    --eon-neutral800-40: #a8a8a8;
    --eon-red100-40: #fcf6f0;
    --eon-red300-40: #fbd7cf;
    --eon-red500-40: #f7a49d;
    --eon-red800-40: #df9b9a;
    --eon-yellow100-40: #fcfbf0;
    --eon-yellow300-40: #f8f7c4;
    --eon-yellow500-40: #f4f399;
    --eon-turquoise100-40: #f3f8f8;
    --eon-turquoise300-40: #cce9ec;
    --eon-turquoise500-40: #a5dae0;
    --eon-black-40: #999999;
    --eon-bright-45: white;
    --eon-dark-45: #9d9d9d;
    --eon-neutral100-45: white;
    --eon-neutral300-45: #fcfbfa;
    --eon-neutral800-45: #9d9d9d;
    --eon-red100-45: #fbf5ee;
    --eon-red300-45: #fbd2c9;
    --eon-red500-45: #f69891;
    --eon-red800-45: #db8e8d;
    --eon-yellow100-45: #fbfaee;
    --eon-yellow300-45: #f7f6bd;
    --eon-yellow500-45: #f2f18c;
    --eon-turquoise100-45: #f2f7f7;
    --eon-turquoise300-45: #c5e6e9;
    --eon-turquoise500-45: #9ad5dc;
    --eon-black-45: #8c8c8c;
    --eon-bright-50: white;
    --eon-dark-50: #939393;
    --eon-neutral100-50: white;
    --eon-neutral300-50: #fcfbfa;
    --eon-neutral800-50: #939393;
    --eon-red100-50: #fbf4ed;
    --eon-red300-50: #facdc3;
    --eon-red500-50: #f58d85;
    --eon-red800-50: #d88281;
    --eon-yellow100-50: #fbfaec;
    --eon-yellow300-50: #f6f5b6;
    --eon-yellow500-50: #f1f080;
    --eon-turquoise100-50: #f0f6f6;
    --eon-turquoise300-50: #bfe3e7;
    --eon-turquoise500-50: #8fd1d8;
    --eon-black-50: gray;
    --eon-bright-55: white;
    --eon-dark-55: #888888;
    --eon-neutral100-55: white;
    --eon-neutral300-55: #fcfaf9;
    --eon-neutral800-55: #888888;
    --eon-red100-55: #fbf2eb;
    --eon-red300-55: #fac7bc;
    --eon-red500-55: #f38278;
    --eon-red800-55: #d47574;
    --eon-yellow100-55: #fbf9ea;
    --eon-yellow300-55: #f5f3ae;
    --eon-yellow500-55: #f0ee73;
    --eon-turquoise100-55: #eff5f5;
    --eon-turquoise300-55: #b9e0e5;
    --eon-turquoise500-55: #83ccd4;
    --eon-black-55: #737373;
    --eon-bright-60: white;
    --eon-dark-60: #7d7d7d;
    --eon-neutral100-60: white;
    --eon-neutral300-60: #fbfaf8;
    --eon-neutral800-60: #7d7d7d;
    --eon-red100-60: #faf1e9;
    --eon-red300-60: #f9c2b6;
    --eon-red500-60: #f2766c;
    --eon-red800-60: #d06867;
    --eon-yellow100-60: #faf8e8;
    --eon-yellow300-60: #f4f2a7;
    --eon-yellow500-60: #eeec66;
    --eon-turquoise100-60: #edf4f4;
    --eon-turquoise300-60: #b2dde2;
    --eon-turquoise500-60: #78c7d0;
    --eon-black-60: #666666;
    --eon-bright-65: white;
    --eon-dark-65: #727272;
    --eon-neutral100-65: white;
    --eon-neutral300-65: #fbf9f8;
    --eon-neutral800-65: #727272;
    --eon-red100-65: #faf0e7;
    --eon-red300-65: #f9bdb0;
    --eon-red500-65: #f16b60;
    --eon-red800-65: #cc5c5b;
    --eon-yellow100-65: #faf8e6;
    --eon-yellow300-65: #f3f19f;
    --eon-yellow500-65: #edeb59;
    --eon-turquoise100-65: #ecf3f3;
    --eon-turquoise300-65: #acdbe0;
    --eon-turquoise500-65: #6dc3cc;
    --eon-black-65: #595959;
    --eon-bright-70: white;
    --eon-dark-70: #676767;
    --eon-neutral100-70: white;
    --eon-neutral300-70: #fbf9f7;
    --eon-neutral800-70: #676767;
    --eon-red100-70: #f9efe5;
    --eon-red300-70: #f8b8aa;
    --eon-red500-70: #f05f54;
    --eon-red800-70: #c84f4e;
    --eon-yellow100-70: #f9f7e4;
    --eon-yellow300-70: #f2f098;
    --eon-yellow500-70: #ebe94d;
    --eon-turquoise100-70: #eaf2f2;
    --eon-turquoise300-70: #a5d8dd;
    --eon-turquoise500-70: #62bec8;
    --eon-black-70: #4d4d4d;
    --eon-bright-75: white;
    --eon-dark-75: #5c5c5c;
    --eon-neutral100-75: white;
    --eon-neutral300-75: #fbf8f7;
    --eon-neutral800-75: #5c5c5c;
    --eon-red100-75: #f9eee3;
    --eon-red300-75: #f8b3a4;
    --eon-red500-75: #ef5447;
    --eon-red800-75: #c44341;
    --eon-yellow100-75: #f9f7e3;
    --eon-yellow300-75: #f2ef91;
    --eon-yellow500-75: #eae840;
    --eon-turquoise100-75: #e9f2f2;
    --eon-turquoise300-75: #9fd5db;
    --eon-turquoise500-75: #56b9c5;
    --eon-black-75: #404040;
    --eon-bright-80: white;
    --eon-dark-80: #515151;
    --eon-neutral100-80: white;
    --eon-neutral300-80: #faf8f6;
    --eon-neutral800-80: #515151;
    --eon-red100-80: #f9ede1;
    --eon-red300-80: #f7ae9e;
    --eon-red500-80: #ee493b;
    --eon-red800-80: #c03635;
    --eon-yellow100-80: #f9f6e1;
    --eon-yellow300-80: #f1ee89;
    --eon-yellow500-80: #e9e633;
    --eon-turquoise100-80: #e7f1f1;
    --eon-turquoise300-80: #99d2d9;
    --eon-turquoise500-80: #4bb5c1;
    --eon-black-80: #333333;
    --eon-bright-85: white;
    --eon-dark-85: #474747;
    --eon-neutral100-85: white;
    --eon-neutral300-85: #faf7f6;
    --eon-neutral800-85: #474747;
    --eon-red100-85: #f8ebe0;
    --eon-red300-85: #f7a998;
    --eon-red500-85: #ed3d2f;
    --eon-red800-85: #bc2a28;
    --eon-yellow100-85: #f8f6df;
    --eon-yellow300-85: #f0ed82;
    --eon-yellow500-85: #e7e526;
    --eon-turquoise100-85: #e6f0f0;
    --eon-turquoise300-85: #92cfd6;
    --eon-turquoise500-85: #40b0bd;
    --eon-black-85: #262626;
    --eon-bright-90: white;
    --eon-dark-90: #3c3c3c;
    --eon-neutral100-90: white;
    --eon-neutral300-90: #faf7f5;
    --eon-neutral800-90: #3c3c3c;
    --eon-red100-90: #f8eade;
    --eon-red300-90: #f6a492;
    --eon-red500-90: #ec3223;
    --eon-red800-90: #b81d1b;
    --eon-yellow100-90: #f8f5dd;
    --eon-yellow300-90: #efec7b;
    --eon-yellow500-90: #e6e31a;
    --eon-turquoise100-90: #e4efef;
    --eon-turquoise300-90: #8ccdd4;
    --eon-turquoise500-90: #35abb9;
    --eon-black-90: #1a1a1a;
    --eon-bright-95: white;
    --eon-dark-95: #313131;
    --eon-neutral100-95: white;
    --eon-neutral300-95: #f9f6f5;
    --eon-neutral800-95: #313131;
    --eon-red100-95: #f7e9dc;
    --eon-red300-95: #f69f8c;
    --eon-red500-95: #eb2616;
    --eon-red800-95: #b4110f;
    --eon-yellow100-95: #f7f5db;
    --eon-yellow300-95: #eeeb73;
    --eon-yellow500-95: #e4e20d;
    --eon-turquoise100-95: #e3eeee;
    --eon-turquoise300-95: #85cad1;
    --eon-turquoise500-95: #29a7b5;
    --eon-black-95: #0d0d0d;
    --eon-bright-opacity-5: rgba(255, 255, 255, 0.05);
    --eon-dark-opacity-5: rgba(38, 38, 38, 0.05);
    --eon-neutral100-opacity-5: rgba(255, 255, 255, 0.05);
    --eon-neutral300-opacity-5: rgba(249, 246, 244, 0.05);
    --eon-neutral800-opacity-5: rgba(38, 38, 38, 0.05);
    --eon-red100-opacity-5: rgba(247, 232, 218, 0.05);
    --eon-red300-opacity-5: rgba(245, 154, 134, 0.05);
    --eon-red500-opacity-5: rgba(234, 27, 10, 0.05);
    --eon-red800-opacity-5: rgba(176, 4, 2, 0.05);
    --eon-yellow100-opacity-5: rgba(247, 244, 217, 0.05);
    --eon-yellow300-opacity-5: rgba(237, 234, 108, 0.05);
    --eon-yellow500-opacity-5: rgba(227, 224, 0, 0.05);
    --eon-turquoise100-opacity-5: rgba(225, 237, 237, 0.05);
    --eon-turquoise300-opacity-5: rgba(127, 199, 207, 0.05);
    --eon-turquoise500-opacity-5: rgba(30, 162, 177, 0.05);
    --eon-black-opacity-5: rgba(0, 0, 0, 0.05);
    --eon-bright-opacity-10: rgba(255, 255, 255, 0.1);
    --eon-dark-opacity-10: rgba(38, 38, 38, 0.1);
    --eon-neutral100-opacity-10: rgba(255, 255, 255, 0.1);
    --eon-neutral300-opacity-10: rgba(249, 246, 244, 0.1);
    --eon-neutral800-opacity-10: rgba(38, 38, 38, 0.1);
    --eon-red100-opacity-10: rgba(247, 232, 218, 0.1);
    --eon-red300-opacity-10: rgba(245, 154, 134, 0.1);
    --eon-red500-opacity-10: rgba(234, 27, 10, 0.1);
    --eon-red800-opacity-10: rgba(176, 4, 2, 0.1);
    --eon-yellow100-opacity-10: rgba(247, 244, 217, 0.1);
    --eon-yellow300-opacity-10: rgba(237, 234, 108, 0.1);
    --eon-yellow500-opacity-10: rgba(227, 224, 0, 0.1);
    --eon-turquoise100-opacity-10: rgba(225, 237, 237, 0.1);
    --eon-turquoise300-opacity-10: rgba(127, 199, 207, 0.1);
    --eon-turquoise500-opacity-10: rgba(30, 162, 177, 0.1);
    --eon-black-opacity-10: rgba(0, 0, 0, 0.1);
    --eon-bright-opacity-15: rgba(255, 255, 255, 0.15);
    --eon-dark-opacity-15: rgba(38, 38, 38, 0.15);
    --eon-neutral100-opacity-15: rgba(255, 255, 255, 0.15);
    --eon-neutral300-opacity-15: rgba(249, 246, 244, 0.15);
    --eon-neutral800-opacity-15: rgba(38, 38, 38, 0.15);
    --eon-red100-opacity-15: rgba(247, 232, 218, 0.15);
    --eon-red300-opacity-15: rgba(245, 154, 134, 0.15);
    --eon-red500-opacity-15: rgba(234, 27, 10, 0.15);
    --eon-red800-opacity-15: rgba(176, 4, 2, 0.15);
    --eon-yellow100-opacity-15: rgba(247, 244, 217, 0.15);
    --eon-yellow300-opacity-15: rgba(237, 234, 108, 0.15);
    --eon-yellow500-opacity-15: rgba(227, 224, 0, 0.15);
    --eon-turquoise100-opacity-15: rgba(225, 237, 237, 0.15);
    --eon-turquoise300-opacity-15: rgba(127, 199, 207, 0.15);
    --eon-turquoise500-opacity-15: rgba(30, 162, 177, 0.15);
    --eon-black-opacity-15: rgba(0, 0, 0, 0.15);
    --eon-bright-opacity-20: rgba(255, 255, 255, 0.2);
    --eon-dark-opacity-20: rgba(38, 38, 38, 0.2);
    --eon-neutral100-opacity-20: rgba(255, 255, 255, 0.2);
    --eon-neutral300-opacity-20: rgba(249, 246, 244, 0.2);
    --eon-neutral800-opacity-20: rgba(38, 38, 38, 0.2);
    --eon-red100-opacity-20: rgba(247, 232, 218, 0.2);
    --eon-red300-opacity-20: rgba(245, 154, 134, 0.2);
    --eon-red500-opacity-20: rgba(234, 27, 10, 0.2);
    --eon-red800-opacity-20: rgba(176, 4, 2, 0.2);
    --eon-yellow100-opacity-20: rgba(247, 244, 217, 0.2);
    --eon-yellow300-opacity-20: rgba(237, 234, 108, 0.2);
    --eon-yellow500-opacity-20: rgba(227, 224, 0, 0.2);
    --eon-turquoise100-opacity-20: rgba(225, 237, 237, 0.2);
    --eon-turquoise300-opacity-20: rgba(127, 199, 207, 0.2);
    --eon-turquoise500-opacity-20: rgba(30, 162, 177, 0.2);
    --eon-black-opacity-20: rgba(0, 0, 0, 0.2);
    --eon-bright-opacity-25: rgba(255, 255, 255, 0.25);
    --eon-dark-opacity-25: rgba(38, 38, 38, 0.25);
    --eon-neutral100-opacity-25: rgba(255, 255, 255, 0.25);
    --eon-neutral300-opacity-25: rgba(249, 246, 244, 0.25);
    --eon-neutral800-opacity-25: rgba(38, 38, 38, 0.25);
    --eon-red100-opacity-25: rgba(247, 232, 218, 0.25);
    --eon-red300-opacity-25: rgba(245, 154, 134, 0.25);
    --eon-red500-opacity-25: rgba(234, 27, 10, 0.25);
    --eon-red800-opacity-25: rgba(176, 4, 2, 0.25);
    --eon-yellow100-opacity-25: rgba(247, 244, 217, 0.25);
    --eon-yellow300-opacity-25: rgba(237, 234, 108, 0.25);
    --eon-yellow500-opacity-25: rgba(227, 224, 0, 0.25);
    --eon-turquoise100-opacity-25: rgba(225, 237, 237, 0.25);
    --eon-turquoise300-opacity-25: rgba(127, 199, 207, 0.25);
    --eon-turquoise500-opacity-25: rgba(30, 162, 177, 0.25);
    --eon-black-opacity-25: rgba(0, 0, 0, 0.25);
    --eon-bright-opacity-30: rgba(255, 255, 255, 0.3);
    --eon-dark-opacity-30: rgba(38, 38, 38, 0.3);
    --eon-neutral100-opacity-30: rgba(255, 255, 255, 0.3);
    --eon-neutral300-opacity-30: rgba(249, 246, 244, 0.3);
    --eon-neutral800-opacity-30: rgba(38, 38, 38, 0.3);
    --eon-red100-opacity-30: rgba(247, 232, 218, 0.3);
    --eon-red300-opacity-30: rgba(245, 154, 134, 0.3);
    --eon-red500-opacity-30: rgba(234, 27, 10, 0.3);
    --eon-red800-opacity-30: rgba(176, 4, 2, 0.3);
    --eon-yellow100-opacity-30: rgba(247, 244, 217, 0.3);
    --eon-yellow300-opacity-30: rgba(237, 234, 108, 0.3);
    --eon-yellow500-opacity-30: rgba(227, 224, 0, 0.3);
    --eon-turquoise100-opacity-30: rgba(225, 237, 237, 0.3);
    --eon-turquoise300-opacity-30: rgba(127, 199, 207, 0.3);
    --eon-turquoise500-opacity-30: rgba(30, 162, 177, 0.3);
    --eon-black-opacity-30: rgba(0, 0, 0, 0.3);
    --eon-bright-opacity-35: rgba(255, 255, 255, 0.35);
    --eon-dark-opacity-35: rgba(38, 38, 38, 0.35);
    --eon-neutral100-opacity-35: rgba(255, 255, 255, 0.35);
    --eon-neutral300-opacity-35: rgba(249, 246, 244, 0.35);
    --eon-neutral800-opacity-35: rgba(38, 38, 38, 0.35);
    --eon-red100-opacity-35: rgba(247, 232, 218, 0.35);
    --eon-red300-opacity-35: rgba(245, 154, 134, 0.35);
    --eon-red500-opacity-35: rgba(234, 27, 10, 0.35);
    --eon-red800-opacity-35: rgba(176, 4, 2, 0.35);
    --eon-yellow100-opacity-35: rgba(247, 244, 217, 0.35);
    --eon-yellow300-opacity-35: rgba(237, 234, 108, 0.35);
    --eon-yellow500-opacity-35: rgba(227, 224, 0, 0.35);
    --eon-turquoise100-opacity-35: rgba(225, 237, 237, 0.35);
    --eon-turquoise300-opacity-35: rgba(127, 199, 207, 0.35);
    --eon-turquoise500-opacity-35: rgba(30, 162, 177, 0.35);
    --eon-black-opacity-35: rgba(0, 0, 0, 0.35);
    --eon-bright-opacity-40: rgba(255, 255, 255, 0.4);
    --eon-dark-opacity-40: rgba(38, 38, 38, 0.4);
    --eon-neutral100-opacity-40: rgba(255, 255, 255, 0.4);
    --eon-neutral300-opacity-40: rgba(249, 246, 244, 0.4);
    --eon-neutral800-opacity-40: rgba(38, 38, 38, 0.4);
    --eon-red100-opacity-40: rgba(247, 232, 218, 0.4);
    --eon-red300-opacity-40: rgba(245, 154, 134, 0.4);
    --eon-red500-opacity-40: rgba(234, 27, 10, 0.4);
    --eon-red800-opacity-40: rgba(176, 4, 2, 0.4);
    --eon-yellow100-opacity-40: rgba(247, 244, 217, 0.4);
    --eon-yellow300-opacity-40: rgba(237, 234, 108, 0.4);
    --eon-yellow500-opacity-40: rgba(227, 224, 0, 0.4);
    --eon-turquoise100-opacity-40: rgba(225, 237, 237, 0.4);
    --eon-turquoise300-opacity-40: rgba(127, 199, 207, 0.4);
    --eon-turquoise500-opacity-40: rgba(30, 162, 177, 0.4);
    --eon-black-opacity-40: rgba(0, 0, 0, 0.4);
    --eon-bright-opacity-45: rgba(255, 255, 255, 0.45);
    --eon-dark-opacity-45: rgba(38, 38, 38, 0.45);
    --eon-neutral100-opacity-45: rgba(255, 255, 255, 0.45);
    --eon-neutral300-opacity-45: rgba(249, 246, 244, 0.45);
    --eon-neutral800-opacity-45: rgba(38, 38, 38, 0.45);
    --eon-red100-opacity-45: rgba(247, 232, 218, 0.45);
    --eon-red300-opacity-45: rgba(245, 154, 134, 0.45);
    --eon-red500-opacity-45: rgba(234, 27, 10, 0.45);
    --eon-red800-opacity-45: rgba(176, 4, 2, 0.45);
    --eon-yellow100-opacity-45: rgba(247, 244, 217, 0.45);
    --eon-yellow300-opacity-45: rgba(237, 234, 108, 0.45);
    --eon-yellow500-opacity-45: rgba(227, 224, 0, 0.45);
    --eon-turquoise100-opacity-45: rgba(225, 237, 237, 0.45);
    --eon-turquoise300-opacity-45: rgba(127, 199, 207, 0.45);
    --eon-turquoise500-opacity-45: rgba(30, 162, 177, 0.45);
    --eon-black-opacity-45: rgba(0, 0, 0, 0.45);
    --eon-bright-opacity-50: rgba(255, 255, 255, 0.5);
    --eon-dark-opacity-50: rgba(38, 38, 38, 0.5);
    --eon-neutral100-opacity-50: rgba(255, 255, 255, 0.5);
    --eon-neutral300-opacity-50: rgba(249, 246, 244, 0.5);
    --eon-neutral800-opacity-50: rgba(38, 38, 38, 0.5);
    --eon-red100-opacity-50: rgba(247, 232, 218, 0.5);
    --eon-red300-opacity-50: rgba(245, 154, 134, 0.5);
    --eon-red500-opacity-50: rgba(234, 27, 10, 0.5);
    --eon-red800-opacity-50: rgba(176, 4, 2, 0.5);
    --eon-yellow100-opacity-50: rgba(247, 244, 217, 0.5);
    --eon-yellow300-opacity-50: rgba(237, 234, 108, 0.5);
    --eon-yellow500-opacity-50: rgba(227, 224, 0, 0.5);
    --eon-turquoise100-opacity-50: rgba(225, 237, 237, 0.5);
    --eon-turquoise300-opacity-50: rgba(127, 199, 207, 0.5);
    --eon-turquoise500-opacity-50: rgba(30, 162, 177, 0.5);
    --eon-black-opacity-50: rgba(0, 0, 0, 0.5);
    --eon-bright-opacity-55: rgba(255, 255, 255, 0.55);
    --eon-dark-opacity-55: rgba(38, 38, 38, 0.55);
    --eon-neutral100-opacity-55: rgba(255, 255, 255, 0.55);
    --eon-neutral300-opacity-55: rgba(249, 246, 244, 0.55);
    --eon-neutral800-opacity-55: rgba(38, 38, 38, 0.55);
    --eon-red100-opacity-55: rgba(247, 232, 218, 0.55);
    --eon-red300-opacity-55: rgba(245, 154, 134, 0.55);
    --eon-red500-opacity-55: rgba(234, 27, 10, 0.55);
    --eon-red800-opacity-55: rgba(176, 4, 2, 0.55);
    --eon-yellow100-opacity-55: rgba(247, 244, 217, 0.55);
    --eon-yellow300-opacity-55: rgba(237, 234, 108, 0.55);
    --eon-yellow500-opacity-55: rgba(227, 224, 0, 0.55);
    --eon-turquoise100-opacity-55: rgba(225, 237, 237, 0.55);
    --eon-turquoise300-opacity-55: rgba(127, 199, 207, 0.55);
    --eon-turquoise500-opacity-55: rgba(30, 162, 177, 0.55);
    --eon-black-opacity-55: rgba(0, 0, 0, 0.55);
    --eon-bright-opacity-60: rgba(255, 255, 255, 0.6);
    --eon-dark-opacity-60: rgba(38, 38, 38, 0.6);
    --eon-neutral100-opacity-60: rgba(255, 255, 255, 0.6);
    --eon-neutral300-opacity-60: rgba(249, 246, 244, 0.6);
    --eon-neutral800-opacity-60: rgba(38, 38, 38, 0.6);
    --eon-red100-opacity-60: rgba(247, 232, 218, 0.6);
    --eon-red300-opacity-60: rgba(245, 154, 134, 0.6);
    --eon-red500-opacity-60: rgba(234, 27, 10, 0.6);
    --eon-red800-opacity-60: rgba(176, 4, 2, 0.6);
    --eon-yellow100-opacity-60: rgba(247, 244, 217, 0.6);
    --eon-yellow300-opacity-60: rgba(237, 234, 108, 0.6);
    --eon-yellow500-opacity-60: rgba(227, 224, 0, 0.6);
    --eon-turquoise100-opacity-60: rgba(225, 237, 237, 0.6);
    --eon-turquoise300-opacity-60: rgba(127, 199, 207, 0.6);
    --eon-turquoise500-opacity-60: rgba(30, 162, 177, 0.6);
    --eon-black-opacity-60: rgba(0, 0, 0, 0.6);
    --eon-bright-opacity-65: rgba(255, 255, 255, 0.65);
    --eon-dark-opacity-65: rgba(38, 38, 38, 0.65);
    --eon-neutral100-opacity-65: rgba(255, 255, 255, 0.65);
    --eon-neutral300-opacity-65: rgba(249, 246, 244, 0.65);
    --eon-neutral800-opacity-65: rgba(38, 38, 38, 0.65);
    --eon-red100-opacity-65: rgba(247, 232, 218, 0.65);
    --eon-red300-opacity-65: rgba(245, 154, 134, 0.65);
    --eon-red500-opacity-65: rgba(234, 27, 10, 0.65);
    --eon-red800-opacity-65: rgba(176, 4, 2, 0.65);
    --eon-yellow100-opacity-65: rgba(247, 244, 217, 0.65);
    --eon-yellow300-opacity-65: rgba(237, 234, 108, 0.65);
    --eon-yellow500-opacity-65: rgba(227, 224, 0, 0.65);
    --eon-turquoise100-opacity-65: rgba(225, 237, 237, 0.65);
    --eon-turquoise300-opacity-65: rgba(127, 199, 207, 0.65);
    --eon-turquoise500-opacity-65: rgba(30, 162, 177, 0.65);
    --eon-black-opacity-65: rgba(0, 0, 0, 0.65);
    --eon-bright-opacity-70: rgba(255, 255, 255, 0.7);
    --eon-dark-opacity-70: rgba(38, 38, 38, 0.7);
    --eon-neutral100-opacity-70: rgba(255, 255, 255, 0.7);
    --eon-neutral300-opacity-70: rgba(249, 246, 244, 0.7);
    --eon-neutral800-opacity-70: rgba(38, 38, 38, 0.7);
    --eon-red100-opacity-70: rgba(247, 232, 218, 0.7);
    --eon-red300-opacity-70: rgba(245, 154, 134, 0.7);
    --eon-red500-opacity-70: rgba(234, 27, 10, 0.7);
    --eon-red800-opacity-70: rgba(176, 4, 2, 0.7);
    --eon-yellow100-opacity-70: rgba(247, 244, 217, 0.7);
    --eon-yellow300-opacity-70: rgba(237, 234, 108, 0.7);
    --eon-yellow500-opacity-70: rgba(227, 224, 0, 0.7);
    --eon-turquoise100-opacity-70: rgba(225, 237, 237, 0.7);
    --eon-turquoise300-opacity-70: rgba(127, 199, 207, 0.7);
    --eon-turquoise500-opacity-70: rgba(30, 162, 177, 0.7);
    --eon-black-opacity-70: rgba(0, 0, 0, 0.7);
    --eon-bright-opacity-75: rgba(255, 255, 255, 0.75);
    --eon-dark-opacity-75: rgba(38, 38, 38, 0.75);
    --eon-neutral100-opacity-75: rgba(255, 255, 255, 0.75);
    --eon-neutral300-opacity-75: rgba(249, 246, 244, 0.75);
    --eon-neutral800-opacity-75: rgba(38, 38, 38, 0.75);
    --eon-red100-opacity-75: rgba(247, 232, 218, 0.75);
    --eon-red300-opacity-75: rgba(245, 154, 134, 0.75);
    --eon-red500-opacity-75: rgba(234, 27, 10, 0.75);
    --eon-red800-opacity-75: rgba(176, 4, 2, 0.75);
    --eon-yellow100-opacity-75: rgba(247, 244, 217, 0.75);
    --eon-yellow300-opacity-75: rgba(237, 234, 108, 0.75);
    --eon-yellow500-opacity-75: rgba(227, 224, 0, 0.75);
    --eon-turquoise100-opacity-75: rgba(225, 237, 237, 0.75);
    --eon-turquoise300-opacity-75: rgba(127, 199, 207, 0.75);
    --eon-turquoise500-opacity-75: rgba(30, 162, 177, 0.75);
    --eon-black-opacity-75: rgba(0, 0, 0, 0.75);
    --eon-bright-opacity-80: rgba(255, 255, 255, 0.8);
    --eon-dark-opacity-80: rgba(38, 38, 38, 0.8);
    --eon-neutral100-opacity-80: rgba(255, 255, 255, 0.8);
    --eon-neutral300-opacity-80: rgba(249, 246, 244, 0.8);
    --eon-neutral800-opacity-80: rgba(38, 38, 38, 0.8);
    --eon-red100-opacity-80: rgba(247, 232, 218, 0.8);
    --eon-red300-opacity-80: rgba(245, 154, 134, 0.8);
    --eon-red500-opacity-80: rgba(234, 27, 10, 0.8);
    --eon-red800-opacity-80: rgba(176, 4, 2, 0.8);
    --eon-yellow100-opacity-80: rgba(247, 244, 217, 0.8);
    --eon-yellow300-opacity-80: rgba(237, 234, 108, 0.8);
    --eon-yellow500-opacity-80: rgba(227, 224, 0, 0.8);
    --eon-turquoise100-opacity-80: rgba(225, 237, 237, 0.8);
    --eon-turquoise300-opacity-80: rgba(127, 199, 207, 0.8);
    --eon-turquoise500-opacity-80: rgba(30, 162, 177, 0.8);
    --eon-black-opacity-80: rgba(0, 0, 0, 0.8);
    --eon-bright-opacity-85: rgba(255, 255, 255, 0.85);
    --eon-dark-opacity-85: rgba(38, 38, 38, 0.85);
    --eon-neutral100-opacity-85: rgba(255, 255, 255, 0.85);
    --eon-neutral300-opacity-85: rgba(249, 246, 244, 0.85);
    --eon-neutral800-opacity-85: rgba(38, 38, 38, 0.85);
    --eon-red100-opacity-85: rgba(247, 232, 218, 0.85);
    --eon-red300-opacity-85: rgba(245, 154, 134, 0.85);
    --eon-red500-opacity-85: rgba(234, 27, 10, 0.85);
    --eon-red800-opacity-85: rgba(176, 4, 2, 0.85);
    --eon-yellow100-opacity-85: rgba(247, 244, 217, 0.85);
    --eon-yellow300-opacity-85: rgba(237, 234, 108, 0.85);
    --eon-yellow500-opacity-85: rgba(227, 224, 0, 0.85);
    --eon-turquoise100-opacity-85: rgba(225, 237, 237, 0.85);
    --eon-turquoise300-opacity-85: rgba(127, 199, 207, 0.85);
    --eon-turquoise500-opacity-85: rgba(30, 162, 177, 0.85);
    --eon-black-opacity-85: rgba(0, 0, 0, 0.85);
    --eon-bright-opacity-90: rgba(255, 255, 255, 0.9);
    --eon-dark-opacity-90: rgba(38, 38, 38, 0.9);
    --eon-neutral100-opacity-90: rgba(255, 255, 255, 0.9);
    --eon-neutral300-opacity-90: rgba(249, 246, 244, 0.9);
    --eon-neutral800-opacity-90: rgba(38, 38, 38, 0.9);
    --eon-red100-opacity-90: rgba(247, 232, 218, 0.9);
    --eon-red300-opacity-90: rgba(245, 154, 134, 0.9);
    --eon-red500-opacity-90: rgba(234, 27, 10, 0.9);
    --eon-red800-opacity-90: rgba(176, 4, 2, 0.9);
    --eon-yellow100-opacity-90: rgba(247, 244, 217, 0.9);
    --eon-yellow300-opacity-90: rgba(237, 234, 108, 0.9);
    --eon-yellow500-opacity-90: rgba(227, 224, 0, 0.9);
    --eon-turquoise100-opacity-90: rgba(225, 237, 237, 0.9);
    --eon-turquoise300-opacity-90: rgba(127, 199, 207, 0.9);
    --eon-turquoise500-opacity-90: rgba(30, 162, 177, 0.9);
    --eon-black-opacity-90: rgba(0, 0, 0, 0.9);
    --eon-bright-opacity-95: rgba(255, 255, 255, 0.95);
    --eon-dark-opacity-95: rgba(38, 38, 38, 0.95);
    --eon-neutral100-opacity-95: rgba(255, 255, 255, 0.95);
    --eon-neutral300-opacity-95: rgba(249, 246, 244, 0.95);
    --eon-neutral800-opacity-95: rgba(38, 38, 38, 0.95);
    --eon-red100-opacity-95: rgba(247, 232, 218, 0.95);
    --eon-red300-opacity-95: rgba(245, 154, 134, 0.95);
    --eon-red500-opacity-95: rgba(234, 27, 10, 0.95);
    --eon-red800-opacity-95: rgba(176, 4, 2, 0.95);
    --eon-yellow100-opacity-95: rgba(247, 244, 217, 0.95);
    --eon-yellow300-opacity-95: rgba(237, 234, 108, 0.95);
    --eon-yellow500-opacity-95: rgba(227, 224, 0, 0.95);
    --eon-turquoise100-opacity-95: rgba(225, 237, 237, 0.95);
    --eon-turquoise300-opacity-95: rgba(127, 199, 207, 0.95);
    --eon-turquoise500-opacity-95: rgba(30, 162, 177, 0.95);
    --eon-black-opacity-95: rgba(0, 0, 0, 0.95);
}
eon-ui-accordion,
eon-ui-badges,
eon-ui-bottom-drawer,
eon-ui-businesscard-author,
eon-ui-businesscard-contact,
eon-ui-card-teaser,
eon-ui-carousel,
eon-ui-contact-list,
eon-ui-content-aggregator,
eon-ui-form,
eon-ui-image,
eon-ui-list,
eon-ui-map-info-layer,
eon-ui-offset-teaser,
eon-ui-page-header,
eon-ui-search-result-item,
eon-ui-sitelink,
eon-ui-stage,
eon-ui-tabs,
eon-ui-text-teaser,
eon-ui-text,
eon-ui-multi-content-stage,
eon-ui-multi-content-stage-item,
eon-ui-table,
eon-ui-table-body,
eon-ui-table-row,
eon-ui-table-head-row,
eon-ui-table-cell {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.25s cubic-bezier(0.33, 0, 0.35, 1);
    transition: opacity 0.25s cubic-bezier(0.33, 0, 0.35, 1);
}
eon-ui-accordion.hydrated,
eon-ui-badges.hydrated,
eon-ui-bottom-drawer.hydrated,
eon-ui-businesscard-author.hydrated,
eon-ui-businesscard-contact.hydrated,
eon-ui-card-teaser.hydrated,
eon-ui-carousel.hydrated,
eon-ui-contact-list.hydrated,
eon-ui-content-aggregator.hydrated,
eon-ui-form.hydrated,
eon-ui-image.hydrated,
eon-ui-list.hydrated,
eon-ui-map-info-layer.hydrated,
eon-ui-offset-teaser.hydrated,
eon-ui-page-header.hydrated,
eon-ui-search-result-item.hydrated,
eon-ui-sitelink.hydrated,
eon-ui-stage.hydrated,
eon-ui-tabs.hydrated,
eon-ui-text-teaser.hydrated,
eon-ui-text.hydrated,
eon-ui-multi-content-stage.hydrated,
eon-ui-multi-content-stage-item.hydrated,
eon-ui-table.hydrated,
eon-ui-table-body.hydrated,
eon-ui-table-row.hydrated,
eon-ui-table-head-row.hydrated,
eon-ui-table-cell.hydrated {
    visibility: visible;
    opacity: 1;
}
