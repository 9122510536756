.orgnize-wrapper {
    display: flex;
    justify-content: space-between;
}

.personalize-dashboard {
    width: 220px;

    .heading {
        color: var(--heading-color);
    }

    .margin-category {
        margin-top: 12px;
    }

    .tab-data-container {
        margin-top: 12px;
        display: flex;
        gap: 12px;

        .disabled-icon {
            color: antiquewhite;
        }
    }

    @media screen and (min-width: 540px) {
        width: 400px;
    }

    @media screen and (min-width: 768px) {
        width: 540px;
    }
}

.delete-tab-confirm {
    .delete-tab-message {
        color: var(--primary-color);
        margin-top: 24px;
    }
}
